import React from 'react';
import SockJsClient from 'react-stomp';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';

//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

const SOCKET_URL = baseUrl.LoginPath + '/ws-message';

export default class WebSocketMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promoted: [],
            demoted: [],
            _GroupName: '',
            alert: false,
            isPromoted: false
        }
        this.onConnected = this.onConnected.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.updatePermissions = this.updatePermissions.bind(this);
    }

    onConnected() {
        console.log("Websocket Connected!")
    }

    onMessageReceived(response) {
        console.log("Received the message");
        this.setState({
            promoted: response.promotedList,
            demoted: response.demotedList
        },()=>{
            if((this.state.promoted && this.state.promoted.includes(localStorage.getItem('userId'))) || 
               (this.state.demoted && this.state.demoted.includes(localStorage.getItem('userId')))) {
                this.updatePermissions();
            }
        })
    }

    async updatePermissions(){
        const url = baseUrl.URLPath + servicesUrl.refreshPermissions;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
  
        // payload
        let payload = {}
  
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response?.roleAccess){
            localStorage.setItem('roleAccess', response?.roleAccess);
            window.location.replace('/dashboard')
        }
    }

    refreshOk(){
        window.location.replace('/dashboard');
    }

    render() {
        return(
            <>
                <SockJsClient
                    url={SOCKET_URL}
                    topics={['/topic/message']}
                    onConnect={this.onConnected}
                    onDisconnect={console.log("Disconnected!")}
                    onMessage={msg => this.onMessageReceived(msg)}
                    debug={false}
                />

                <MDBModal isOpen={this.state.alert} size="md" className="model_top">
                    <MDBModalHeader>Alert</MDBModalHeader>
                    <MDBModalBody>
                        {this.state._GroupName}: Your Role is {this.state.isPromoted? 'promoted': 'demoted'} as {this.state.isPromoted? 'WEBASTO_SITE_ADMIN': localStorage.getItem('role')}. So portal will reflect those changes accordingly.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.refreshOk}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}