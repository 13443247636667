import React from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import $ from "jquery";

import { Alert } from 'reactstrap';
import { Form, Table } from 'react-bootstrap';
import moment from 'moment';
import { Markup } from 'interweave';
import Loader from './loader';
import { hasPermission } from './auth';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { Document, Page, pdfjs } from "react-pdf";
import Calendar from 'react-calendar';
import { Line } from "react-chartjs-2";
import currencyFormatter from 'currency-formatter';
import { dateformatinUTC } from './_methods';
import DatePicker from "react-datepicker";
import * as roles from './roles';
import { withTranslation } from 'react-i18next';
import CustomModal from '../common/CustomModal';
import classNames from 'classnames';

//API Imports
import * as baseUrl from '../views/constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders, getAuthorization, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';

import '../App.css';
import './scrollbar.css';
import './cpDashboard.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const history = createBrowserHistory();

class Viewchargepoint extends React.Component {
  fileInput = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      successTbContent: false,
      settingPopup: false,
      prevSelectedData: [],
      selectedItems: [],
      tableCustomization: false,
      getFieldsList: [],
      deletedWallboxes: [],
      siteWallboxes: [],
      imgsizeerror: false,
      imgmemoryerror: false,
      deleteModalSuccess: false,
      deleteModaltitle: '',
      deleteImageMessage: '',
      uploadImageMessage: '',
      uploadModalSuccess: false,
      image: [],
      previewUrl: [],
      getImagesList: [],
      selectedImage: '',
      deleteModal: false,
      logbookSuccessMessage: '',
      sucesslogbook: false,
      onlineStatus: false,
      confirmdelete: false,
      modelwallbox: false,
      assignedEmail: "",
      assignedmail: "",
      assignEmail: "",
      location: "",
      color_black: false,
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      getConfigMessage: "",
      operationdisplay: "",
      enableCSOperation: "true",
      getUrl: "",
      file: "",
      getResponse:
      {
        data: {
          attachments: [],
        },
      },
      getOperationsList: [],
      startDate2: '',
      startDate3: '',
      startDate1: '',
      endDate1: '',
      hideActionBtn: "none", // show hide btn varible based on permission 
      transData: '',
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      disabled: 'true',
      activeItem: "1",
      page_title: "View Charge Point ",
      loading: "",
      pdfloading: "",
      dataLine: {
        labels: [],
        dataLabels: {
          enabled: false
        },
        datasets: [
          {
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",
            data: []
          }
        ],
        options: {
          legend: { display: false }, tooltips: {
            enabled: false
          }
        }
      },
      editResUser: false,
      message: "",
      rEditAccess: false,
      fields:
      {
        id: "",
        chargePointId: '',
        description: '',
        chargePointModel: '',
        fwVersion: '',
        softwareVersion: '',
        chargePointSerialNumber: '',
        partNumber: '',
        address: {
          addressId: '',
          street: "",
          houseNumber: "",
          zipCode: "",
          city: "",
          country: "",
          createdTime: "",
          modifiedTime: "",
          latitude: "",
          longitude: "",
        },
        active: "",
        chargeBoxSerialNumber: "",
        iccid: "",
        meterSerialNumber: "",
        meterType: "",
        chargePointId: "",
        onChangeOperationValue: "",
        assignedEmail: "",
        installationSite: "",
        logBookAccess: false,
        logbookData: false,
        vendorName: "",
        isChargerOwner: false
      },
      model1: "false",
      value: [null, null],
      model2: "false",
      bgColor1: "active",
      bgColor2: "",
      errors: {},
      activeItem: "1",
      transactions: [],
      startDate: new Date(),
      chargePointId: '',
      connectorStatus: [],
      sucess: false,
      colour: 'sucess',
      sucess1: false,
      colour1: 'sucess',
      metervalues: [],
      connectorId: '',
      transactionId: '',
      modalsession: false,
      calenderfromDisplay: "none",
      diagnosticsData: [{
        "statusTables": "",
        "fileName": "",
        "fileUploadedDate": "",
        "diagnosticsStartDate": "",
        "diagnosticsStopDate": "",
      }],
      graphView: 'block',
      tableView: 'none',
      meterValuesChart: [],
      displayMaxValues: {
        min: 0,
        suggestedMax: 10,
      },
      getOperationList: [],
      user: {
        permissions: []
      },
      tempOperation: [],
      businessError: [],
      numPages: null,
      basepdfurl: '',
      setNumPages: null,
      pageNumber: 1,
      setPageNumber: 1,
      configKeys: [],
      configmessage: '',
      schedulefromDate: '',
      scheduletoDate: "",
      error: "",
      wallboxError: false,
      sucessMessage: "",
      wallboxSucess: false,
      calenderFrom: "none",
      calenderTo: "none",
      transId: '',
      mileage: '',
      inventoryNumber: '',
      remoteStart: false,
      selectedcpId: "",
      selectedTransactionId: "",
      energyLevel: "",
      selectedRfid: "",
      note: "",
      isactiveEnergy: "",
      rfidTagsList: [],
      successTransaction: false,
      mileageNdInventory: false,
      modalMileageNdInventory: false,
      isOperationsActive: true,
      opertaionPermissions: [],
      opertaion_permissions: {
        permissions: []
      },
      data1: new Map(),
      pageSize1: "10",
      activePage1: 1,
      pageNo1: 1,
      noOfRecords1: '',
      configFavKeys: [],
      ErrorCounter: "",
      favValue: false,
      modalkye: false,
      configNewValue: "",
      editConfigKey: "",
      editConfigKeyValue: "",
      modalkyeWar: false,
      modalkedit: false,
      modalkyeSuc: false,
      cpmodel: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      fromDate: '',
      toDate: '',
      fromDateApi: '',
      toDateApi: '',
      isFirmwareUptodate: false,
      isChargerOwner: false,
      isFirmwareAvailable: false,
      fwVersion: '',
      softwareVersion: '',
      isFirmwareInitiated: false,
      isFirmwareNotAvailable: false,
      disabledLogbook: false,
      logBookmodel: false,
      isUploadImagesOpen: false,
      isImagesLimitExceeded: false,
      inDropZone: false,
      uploading: false,
      imgemptyerror: false,
      ASetChargingLimit: false,
      images_limit: false,
      imgtypeerror: false,
      reservationSeatingEnabled: false,
      uploadImageTitle: '',
      sucess2: false,
      isOperationAvailable: false,
      traCPId: "",
      findValue: "",
      finderrorCode: "",
      firmwareCurrentVersion: "",
      firmwareStatus: "",
      firmwareTotalCount: 0,
      firmwareUpdatedCount: 0,
      firmwareInstalledBuildsCount: 0,
      firmwareInstalledUpdateTime: "",
      firmwareDownloadStatus: false,
      firmwareAlert: false,
      firmwareMessage: "",
      cponlinestatusAlert: false,
      cponlinestatus: "",
      localUsers: [],
      confirmdeleteuser: false,
      deleteEmail: '',
      deleteSucess: false,
      addUser: false,
      userFields: {
        useremail: '',
        userPassword: '',
        firstName: '',
        lastName: ''
      },
      userAddSucess: false,
      userAddWarning: false,
      localuserupdatepassowrd: false,
      localUserEmail: "",
      localUserOldPassword: "",
      localUserNewPassword: "",
      localUserUpdatePasswordSucess: false,
      syncSuccessModal: false,
      syncSuccessMsg: "",
      cpId: null,      
      modelName: '',
      isModelDX: false,
      isLoading: false,
    }

    this.getChargePointDetails = this.getChargePointDetails.bind(this);
    this.togglekey = this.togglekey.bind(this);
    this.Closekedit = this.Closekedit.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.togglekyeSuc = this.togglekyeSuc.bind(this);
    this.togglekyeWar = this.togglekyeWar.bind(this);
    this.updateConfigListValue = this.updateConfigListValue.bind(this);
    this.Closekey = this.Closekey.bind(this);
    this.changeConfigValue = this.changeConfigValue.bind(this);
    this.toggleMileageNdInventory = this.toggleMileageNdInventory.bind(this);
    this.idTagChange = this.idTagChange.bind(this);
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.editChargePoint = this.editChargePoint.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.tabToggle = this.tabToggle.bind(this)
    this.getTransactions = this.getTransactions.bind(this);
    this.getConnectorStatus = this.getConnectorStatus.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.getDignosticsData = this.getDignosticsData.bind(this);
    this.getMeterVaulesByRefresh = this.getMeterVaulesByRefresh.bind(this);
    this.displayfromCalender = this.displayfromCalender.bind(this);
    this.closeMeterValuesCalender = this.closeMeterValuesCalender.bind(this);
    this.getAttachment = this.getAttachment.bind(this);
    this.lowToHigh = this.lowToHigh.bind(this);
    this.highToLow = this.highToLow.bind(this);
    this.sortByAtoZ = this.sortByAtoZ.bind(this);
    this.sortByZtoA = this.sortByZtoA.bind(this);
    this.compareObjectsSortByAtoZ = this.compareObjectsSortByAtoZ.bind(this);
    this.compareObjectsSortByZtoA = this.compareObjectsSortByZtoA.bind(this);
    this.getChargePointConfiguration = this.getChargePointConfiguration.bind(this);
    this.getConfigurations = this.getConfigurations.bind(this);
    this.onShowAlert1 = this.onShowAlert1.bind(this);
    this.exportTransactionCSV = this.exportTransactionCSV.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.changeOperations = this.changeOperations.bind(this);
    this.onChangeOperationsFilter = this.onChangeOperationsFilter.bind(this);
    this.getDiagnostics = this.getDiagnostics.bind(this);
    this.getPayLoad = this.getPayLoad.bind(this);
    this.assignWallbox = this.assignWallbox.bind(this);
    this.removeWallbox = this.removeWallbox.bind(this);
    this.togglesession1 = this.togglesession1.bind(this);
    this.changeWallboxEmail = this.changeWallboxEmail.bind(this);
    this.togglewallboxError = this.togglewallboxError.bind(this);
    this.togglewallboxSucess = this.togglewallboxSucess.bind(this);
    this.togglewallboxConfirm = this.togglewallboxConfirm.bind(this);
    this.logbookActivateDeactivate = this.logbookActivateDeactivate.bind(this);
    this.togglesucesslogbook = this.togglesucesslogbook.bind(this);
    this.getMeterValuesChart = this.getMeterValuesChart.bind(this);
    this.onChangeMeterValueDate = this.onChangeMeterValueDate.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.toggleRemoteStart = this.toggleRemoteStart.bind(this);
    this.beginRemoteStart = this.beginRemoteStart.bind(this);
    this.getRfidTags = this.getRfidTags.bind(this);
    this.updateEnergyLevel = this.updateEnergyLevel.bind(this);
    this.successTransaction = this.successTransaction.bind(this);
    this.updateMileage = this.updateMileage.bind(this);
    this.updateInventory = this.updateInventory.bind(this);
    this.saveMileageNdInventory = this.saveMileageNdInventory.bind(this);
    this.toggleSaveMileageNdInventory = this.toggleSaveMileageNdInventory.bind(this);
    this.getOperationsForResidential = this.getOperationsForResidential.bind(this);
    this.handlePageChange1 = this.handlePageChange1.bind(this);
    this.showEntriesChange1 = this.showEntriesChange1.bind(this);
    this.addInFavList = this.addInFavList.bind(this);
    this.sortByZtoAFav = this.sortByZtoAFav.bind(this);
    this.sortByAtoZFav = this.sortByAtoZFav.bind(this);
    this.lowToHighFav = this.lowToHighFav.bind(this);
    this.highToLowFav = this.highToLowFav.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.checkUpdateFirmwareAvailable = this.checkUpdateFirmwareAvailable.bind(this);
    this.triggerFirmwareAvailable = this.triggerFirmwareAvailable.bind(this);
    this.triggerNoPermission = this.triggerNoPermission.bind(this);
    this.triggerFirmwareNoNeed = this.triggerFirmwareNoNeed.bind(this)
    this.triggerFirmwareUpdate = this.triggerFirmwareUpdate.bind(this);
    this.triggerisFirmwareNotAvailable = this.triggerisFirmwareNotAvailable.bind(this);
    this.togglelogBook = this.togglelogBook.bind(this)
    this.toggleImagesLimitModal = this.toggleImagesLimitModal.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.toggleImageSizeError = this.toggleImageSizeError.bind(this);
    this.toggleImageMemoryError = this.toggleImageMemoryError.bind(this);
    this.toggleImgEmptyError = this.toggleImgEmptyError.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.toggleUploadImages = this.toggleUploadImages.bind(this);
    this.deletImage = this.deletImage.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.refreshImageAfterDelete = this.refreshImageAfterDelete.bind(this);
    this.refreshImageAfterUpload = this.refreshImageAfterUpload.bind(this);
    this.toggleASetChargingLimit = this.toggleASetChargingLimit.bind(this)
    this.getMeterValuesData = this.getMeterValuesData.bind(this);
    this.toggleImagesLimit = this.toggleImagesLimit.bind(this);
    this.toggleImageTypeError = this.toggleImageTypeError.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.exportStatus = this.exportStatus.bind(this);
    this.clearOperation = this.clearOperation.bind(this);
    this.onShowAlert2 = this.onShowAlert2.bind(this);
    this.downloadSafeEVFile = this.downloadSafeEVFile.bind(this);
    this.getTableField = this.getTableField.bind(this);
    this.togglesettingPopup = this.togglesettingPopup.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.saveTableField = this.saveTableField.bind(this);
    this.ascendingOrder = this.ascendingOrder.bind(this);
    this.successTableContent = this.successTableContent.bind(this);
    this.settingAction = this.settingAction.bind(this);
    this.keyPress3 = this.keyPress3.bind(this);
    this.clearValue = this.clearValue.bind(this);
    this.getOTAStatus = this.getOTAStatus.bind(this);
    this.updateFirmware = this.updateFirmware.bind(this);
    this.getLatestOTAStatus = this.getLatestOTAStatus.bind(this);
    this.triggerOTADownloadStatus = this.triggerOTADownloadStatus.bind(this);
    this.triggerFirmwareMessage = this.triggerFirmwareMessage.bind(this);
    this.closeFirmwareMessage = this.closeFirmwareMessage.bind(this);
    this.closeCponlinestatus = this.closeCponlinestatus.bind(this);
    this.getLocalUsers = this.getLocalUsers.bind(this);
    this.toggleConfirmDeleteUser = this.toggleConfirmDeleteUser.bind(this);
    this.deleteLocalUser = this.deleteLocalUser.bind(this);
    this.toggleDeleteUser = this.toggleDeleteUser.bind(this);
    this.toggleAddUser = this.toggleAddUser.bind(this);
    this.addLocalUser = this.addLocalUser.bind(this);
    this.updateUserFields = this.updateUserFields.bind(this);
    this.toggleSuccessUser = this.toggleSuccessUser.bind(this);
    this.toggleUpdatePasswordLocalUser = this.toggleUpdatePasswordLocalUser.bind(this);
    this.updateLocalUserPassword = this.updateLocalUserPassword.bind(this);
    this.onChangeLocalUserNewPassword = this.onChangeLocalUserNewPassword.bind(this);
    this.toggleLocalUserUpdatePasswordSuccess = this.toggleLocalUserUpdatePasswordSuccess.bind(this);
    this.getCloudUsers = this.getCloudUsers.bind(this);
    this.syncLocalUsers = this.syncLocalUsers.bind(this);
    this.toggleSyncPopup = this.toggleSyncPopup.bind(this);
    this.syncChargerStats = this.syncChargerStats.bind(this);
    this.syncFreeChargeStats = this.syncFreeChargeStats.bind(this);
    this.checkIsDXModel = this.checkIsDXModel.bind(this);
    this.toggleUserMsg = this.toggleUserMsg.bind(this);
    this.configureMQTT = this.configureMQTT.bind(this);
    this.toggleMqttReqPopup = this.toggleMqttReqPopup.bind(this);
  }

  toggleSyncPopup() {
    this.setState({
      syncSuccessModal: !this.state.syncSuccessModal
    });
  }

  toggleMqttReqPopup() {
    this.setState({
      mqttReqSuccessModal: !this.state.mqttReqSuccessModal
    });
  }

  toggleLocalUserUpdatePasswordSuccess() {
    this.setState({ localUserUpdatePasswordSucess: !this.state.localUserUpdatePasswordSucess })
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1500)
  }

  onChangeLocalUserNewPassword(e) {
    let localusernewpassword = e.target.value;
    this.setState({ localUserNewPassword: localusernewpassword })
  }

  toggleUpdatePasswordLocalUser(email, password, chargePointId) {
    let errors = this.state.errors;
    errors.localUserpwdError = ''
    this.setState({
      localuserupdatepassowrd: !this.state.localuserupdatepassowrd,
      localUserEmail: email ?? '',
      localUserOldPassword: password ?? '',
      chargePointId: chargePointId ?? '',
      localUserNewPassword: '',
      errors: errors
    })
  }

  toggleSuccessUser() {
    this.setState({ userAddSucess: !this.state.userAddSucess })
  }
  toggleUserMsg() {
    this.setState({ userAddWarning: !this.state.userAddWarning })
  }

  updateUserFields(field, e) {
    let fields = this.state.userFields;
    fields[field] = e.target.value;

    this.setState({ userFields: fields });
  }

  addLocalUser() {
    let userFields = this.state.userFields;
    let errors = this.state.errors;
    let isValid = true;

    if (userFields.useremail == "") {
      errors.emailError = "Please Enter User Email";
      isValid = false
    } else {
      let emailAddress = userFields.useremail;  
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(emailAddress)) {
        errors.emailError = "";
      } else {
        errors.emailError = "Please Enter Valid Email";
        isValid = false
      }
    }

    if (userFields.userPassword == "") {
      errors.passwordError = "Please Enter User Password";
      isValid = false
    } else {
      errors.passwordError = "";
    }

    if (userFields.firstName == "") {
      errors.firstNameError = "Please Enter First Name";
      isValid = false
    } else {
      errors.firstNameError = "";
    }

    if (userFields.lastName == "") {
      errors.lastNameError = "Please Enter Last Name";
      isValid = false
    } else {
      errors.lastNameError = "";
    }

    this.setState({ errors });

    if (isValid) {
      let payload = {
        "email": userFields.useremail,
        "firstName": userFields.firstName,
        "lastName": userFields.lastName,
        "role": "WEBASTO_END_USER_RESIDENTIAL",
        "status": true,
        "chargePointId": this.state.fields.chargePointId,
        "index": 1,
        "password": userFields.userPassword,
        "userType": "LOCAL_USER"
      }

      var url = baseUrl.URLPath + servicesUrl.addLocalUser;

      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        },
        body: JSON.stringify(payload)
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response.status == 'SUCCESS') {
            this.toggleAddUser();
            let userFields = {
              useremail: '',
              userPassword: '',
              firstName: '',
              lastName: ''
            }
            this.setState({ userFields }, () => {
              this.toggleSuccessUser();
              this.getLocalUsers();
            });
          } else {
            if(response.status === "FAILURE"){
            this.toggleAddUser(); 
            let userFields = {
              useremail: '',
              userPassword: '',
              firstName: '',
              lastName: ''
            }
            this.setState({ userFields, updatemsg:response.errorMessage }, () => { 
              this.toggleUserMsg();        
              this.getLocalUsers();
            });
            }
            }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }
  }
  toggleAddUser() {
    let userFields = {
      useremail: '',
      userPassword: '',
      firstName: '',
      lastName: ''
    }
    this.setState({
      addUser: !this.state.addUser,
      userFields
    })
  }
  toggleDeleteUser() {
    this.setState({
      deleteSucess: !this.state.deleteSucess,
      isLoading: true
    }, () => {
      setTimeout(() => {
        this.getLocalUsers();
        this.setState({ isLoading: false });
      }, 10000);
    })
  }
  async configureMQTT() {
    document.getElementById("loader_image_div").style.display = "block";
    let cpId = this.state.chargePointId;
    var url = baseUrl.URLPath + servicesUrl.configureMQTTByCpId + cpId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      setTimeout(() => {
        document.getElementById("loader_image_div").style.display = "none";
        if (response.status === 404 && response.message === "Chargepoint is not exists") {
            this.setState({
              mqttReqSuccessMsg: this.props.t(response.message)
              }, () => this.toggleMqttReqPopup())
        } else {
            this.setState({
              mqttReqSuccessMsg: this.props.t(response.message)
            }, () => this.toggleMqttReqPopup())
        }}, 100);
  }
  }
  syncFreeChargeStats() {
    document.getElementById("loader_image_div").style.display = "block";
    let cpId = this.state.chargePointId;
    var url = baseUrl.URLPath + servicesUrl.syncFreeChargerStatistics + "?cpid=" + cpId;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          document.getElementById("loader_image_div").style.display = "none";
        } else if (resp.status == 500) {
          alert("500 error code")
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 403) {
          if (response.message == "package_doesn't_enabled_logbook") {
            this.setState({
              disabledLogbook: true
            })
            this.togglelogBook();
          }
        } else {
          setTimeout(() => {
            this.setState({
              syncSuccessMsg: this.props.t(response.status)
            }, () => {
              document.getElementById("loader_image_div").style.display = "none";
              this.toggleSyncPopup();
            });
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  syncChargerStats() {
    document.getElementById("loader_image_div").style.display = "block";
    let cpId = this.state.chargePointId;
    var url = baseUrl.URLPath + servicesUrl.syncChargerStatistics + "?cpid=" + cpId;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          document.getElementById("loader_image_div").style.display = "none";
        } else if (resp.status == 500) {
          alert("500 error code")
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 403) {
          if (response.message == "package_doesn't_enabled_logbook") {
            this.setState({
              disabledLogbook: true
            })
            this.togglelogBook();
          }
        } else {
          setTimeout(() => {
            this.setState({
              syncSuccessMsg: this.props.t(response.status)
            }, () => {
              document.getElementById("loader_image_div").style.display = "none";
              this.toggleSyncPopup();
            });
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  syncLocalUsers() {
    document.getElementById("loader_image_div").style.display = "block";
    let cpId = this.state.chargePointId;
    var url = baseUrl.URLPath + servicesUrl.syncLocalUsers + "?cpid=" + cpId;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          document.getElementById("loader_image_div").style.display = "none";
        } else if (resp.status == 500) {
          alert("500 error code")
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 403) {
          if (response.message == "package_doesn't_enabled_logbook") {
            this.setState({
              disabledLogbook: true
            })
            this.togglelogBook();
          }
        } else {
          setTimeout(() => {
            this.setState({
              syncSuccessMsg: this.props.t(response.status)
            }, () => {
              document.getElementById("loader_image_div").style.display = "none";
              this.toggleSyncPopup();
            });
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  //function for sorting table data on click
  ascendingOrder() {
    this.setState({ transactions: this.state.transactions.reverse() })
  }

  //to save selected table content 
  async saveTableField() {
    this.setState({ settingPopup: !this.state.settingPopup })
    let url = baseUrl.URLPath + servicesUrl.transctionsSaveTableField;
    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();
    let payload = { transactionColumns: this.state.selectedItems }
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      this.successTableContent();
    }
  }

  successTableContent = () => {
    this.setState({
      successTbContent: !this.state.successTbContent
    });
  }

  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    if (event.target.checked) {
      if (selectedItemIndex < 0) {
        allItems.push(item);
      }
    } else {
      const deletedWallboxIndex = this.state.siteWallboxes.findIndex(el => el === item);
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
      if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
        deletedWallboxes.push(item);
      }
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  togglesettingPopup() {
    this.setState({
      settingPopup: !this.state.settingPopup
    });
  }

  settingAction() {
    this.setState({
      settingPopup: !this.state.settingPopup,
      selectedItems: this.state.prevSelectedData
    });
  }

  async getTableField() {
    var url = baseUrl.URLPath + servicesUrl.transctionsGetTableField;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {}
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      if (response.status === 404) {
        this.setState({ tableCustomization: false })
      } else if (response !== undefined) {
        var prevSelectedData = [];
        for (var i = 0; i < response.length; i++) {
          if (response[i].isSelected === true) {
            this.state.selectedItems.push(response[i].key)
            prevSelectedData.push(response[i].key)
          }
        }

        this.setState({
          getFieldsList: response,
          prevSelectedData: prevSelectedData,
          tableCustomization: true
        })
      }
    }
  }



  clearValue() {
    let errors = this.state.errors;
    let fields = this.state;

    if (this.state.errors["chargePowerError"] !== "") {
      fields.findValue = '';
      errors["chargePowerError"] = "";

      this.setState({ ...fields, errors });
    } else {
      fields.findValue = '';
      errors["chargePowerError"] = "";

      this.setState({ ...fields, errors }, () => {
        this.getMeterVaules(this.state.transactionId);
      });
    }
  }

  keyPress3(e) {
    var key = e.key;
    let state = this.state;
    let errors = this.state.errors;

    if (e.keyCode === 13) {
      if (state.findValue === "") {
        errors["chargePowerError"] = this.props.t('enter_value');
        this.setState({ errors });
      } else {
        errors["chargePowerError"] = "";
        this.setState({ errors }, () => {
          this.getMeterVaules(this.state.transactionId);
        });
      }
    }

    if (key === "Backspace" || key === "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno === "") {
        state.findValue = sno;
        this.setState({ ...state }, () => {
          this.getMeterVaules(this.state.transactionId);
        })
      }
    }
  }


  exportStatus(cpid) {
    let usertimezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4).match(/\b(\w)/g).join('');
    var url = baseUrl.URLPath + "chargepoints/connectors/download/xls?chargePointId=" + cpid + "&status=" + "&timeZone=" + usertimezone;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    }).then((resp) => {
      if (resp.status == 401) {
        window.location.reload();
      } else if (resp.status == 200) {
        return resp.blob();
      }
    }).then((response) => {
      if (response != undefined) {
        let d = new Date();
        let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Connectors_Status_' + dformat + '.xls';
        a.click();
      }
    }).catch((error) => {
      console.log(error, "catch the loop")
    })
  }

  toggleImagesLimit() {
    this.setState({
      images_limit: !this.state.images_limit
    })
  }

  toggleImgEmptyError() {
    this.setState({
      imgemptyerror: !this.state.imgemptyerror
    })
  }

  toggleImageSizeError() {
    this.setState({
      imgsizeerror: !this.state.imgsizeerror
    })
  }

  toggleImageMemoryError() {
    this.setState({
      imgmemoryerror: !this.state.imgmemoryerror
    })
  }

  toggleImageTypeError() {
    this.setState({
      imgtypeerror: !this.state.imgtypeerror
    })
  }

  refreshImageAfterDelete() {
    this.setState({
      deleteModalSuccess: !this.state.deleteModalSuccess,
      deleteModal: !this.state.deleteModal
    }, () => {
      this.getImages();
    });
  }

  refreshImageAfterUpload() {
    this.setState({
      uploadModalSuccess: !this.state.uploadModalSuccess,
      isUploadImagesOpen: !this.state.isUploadImagesOpen
    }, () => {
      this.getImages();
    });
  }

  toggleDeleteModal(id) {
    this.setState({
      deleteModal: !this.state.deleteModal,
      selectedImage: id
    })
  }

  toggleUploadImages() {
    if (this.state.getImagesList.length === 2) {
      this.toggleImagesLimitModal();
    } else {
      this.setState({
        isUploadImagesOpen: !this.state.isUploadImagesOpen,
        image: [],
        previewUrl: []
      });
    }
  }

  showEntriesChange1() {

    var entries = document.getElementById("showEntries1").value;
    this.state.data.clear();
    this.setState({ pageSize1: entries, pageNo1: 1, activePage1: 1 }, () => {

      this.getMeterVaules(this.state.transactionId);
    });
  }
  handlePageChange1(pageNumber) {

    if (pageNumber != this.state.activePage1) {
      var data = this.state.data1;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage1: pageNumber, pageNo1: pageNumber }, () => {

          this.getMeterVaules(this.state.transactionId);
        });
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }
  updateInventory(e) {
    if (e.target.validity.valid) {
      this.setState({
        inventoryNumber: e.target.value
      });
      $('.error_inventoryNumber').text("");
    }
  }
  updateMileage(e) {
    if (e.target.validity.valid) {
      this.setState({
        mileage: e.target.value
      });
      $('.error_mileage').text("");
    }
  }
  successTransaction() {
    this.setState({
      successTransaction: !this.state.successTransaction,
      energyLevel: "",
      note: ""
    });
  }
  updateEnergyLevel(e) {
    var val = e.target.value;
    if (e.target.validity.valid) {
      this.setState({
        energyLevel: e.target.value
      });
      $('.error_energyLevel').text("");
    }
  }

  async getRfidTags(cpid) {
    var url = baseUrl.URLPath + servicesUrl.getOcpptagList + "?cpId=" + cpid;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response && response.status == 403) {
      this.setState({ rfidTagsList: [] })
    } else if (response != undefined) {
      this.setState({ rfidTagsList: response.data ?? [] })
    }
  }

  idTagChange() {
    var x = document.getElementById("tagList").value;
    this.setState({ selectedRfid: x }, () => { $('.error_rfid').text(""); });
  }

  changeScheduleTo(e) {
    this.setState({ scheduletoDate: e.target.value });
  }

  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }
  togglesucesslogbook() {
    this.setState({
      sucesslogbook: !this.state.sucesslogbook
    });
  }
  async logbookActivateDeactivate(event) {
    let fields = this.state.fields;
    fields["logbookData"] = !this.state.fields.logbookData;
    this.setState({
      fields: fields
    });

    let logbookData = false;
    if (this.state.fields.logbookData != null && this.state.fields.logbookData !== undefined) {
      logbookData = this.state.fields.logbookData
    }
    let chargePointId = this.state.fields.chargePointId;
    var url = baseUrl.URLPath + "chargepoints/logbook?chargePointId=" + chargePointId + "&logbookData=" + logbookData;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.status === 403) {
      if (response.message === "package_doesn_t_enabled_logbook") {
        this.setState({
          disabledLogbook: true
        })
        this.togglelogBook();
      }
    } else {
      this.setState({
        logbookSuccessMessage: this.props.t(response.message)
      });
      this.togglesucesslogbook();
    }
  }

  // get Date
  convertMinSec(time) {
    if (time != undefined) {
      var time_min = time.split(":");
      var day_min = time_min[0] * 1440;
      var hr_min = time_min[1] * 60;
      var min = time_min[2];
      var total_min = Math.round(parseInt(day_min) + parseInt(hr_min) + parseInt(min));
      return total_min + " min / " + time_min[3] + "sec";
    }
  }
  changeWallboxEmail(e) {
    this.setState({ assignedmail: e.target.value });
  }
  togglewallboxError() {
    this.setState({
      wallboxError: !this.state.wallboxError
    })
  }
  togglewallboxSucess() {
    this.setState({
      wallboxSucess: !this.state.wallboxSucess
    });
    window.location.reload();
  }
  togglewallboxConfirm() {
    this.setState({
      confirmdelete: !this.state.confirmdelete
    })
  }
  async assignWallbox(email) {
    document.getElementById("loader_image_div").style.display = "block";
    let chargePointId = this.state.fields.chargePointId
    var url = baseUrl.URLPath + "chargepoints/assign?chargePointId=" + chargePointId + "&email=" + email;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    if (email != undefined && email?.trim() != "") {
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

      if (response.status === 404 || response.status === 409) {
        document.getElementById("loader_image_div").style.display = "none";
        // this.setState({ error: this.props.t('no_user_exists_on_this_email_address'), wallboxError: true });
        this.setState({ error: this.props.t(response.message), wallboxError: true });
        this.togglewallboxError.bind(this);
      } else {
        document.getElementById("loader_image_div").style.display = "none";
        this.setState({ sucessMessage: this.props.t(response.message), wallboxSucess: true });
        this.togglewallboxSucess.bind(this);
      }
    } else {
      document.getElementById("loader_image_div").style.display = "none";
      this.setState({ error: this.props.t('user_email_cannot_be_empty'), wallboxError: true });
      this.togglewallboxError.bind(this);
    }
  }
  async removeWallbox(email) {
    document.getElementById("loader_image_div").style.display = "block";
    this.setState({
      confirmdelete: !this.state.confirmdelete
    })
    let chargePointId = this.state.fields.chargePointId
    var url = baseUrl.URLPath + "chargepoints/remove?chargePointId=" + chargePointId + "&email=" + email;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.status === 404 || response.status === 409) {
      document.getElementById("loader_image_div").style.display = "none";
      this.setState({ error: this.props.t(response.message), wallboxError: true });
      this.togglewallboxError.bind(this);
    } else {
      document.getElementById("loader_image_div").style.display = "none";
      this.setState({ sucessMessage: this.props.t(response.message), wallboxSucess: true });
      this.togglewallboxSucess.bind(this);
    }
  }
  // function for value filter
  onChangeValue() {
    let errors = this.state.errors;


    var getValues = document.getElementById("findValue").value;
    this.setState({ findValue: getValues }, () => {
      if (this.state.findValue) {
        this.getMeterVaules(this.state.transactionId);
      } else {
        errors["chargePowerError"] = this.props.t('enter_value');

        this.setState({ errors });
      }
    });
  }
  getPayLoad() {
    var payload = {
      "location": this.state.location
    };
    return payload;
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    this.state.data.clear();

    let fromDateApi = '';
    if (this.state.fromDate != "" && this.state.fromDate != null && this.state.fromDate != undefined) {
      fromDateApi = dateformatinUTC(moment(this.state.fromDate));
    }
    let toDateApi = '';
    if (this.state.toDate != "" && this.state.toDate != null && this.state.toDate != undefined) {
      toDateApi = dateformatinUTC(moment(this.state.toDate));
    }

    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: fromDateApi,
      toDateApi: toDateApi
    }, () => {
      this.getTransactions(entries, 1);
    });
  }
  // date picker onchange function
  onChangeMeterValueDate(value, event) {

    if (value != null && value != "") {
      var startDate = moment(value[0]).format("YYYY-MM-DD").toUpperCase();
      var endDate = moment(value[1]).format("YYYY-MM-DD").toUpperCase();
      this.setState({
        value: value,

      })
      this.state.startDate1 = startDate;
      this.state.endDate1 = endDate;

      this.getMeterVaules(this.state.transactionId);
    } else {
      this.setState({
        value: value
      })
    }
    var calenderfromDisplay = "none";
    this.setState({
      calenderfromDisplay: calenderfromDisplay
    })

  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getTransactions(this.state.pageSize, pageNumber, "", false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: dateformatinUTC(this.state.fromDate),
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getTransactions(this.state.pageSize, pageNumber, "", false);
      });
    }
  }

  // get Date
  changeColor(transaction) {
    var bgColor1 = '';
    var bgColor2 = '';
    var tableView = "";
    var graphView = "";
    if (this.state.color_black == true) {
      bgColor1 = "active";
      bgColor2 = "";
      tableView = "none";
      graphView = "block";
      this.getMeterValuesChart();
    } else {
      bgColor1 = "";
      bgColor2 = "active";
      tableView = "block";
      graphView = "none";
      this.state.activePage = 1;
      this.getMeterVaules(this.state.transactionId);

    }
    this.setState({ color_black: !this.state.color_black, bgColor1: bgColor1, bgColor2: bgColor2, tableView: tableView, graphView: graphView })
  }

  formatCalDate(date) {
    var format = date.toISOString().substr(0, 19) + "Z";
    return format;
  }
  //new
  formatLocalCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    var monthname = d.toLocaleString('default', { month: 'short' });
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [day, monthname, year].join(' ');
  }

  async togglesession() {
    ;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  async togglesession1() {
    this.setState({
      modelwallbox: !this.state.modelwallbox,
      assignedmail: ""
    });
  }

  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  onShowAlert() {

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }
  onShowAlert1() {

    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false })
      }, 5000)
    });

  }

  onShowAlert2() {

    this.setState({ sucess2: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess2: false })
      }, 5000)
    });

  }

  error(error) {
    console.log(error);
  }
  onDocumentLoad = ({ numPages, pageNumber }) => {
    this.setState({
      numPages, pageNumber
    })
    // this.changePage(0);
  }
  onItemClick = ({ pageNumber }) =>
    this.setState({
      pageNumber
    })
  previousPage = () => this.changePage(-1)
  nextPage = () => this.changePage(1)
  changePage = offset =>
    this.setState(prevState => ({
      pageNumber: (prevState.pageNumber || 1) + offset,
    }))

  redirectToListPage() {
    this.props.history.push('/chargepoint')
  }

  toggle1(event) {
    event.preventDefault();
    ;
    this.setState({
      modal1: !this.state.modal1
    });
  }
  async saveMileageNdInventory(mileage, inventoryNumber) {
    if (mileage == "" || mileage == null) {
      $('.error_mileage').text(this.props.t('mileage_not be_empty'));
    }
    if (inventoryNumber == "" || inventoryNumber == null) {
      $('.error_inventoryNumber').text(this.props.t('inventory_no_not_beempty'));
    }
    if (mileage != "" && mileage != null && mileage > 0 && mileage <= 999999) {
      $('.error_mileage').text("");
    } else {
      $('.error_mileage').text(this.props.t('milege_min_max_validation'));
    }
    if (inventoryNumber != "" && inventoryNumber != null && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
      $('.error_inventoryNumber').text("");
    } else {
      $('.error_inventoryNumber').text(this.props.t('inventory_no_have_min1and_max20_char'));
    }
    if (mileage != "" && mileage != null && inventoryNumber != "" && inventoryNumber != null && mileage > 0 && mileage <= 999999 && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
      let url = baseUrl.URLPath + servicesUrl.updateByTranId + "?tranId=" + this.state.transId + "&mileage=" + this.state.mileage + "&vehicleInventoryNumber=" + this.state.inventoryNumber;
      let type = ApiMethodTypes.GET;
      let headers = getSecurityHeaders();
      let payload = {};

      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if (response) {
        this.toggleMileageNdInventory();
        this.toggleSaveMileageNdInventory();
      }
    }
  }
  toggleSaveMileageNdInventory = () => {
    this.setState({
      modalMileageNdInventory: !this.state.modalMileageNdInventory
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  tagOk1() {
    this.props.history.push('/chargepoint');
  }

  tagOk2() {
    //  this.props.history.push('/chargepoint');
  }

  componentWillMount() {
    let userPackage = JSON.parse(localStorage.getItem("userPackage"));
    if (userPackage !== null) {
      let reservationSeatingEnabled = userPackage.reservationSeatingEnabled;
      this.setState({
        reservationSeatingEnabled: reservationSeatingEnabled
      })
    }
  }

  // displaying value in all the fields
  componentDidMount() {
    this.state.user.permissions = localStorage.getItem("roleAccess");

    this.state.location = baseUrl.URLPath + "diagnostics/SN/{cpId}";
    this.setState({
      location: baseUrl.URLPath + "diagnostics/SN/{cpId}"
    })
    //  alert(JSON.stringify(this.props.location.state));
    if (this.state.writePermission == true) {
      this.state.hideActionBtn = "inline-block";
      // this.setState({
      //   hideActionBtn: "inline-block"
      // })
    }


    if (this.props.location.state == null) {
      var data = JSON.parse(localStorage.getItem("cpview"));
      //  var data= JSON.parse(localStorage.getItem("cpview"));
      this.state.id = data.id;
      this.state.chargePointId = data.chargePointId;
      this.state.cpmodel = data.cpmodel;
      // this.state.onlineStatus = data.onlineStatus;
    } else {

      var fdfd = this.state.id;
      this.state.id = this.props.location.state.id;
      this.state.chargePointId = this.props.location.state.chargePointId;
      this.state.cpmodel = this.props.location.state.cpmodel;
      // this.state.onlineStatus = this.props.location.state.onlineStatus;
      var data = {
        id: this.state.id,
        chargePointId: this.state.chargePointId,
        cpmodel: this.state.cpmodel,
      }
      localStorage.setItem("cpview", JSON.stringify(data));
    }
    this.getChargePointDetails();
    this.getCustomization();
    this.setState({ id: this.props.location.state })
    this.getRfidTags(this.state.chargePointId);
    this.getOTAStatus();

    if (localStorage.getItem("role") != roles.WEBASTO_CUSTOMER_ADMIN_READ_ONLY) {
      this.getOperationsForResidential();
    }


    if (this.props.customizationSettings && Array.isArray(this.props.customizationSettings)) {
      const oprManagementObj = this.props.customizationSettings.find(el => el.operation === "Operations");
      if (oprManagementObj) {
        this.setState({ ...this.state, isOperationAvailable: oprManagementObj.active });
      } else {
        this.setState({ ...this.state, isOperationAvailable: false });
      }
    }

    this.checkIsDXModel();
  }

  componentDidUpdate(prevProps) {
    if (this.props.model_name !== prevProps.model_name) {
      this.checkIsDXModel();
    }
  }

  checkIsDXModel() {
    let model_name = this.state.cpmodel;
    let position = model_name?.search(/(?:DX|FC)/i);

    if (model_name && position > -1) {
      this.setState({
        modelName: model_name,
        isModelDX: true,
      });
    } else {
      this.setState({
        modelName: model_name,
        isModelDX: false,
      });
    }
  }

  async getOTAStatus() {
    var url = baseUrl.URLPath + servicesUrl.getOTAStatus + "?chargepointId=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      this.setState({
        firmwareCurrentVersion: response?.currentVersion,
        firmwareStatus: response?.status,
        firmwareTotalCount: response?.totalCount,
        firmwareUpdatedCount: response?.updatedCount
      })
    }
  }

  async getLatestOTAStatus() {
    var url = baseUrl.URLPath + servicesUrl.getOTAStatus + "?chargepointId=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      this.setState({
        firmwareCurrentVersion: response?.currentVersion,
        firmwareStatus: response?.status,
        firmwareTotalCount: response?.totalCount,
        firmwareUpdatedCount: response?.updatedCount
      }, () => {
        this.triggerOTADownloadStatus();
      })
    }
  }

  triggerOTADownloadStatus() {
    this.setState({ firmwareDownloadStatus: !this.state.firmwareDownloadStatus })
  }

  async getChargePointDetails() {
    document.getElementById("loader_image_div").style.display = "block";
    var url = baseUrl.URLPath + servicesUrl.getChargePointDetails + "?id=" + this.state.id;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      if (response.data.address === null) {
        var fields1 = [];
        fields1.chargePointId = response.data.chargePointId
        fields1.id = response.data.id
        fields1.description = response.data.description
        fields1.description = response.data.description
        fields1.chargePointVendor = response.data.chargePointVendor
        fields1.chargePointModel = response.data.chargePointModel;
        fields1.installationSite = response.data.installationSite;
        fields1.logBookAccess = response.data.logBookAccess;
        fields1.logbookData = response.data.logbookData;
        fields1.isSiteAdminAccess = response.data.isSiteAdminAccess;
        fields1.isChargerOwner = response.data.isChargerOwner;
        fields1.vendorName = response.data.vendorName;
        fields1.address = {
          addressId: '',
          street: "",
          houseNumber: "",
          zipCode: "",
          city: "",
          country: "",
          createdTime: "",
          modifiedTime: "",
          locationLatitude: "",
          locationLongitude: "",
        }
        fields1.assignedEmail = response.data.assignedEmail;
        this.setState({ fields: fields1 })
        document.getElementById("loader_image_div").style.display = "none";
      } else {
        this.state.assignedEmail = response.data.assignedEmail;
        this.setState({ fields: response.data });
        $('#assignedEmail').text(response.data.assignedEmail);
        if (this.state.fields.address.country != null && this.state.fields.address.country != "") {
          // this.state.fields.address.country = "country"
        }
      }
      this.setState({ rEditAccess: response.data.editAccess })
      this.state.assignedEmail = response.data.assignedEmail;
      this.state.onlineStatus = response.data.backendConnection;
      document.getElementById("loader_image_div").style.display = "none";
    }
  }

  //hide operations for residential user login
  async getOperationsForResidential() {
    let url = baseUrl.URLPath + servicesUrl.getChargePointOperations + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      this.state.opertaion_permissions.permissions = response
      this.setState({
        opertaion_permissions: {
          permissions: response
        }
      })
    }
  }

  // tab view click
  tabToggle(tab, event) {
    this.state.schedulefromDate = "";
    this.state.scheduletoDate = "";

    var data = {
      id: this.state.id,
      chargePointId: this.state.chargePointId
    }
    // localStorage.setItem('chargePoint', 'chargePointId')
    localStorage.removeItem("operation")
    // localStorage.setItem('operationId', data.chargePointId);
    //this.props.history.state=data;
    this.props.location.state = data;
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
      if (tab == 4) {
        this.setState({
          message: "",
          sucess: false
        })
        if (this.state.basepdfurl != "") {
        } else {
          this.setState({
            pdfloading: "true",
          })
          this.getAttachment();
        }
      }
      if (tab == 6) {
        this.getChargePointConfiguration();
      }
      if (tab == 2) {
        var x = document.getElementById("metervalues")
        var y = document.getElementById("transactions")
        if (x.style.display === "block" && y.style.display === "block") {
          x.style.display = "block";
          y.style.display = "none";
        } else {
          x.style.display = "none";
          y.style.display = "block";
        }

        // var x = document.getElementById("metervalues")
        // if (x.style.display === "block") {
        //   x.style.display = "block";
        // } else {
        //   x.style.display = "none";
        // }

        let d = new Date();
        d.setHours(0, 0, 0);

        let fromdate = this.formatLocalCalDate(d);

        this.state.startDate2 = moment(d).utc().format("YYYY-MM-DDTHH:mm:ss:SSS");


        this.state.schedulefromDate = fromdate;
        this.state.scheduletoDate = "";

        let fromDate = moment().subtract(1, 'd');
        let fromDateApi = dateformatinUTC(moment().subtract(1, 'd'));
        let toDate = new Date();
        let toDateApi = dateformatinUTC(moment(toDate));

        this.setState({
          fromDate: new Date(fromDate),
          fromDateApi: fromDateApi,
          toDate: toDate,
          toDateApi: toDateApi,
        }, () => {
          this.getTableField();
          this.getTransactions(this.state.pageSize, this.state.activePage);
        });
      } else if (tab == 3) {
        this.getConnectorStatus();
      }
      else if (tab == 5) {
        this.setState({
          message: "",
          sucess: false
        })
        if (this.state.diagnosticsData.fileName != "") {
          this.setState({
            loading: "true"
          })
        }
        this.getDignosticsData();
      } else if (tab == 7) {
        this.getImages()
      } else if (tab == 8) {
        this.getLocalUsers();
      } else if (tab == 9) {
        this.getCloudUsers(1, 10);
      }

    }

  };

  async getCloudUsers(pageNo, pageSize) {

    var url = baseUrl.URLPath + "web/chargers/" + this.state.chargePointId + "/cloudusers?pageNo=" + pageNo + "&pageSize=" + pageSize;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray2: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getLocalUsers() {
    let url = baseUrl.URLPath + servicesUrl.getLocalUsers + "?chargePointId=" + this.state.chargePointId;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
      if (response != undefined) {
        this.setState({ localUsers: response })
      }
    }).catch((error) => {
      console.log(error, "get connector status catch the loop")
    })
  }
  toggleConfirmDeleteUser(email) {
    this.setState({
      confirmdeleteuser: !this.state.confirmdeleteuser,
      deleteEmail: email ?? ''
    })
  }
  deleteLocalUser() {
    this.toggleConfirmDeleteUser();

    let url = baseUrl.URLPath + servicesUrl.deleteLocalUser + "?chargePointId=" + this.state.chargePointId + "&email=" + this.state.deleteEmail;

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
      if (response.message != "") {
        this.setState({
          deleteMessage: response.message,
          deleteSucess: true
        })
      }
    }).catch((error) => {
      console.log(error, "get connector status catch the loop")
    })
  }

  async updateLocalUserPassword() {
    let localuseremail = this.state.localUserEmail;
    var localuseroldpassword = this.state.localUserOldPassword;
    var localusernewpassword = this.state.localUserNewPassword;
    var chargepointid = this.state.chargePointId;

    let errors = this.state.errors;
    let validPassword = true;

    if (localusernewpassword == '') {
      errors.localUserpwdError = "Password cannot be empty";
    } else if (localusernewpassword != '') {
      if (!localusernewpassword.match("(?=.*[0-9])"
        + "(?=.*[a-z])(?=.*[A-Z])"
        + "(?=.*[!@#$%^&+=])"
        + "(?=\\S+$).{8,128}$")) {
        errors.localUserpwdError = "Your password should be minimum 8 characters should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
        validPassword = false;
      }
    }

    if (localusernewpassword != '' && validPassword) {

      let url = baseUrl.URLPath + servicesUrl.updateLocalUserPassword + "?email=" + localuseremail + "&chargePointId=" + chargepointid + "&oldPassword=" + localuseroldpassword + "&newPassword=" + localusernewpassword;

      fetch(url, {
        method: 'PUT',
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      }).then((resp) => {
        return resp.json();
      }).then((response) => {
        console.log(response);
        if (response.status == 404) {
          if (response.errorMessage == 'usre_not_exits') {
            errors.localUserpwdError = 'User not exists'
            this.setState({
              errors: errors
            });
            localStorage.clear()
          }
        }
        if (response.status == 'FAILURE') {
          if (response.errorMessage == 'old_password_does_not_match') {
            errors.localUserpwdError = 'Old Password does not match'
            this.setState({
              errors: errors
            });
          } else if (response.message == "password_shouldn_t_contain_name") {
            errors.localUserpwdError = "Password could not contain username";
            this.setState({
              errors: errors
            });
          } else if (response.message == "password_already_used") {
            errors.localUserpwdError = "Password is already used.";
            this.setState({
              errors: errors
            });
          }
        } else {
          if (response.status === 'SUCCESS' || response.updateStatus === true) {
            this.toggleUpdatePasswordLocalUser();
            this.toggleLocalUserUpdatePasswordSuccess();
            this.getLocalUsers();
          }
        }
      })
    }

    this.setState({ errors: errors });
  }

  /* To get the connector status of selected charge point
  */
  async getConnectorStatus() {
    let url = baseUrl.URLPath + servicesUrl.getChargePointConnectorSearch + "?chargePointId=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response != undefined) {
      this.setState({ connectorStatus: response.data })
    }
  }

  /* To get the transactions of selected charge point*/
  async getTransactions(pageSize, pageNo, countValue) {
    document.getElementById("loader_image_div").style.display = "block";

    let chargePointId = this.state.fields.chargePointId

    let url = baseUrl.URLPath + "transactions?chargepointid=" + chargePointId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response.count.count == 0) {
      setTimeout(() => {
        this.setState({
          transactions: [],
          noOfRecords: 0
        }, () => {
          document.getElementById("loader_image_div").style.display = "none";
        })
      }, 100);
    } else {
      if (countValue == false) {
        setTimeout(() => {
          this.setState({
            prevPageFirstCreTime: this.state.curPageFirstCreTime,
            transactions: response.recordList,
            curPageFirstCreTime: moment(response.recordList[0].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            curPageLastCreTime: moment(response.recordList[response.recordList.length - 1].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS")
          }, () => {
            document.getElementById("loader_image_div").style.display = "none";
          })
        }, 100);
      } else {
        setTimeout(() => {
          this.setState({
            prevPageFirstCreTime: this.state.curPageFirstCreTime,
            transactions: response.recordList,
            noOfRecords: response.count.count,
            curPageFirstCreTime: moment(response.recordList[0].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            curPageLastCreTime: moment(response.recordList[response.recordList.length - 1].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          }, () => {
            document.getElementById("loader_image_div").style.display = "none";
          })
        }, 100);
      }
    }
  }

  //api to get metre values chart
  async getMeterValuesChart() {

    var chart_Date = [];
    var chart_Count = [];
    var url = baseUrl.URLPath + servicesUrl.getTransactionsMetervalueBytransid + "?transId=" + this.state.transactionId + "&chargePointId=" + this.state.traCPId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response != undefined) {
      this.setState({
        meterValuesChart: response.data,

      })
      for (var i = 0; i < response.data.length; i++) {
        var date = moment(response.data[i].valueTime).format("hh:mm A").toUpperCase();
        chart_Date.push(date);
        chart_Count.push(response.data[i]["chargingPower"]);
      }
      this.state.dataLine = {
        labels: chart_Date,
        datasets: [
          {

            label: "Count",
            data: chart_Count,
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",

          }
        ]
      }

      this.setState({ datasets: response.data })
    }
  }

  async exportTransactionCSV() {

    let fromDate = '';
    if (this.state.fromDate != '' && this.state.fromDate != undefined) {
      fromDate = dateformatinUTC(this.state.fromDate)
    }

    let toDate = '';
    if (this.state.toDate != '' && this.state.toDate != undefined) {
      toDate = dateformatinUTC(this.state.toDate)
    }

    let chargePointId = this.state.fields.chargePointId
    // var timezone = moment().tz(moment.tz.guess()).format('z');
    var url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=CSV&chargepointid=" + chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);

    if (response !== undefined) {
      let d = new Date();
      let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Charging_session_' + dformat + '.csv';
      a.click();
    }
  }

  addNew() {
    this.props.history.push('/chargePoint');
  }

  // back button click
  backToList() {
    this.props.history.push('/chargePoint');
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    let errors = this.state.errors;

    let findValue = "";
    if (field === "chargePointId" || field === "description") {
      fields[field] = e.target.value;
    } else {
      fields['address'][field] = e.target.value;
    }
    if (field === "findValue") {
      errors["chargePowerError"] = "";
      findValue = e.target.value
    }
    this.setState({ fields, findValue, errors });
  }
  changeOperations(field, e) {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.getOperations = document.getElementById('onChangeOperationValue')
    errors['enterOper'] = '';

    this.setState({ fields }, () => {
      if (fields.getOperations.value.length == 0) {
        this.setState({
          tempOperation: this.state.getOperationList,
          errors: errors
        });
      }
    });
  }

  clearOperation() {
    let errors = this.state.errors;
    let fields = this.state.fields;

    errors['enterOper'] = '';
    fields["onChangeOperationValue"] = '';

    this.setState({
      tempOperation: this.state.getOperationList,
      errors: errors,
      fields: fields
    });
  }

  //  Enable all the inputs for editing onclick of edit button 
  editChargePoint() {
    this.props.history.push('./editChargepoint');
  }

  showTrasactions() {
    var x = document.getElementById("transactions")

    x.style.display = "block";


    var metervalues = document.getElementById("metervalues")

    metervalues.style.display = "none";

  }

  // api to get business errors
  async getBusinessErrors() {
    var url = baseUrl.URLPath + servicesUrl.getTranBusinessErrorsByTransId + "?tranId=" + this.state.transactionId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ businessError: response })
    }
  }

  async getMeterValuesData(transid) {
    let url = baseUrl.URLPath + servicesUrl.getTransactionsByTransId + "?tranId=" + transid + "&chargepointid=" + this.state.traCPId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      this.setState({
        transData: response
      })
    }
  }

  viewMeterValues(transaction) {

    this.state.connectorId = transaction.connectorId;
    this.state.transactionId = transaction.transactionId;
    this.state.traCPId = transaction.chargePointId;
    //this.state.transData = transaction;
    this.setState({
      transactionId: transaction.transactionId,
      traCPId: transaction.chargePointId
    });
    this.getMeterVaules(transaction.transactionId);
    this.getMeterValuesChart();
    this.getMeterValuesData(transaction.transactionId)
    // this.getMeterValuesChart(transaction.transactionId);
    this.getBusinessErrors();
    var x = document.getElementById("transactions")
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

    var x = document.getElementById("metervalues")
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  async getMeterVaules(id) {
    let errors = this.state.errors;
    let fromdate = "";
    let todate = "";

    if (this.state.startDate1 !== '' && this.state.startDate1 !== undefined && this.state.startDate1 != null) {
      fromdate = moment(this.state.startDate1).format("MM-DD-YYYY");
    }

    if (this.state.startDate1 !== '' && this.state.startDate1 !== undefined && this.state.startDate1 != null) {
      todate = moment(this.state.endDate1).format("MM-DD-YYYY");
    }

    let value = this.state.findValue;
    if (value !== "" && this.state.errors["chargePowerError"] !== "" && this.state.errors["chargePowerError"] !== undefined) {
      value = "";
    }

    var url = baseUrl.URLPath + servicesUrl.getTransMeterValue + "?transId=" + id + "&chargepointid=" + this.state.traCPId + "&value=" + value + "&fromDate=" + fromdate + "&toDate=" + todate + "&pageNo=" + this.state.pageNo1 + "&pageSize=" + this.state.pageSize1;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    localStorage.setItem("refreshId", id)
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response !== undefined) {
      if (response.count.count === 0) {
        if (this.state.findValue !== "") {
          errors["chargePowerError"] = this.props.t('value_not_exists');

          this.setState({ errors });
        }
      } else {
        this.setState({ metervalues: response.meterValues, noOfRecords1: response.count.count })
      }
    }
  }

  async getDignosticsData() {

    var url = baseUrl.URLPath + servicesUrl.getDiagnosticsCPDetails + "?chargePointId=" + this.state.chargePointId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.state.loading = "false"
      if (response.status === 404) {
        this.setState({ message: this.props.t(response.message), colour: 'danger' })
        this.onShowAlert();
      } else {
        this.setState({ diagnosticsData: response })
      }

    }
  }

  async getMeterVaulesByRefresh() {
    this.getMeterValuesData(this.state.transactionId)
    this.getBusinessErrors();
    var onRefreshId = localStorage.getItem("refreshId");
    var url = baseUrl.URLPath + servicesUrl.getTransactionsMetervalueBytransid + "?transId=" + onRefreshId + "&chargePointId=" + this.state.traCPId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ metervalues: response.data })
    }
  }


  async getAttachment() {
    var url = baseUrl.URLPath + servicesUrl.getCPattachmentBySerialnumber + "?serialNumber=" + this.state.fields.chargePointSerialNumber;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.status === 404) {
      this.setState({ message: this.props.t('commission_report_not_exists'), colour: 'danger' })
      this.onShowAlert();
      this.setState({
        pdfloading: "false"
      })
    } else if (response !== undefined) {
      if (response.base64EncodePDF == null) {
        this.setState({ message: this.props.t('commission_report_not_exists'), colour: 'danger' })
        this.onShowAlert();
        this.setState({
          pdfloading: "false"
        })
      } else {
        this.setState({
          basepdfurl: response.base64EncodePDF,
          pdfloading: "false"
        })
      }
    }
  }

  lowToHigh() {
    this.state.configKeys.sort(function (a, b) {
      return a.value - b.value;
    });
  }

  lowToHighFav() {
    this.state.configFavKeys.sort(function (a, b) {
      return a.value - b.value;
    });
  }

  highToLowFav() {
    this.state.configFavKeys.sort(function (a, b) {
      return b.value - a.value;
    });
  }

  highToLow() {
    this.state.configKeys.sort(function (a, b) {
      return b.value - a.value;
    });
  }


  sortByAtoZ() {
    this.state.configKeys.sort((book1, book2) => {
      return this.compareObjectsSortByAtoZ(book1, book2, 'key')
    })

  }
  sortByAtoZFav() {
    this.state.configFavKeys.sort((book1, book2) => {
      return this.compareObjectsSortByAtoZ(book1, book2, 'key')
    })

  }


  compareObjectsSortByAtoZ(object1, object2, key) {
    const obj1 = object1[key].toUpperCase()
    const obj2 = object2[key].toUpperCase()

    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }
    return 0
  }
  sortByZtoA() {
    this.state.configKeys.sort((book1, book2) => {
      return this.compareObjectsSortByZtoA(book1, book2, 'key')
    })
  }

  sortByZtoAFav() {
    this.state.configFavKeys.sort((book1, book2) => {
      return this.compareObjectsSortByZtoA(book1, book2, 'key')
    })
  }


  compareObjectsSortByZtoA(object1, object2, key) {
    const obj1 = object1[key].toUpperCase()
    const obj2 = object2[key].toUpperCase()

    if (obj1 > obj2) {
      return -1
    }
    if (obj1 < obj2) {
      return 1
    }
    return 0
  }

  async getChargePointConfiguration() {

    var url = baseUrl.URLPath + servicesUrl.getChargePointConfiguration + "?chargePointId=" + this.state.chargePointId; // /api/chargepoints/configuration/{chargePointId}
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      if (response.status === 404) {
        this.setState({ configmessage: this.props.t(response.message), colour3: 'danger' }, () => {
          this.onShowAlert2();
        });
      } else {
        this.setState({
          configKeys: response.configurationKey,
          configFavKeys: response.favconfigurationKey,
        })
      }
    }
  }

  async getConfigurations(event) {
    var keys = [];
    let payload = { "key": keys }
    let response = await APICallUtility.cpmsProcessMessages(baseUrl.GET_CONFIGURATION, payload, [this.state.chargePointId], this.props.t);

    if (response === "Bad request") {
      this.state.responseFromAPI = this.props.t('bad_request');
    } else if (response === "Request is processed Successfully") {
      this.setState({ getConfigMessage: this.props.t('Request is processed Successfully'), colour1: 'success' })
      this.onShowAlert1();
    } else {
      this.setState({ getConfigMessage: this.props.t('bad_request'), colour1: 'danger' })
      this.onShowAlert1();
    }
  }

  displayfromCalender(type, event) {
    var calenderfromDisplay = "";
    if (type === "from") {
      calenderfromDisplay = "block";
    } else {
      calenderfromDisplay = "none";
    }
    this.setState({
      calenderfromDisplay: calenderfromDisplay,
    })
  }

  //sorting functionality for Charging Session Table
  ascendingOrderForTransactions() {

    this.setState({ transactions: this.state.transactions.reverse() })


  }

  //sorting functionality for Connector Status Table
  ascendingOrderForConnectorStatus() {
    this.setState({ connectorStatus: this.state.connectorStatus.reverse() })
  }

  //change function for operation  filter
  onChangeOperationsFilter() {
    var oper = document.getElementById("onChangeOperationValue").value;
    var filterData = this.state.getOperationList.filter(x => (x["name"].toLowerCase()).includes(oper.toLowerCase()));

    if (oper?.length === 0) {
      let errors = this.state.errors;
      errors['enterOper'] = this.props.t('enter_operation_name');

      this.setState({
        errors: errors
      });
    } else if (filterData?.length === 0) {
      let errors = this.state.errors;
      errors['enterOper'] = this.props.t('operation_not_found');

      this.setState({
        errors: errors
      });
    } else {
      this.setState({ tempOperation: filterData })
    }
  }
  closeMeterValuesCalender(type, event) {

    if (type == "from") {
      this.setState({
        calenderfromDisplay: "none"
      })
    }
  }

  getMessageType() {
    return baseUrl.GET_DIAGNOSTICS;
  }
  async getDiagnostics(event) {
    var payload = this.getPayLoad();

    var cpList = [];
    var page_title1 = "";
    if (this.props.location.state == null) {
      var data1 = JSON.parse(localStorage.getItem("cpview"));
      page_title1 = data1.chargePointId;
    } else {
      page_title1 = this.props.location.state.chargePointId;
    }

    cpList.push(page_title1);

    if (cpList?.length !== 0 && payload.location !== "") {
      var response = await APICallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), cpList, this.props.t);
      //alert(response);
      if (response === "Unable to process your request, please try after some time.") {
        this.setState({ message: this.props.t(response), colour: 'danger' })
        this.onShowAlert();
      } else {
        this.setState({ message: this.props.t(response), colour: 'success' })
        this.onShowAlert();
      }
    }
  }
  async getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    response.map((operation, index) => {
      if (operation.operation === "Operations") {
        this.state.getOperationList = operation.subOperations;
        this.state.tempOperation = operation.subOperations;
        this.state.isOperationsActive = operation.active;
      }
    })
    this.setState({ getOperationsList: response })
  }

  toggleRemoteStart(cpId, trans_id, energylevel, idtag, Note) {
    if (cpId !== "" && cpId !== undefined && cpId != null) {
      let active = false;
      if (energylevel !== "" && energylevel != null && energylevel !== undefined && energylevel > 0) {
        active = true;
      } else {
        active = false;
      }
      this.setState({ remoteStart: !this.state.remoteStart, selectedcpId: cpId, selectedTransactionId: trans_id, energyLevel: energylevel, selectedRfid: idtag, note: Note, isactiveEnergy: active });
    } else {
      this.setState({ remoteStart: !this.state.remoteStart });
    }
  }
  async beginRemoteStart(cpId, transactionId) {
    var url = baseUrl.URLPath + "cs/operations/RemoteStartTransaction";
    let cpLists = [];
    cpLists.push(cpId);

    let data = {
      cplist: cpLists,
      payload: { idTag: this.state.selectedRfid, connectorId: 1},
      type: "RemoteStartTransaction",
      note: this.state.note,
      energyLevel: this.state.energyLevel,
      transactionId: transactionId
    };
    let selectedrfid = "";
    if (this.state.selectedRfid !== "" && this.state.selectedRfid != null && this.state.selectedRfid !== undefined) {
      selectedrfid = this.state.selectedRfid.trim();
    }
    if (this.state.selectedRfid === "" || this.state.selectedRfid == null || this.state.selectedRfid === undefined || this.state.selectedRfid === "-- Select RFID Tag * --" || selectedrfid === "") {
      $('.error_rfid').text(this.props.t('rfid_not_be_empty'));
    } else if (this.state.isactiveEnergy && (this.state.energyLevel === "" || this.state.energyLevel === undefined || this.state.energyLevel == null)) {
      $('.error_energyLevel').text(this.props.t('energy_level_notbe_empty'));
    }
    else if (this.state.energyLevel !== "" && this.state.energyLevel != null && this.state.energyLevel !== undefined && (parseFloat(this.state.energyLevel) <= 0 || parseFloat(this.state.energyLevel) > 9999999999)) {
      if (parseFloat(this.state.energyLevel) <= 0) {
        $('.error_energyLevel').text(this.props.t('energy_level_begreater_than0'));
      }
      if (parseFloat(this.state.energyLevel) > 9999999999) {
        $('.error_energyLevel').text(this.props.t('energy_level_not_be_greater_than9999999999'));
      }
    } else {
      let type = ApiMethodTypes.POST;
      let headers = getSecurityHeaders();

      let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);

      if (response?.status === 403) {
        this.toggleASetChargingLimit();
      } else if (response?.status === 404 && response.message === "charger_is_not_online") {
        this.setState({
          cponlinestatus: this.props.t('cp_not_online'),
          remoteStart: !this.state.remoteStart
        }, () => {
          this.closeCponlinestatus();
        })
      } else if (response !== undefined) {
        if (response[2] === "Request is Processed Successfully") {
          this.toggleRemoteStart();
          this.successTransaction();
        }
      }
    }
  }

  closeCponlinestatus() {
    this.setState({ cponlinestatusAlert: !this.state.cponlinestatusAlert })
  }

  triggerFirmwareMessage(msg) {
    this.setState({ firmwareAlert: !this.state.firmwareAlert, firmwareMessage: msg })
  }
  closeFirmwareMessage() {
    this.setState({ firmwareAlert: !this.state.firmwareAlert })
  }
  async checkUpdateFirmwareAvailable(cpId) {
    document.getElementById("loader_image_div").style.display = "block";
    let url = baseUrl.URLPath + servicesUrl.getChargePointsLatestfirmware + '?cpid=' + cpId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, true);

    if (response != null && response !== "" && response !== undefined) {
      if (response.message === 'latest_fwversion_in_charger') {
        this.triggerFirmwareAvailable();
        document.getElementById("loader_image_div").style.display = "none";
      } else if (response.message === "group_permission") {
        this.triggerNoPermission();
        document.getElementById("loader_image_div").style.display = "none";
      } else if (response.message === "fwversion_details_not_available") {
        this.triggerisFirmwareNotAvailable();
        document.getElementById("loader_image_div").style.display = "none";
      } else if (response.message === "charger_in_charging" || response.message === "cp_not_exist" || response.message === "cp_not_online" || response.message === "firmware_installing" || response.message === "charger_no_response") {
        this.triggerFirmwareMessage(response.message);
        document.getElementById("loader_image_div").style.display = "none";
      } else {
        // latest_fwversion_in_cloud
        this.setState({
          chargePointId: cpId,
          fwVersion: response.latestFWVersion,
          firmwareInstalledBuildsCount: response?.buildsCount,
          firmwareInstalledUpdateTime: response?.updateTime
        }, () => {
          this.triggerFirmwareNoNeed();
          document.getElementById("loader_image_div").style.display = "none";
        })
      }
    }
  }

  triggerFirmwareAvailable() {
    this.setState({
      isFirmwareUptodate: !this.state.isFirmwareUptodate
    })
  }

  triggerNoPermission() {
    this.setState({
      isChargerOwner: !this.state.isChargerOwner
    })
  }

  triggerFirmwareNoNeed() {
    this.setState({
      isFirmwareAvailable: !this.state.isFirmwareAvailable
    })
  }

  async updateFirmware(cpId) {
    this.triggerFirmwareNoNeed()
    let url = baseUrl.URLPath + servicesUrl.updateFirmware + "?cpid=" + cpId;

    let payload = {}

    let type = ApiMethodTypes.POST;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== null) {
      if (response.message === "charger_in_charging" || response.message === "cp_not_exist" || response.message === "cp_not_online" || response.message === "firmware_installing" || response.message === "charger_no_response") {
        this.triggerFirmwareMessage(response.message);
      } else if (response.message === 'firmware_update_initiated') {
        this.triggerFirmwareUpdate()
      }
    }
  }

  triggerFirmwareUpdate() {
    this.setState({
      isFirmwareInitiated: !this.state.isFirmwareInitiated
    })
  }

  triggerisFirmwareNotAvailable() {
    this.setState({
      isFirmwareNotAvailable: !this.state.isFirmwareNotAvailable
    })
  }

  toggleImagesLimitModal() {
    this.setState({
      isImagesLimitExceeded: !this.state.isImagesLimitExceeded
    })
  }

  toggleMileageNdInventory() {
    this.setState({
      mileageNdInventory: !this.state.mileageNdInventory,
    });
  }

  changeConfigValue(e) {
    let error = {};
    error['emptycpConfigNewValue'] = "";
    this.setState({
      configNewValue: e.target.value,
      errors: error
    });
  }

  togglekedit(configVal, configKey) {
    this.setState({
      modalkedit: !this.state.modalkedit,
      editConfigKey: configKey,
      editConfigKeyValue: configVal,
      configNewValue: ""
    });
  }

  // function to add chargingstation in fav list {}
  togglekey(ErrorCounter, favValue, e) {
    this.setState({
      modalkye: !this.state.modalkye,
      ErrorCounter: ErrorCounter,
      favValue: favValue
    });
  }

  togglekyeWar() {
    let error = {};
    if (this.state.configNewValue === "") {
      error['emptycpConfigNewValue'] = this.props.t('enter_new_value');
      this.setState({
        errors: error
      })
    } else {
      this.setState({
        modalkyeWar: !this.state.modalkyeWar,
      });
    }
  }

  Closekedit() {
    this.setState({
      modalkedit: !this.state.modalkedit,
    });
  }

  togglekyeSuc() {
    this.setState({
      modalkyeSuc: !this.state.modalkyeSuc,
    });
  }

  Closekey(ErrorCounter, favValue, e) {
    this.setState({
      modalkye: !this.state.modalkye,
      ErrorCounter: "",
      favValue: false,
    });
  }

  async addInFavList() {

    let url = baseUrl.URLPath + "chargepoints/favourite?model=" + this.state.cpmodel + "&key=" + this.state.ErrorCounter + "&isFav=" + this.state.favValue;

    let payload = {};
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response != null) {
      this.setState({
        modalkye: !this.state.modalkye,
      })
      this.getChargePointConfiguration();
    }
  }

  async updateConfigListValue() {
    this.setState({
      modalkyeWar: !this.state.modalkyeWar,
      modalkedit: !this.state.modalkedit
    })
    let url = baseUrl.URLPath + "chargepoints/updateKeyValue?model=" + this.state.model + "&key=" + this.state.editConfigKey + "&value=" + this.state.configNewValue + "&cpId=" + this.state.chargePointId;
    let payload = {};
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response != null) {
      this.setState({
        modalkyeSuc: !this.state.modalkyeSuc,
      })
    }
  }
  // for from date calender 
  handleFromChange = date => {
    $('.future_err1').text("");
    let fromD = '';
    if (date !== undefined && date !== "" && date != null) {
      fromD = dateformatinUTC(date)
    }
    this.setState({
      fromDate: date,
      fromDateApi: fromD,
      activePage: 1
    }, () => {
      this.getTransactions(this.state.pageSize, 1);
    });
  };

  // for to date calender 
  handleToChange = date => {
    let toD = '';
    if (date !== undefined && date !== "" && date != null) {
      toD = dateformatinUTC(date)
    }
    this.setState({
      toDate: date,
      toDateApi: toD,
      activePage: 1
    }, () => {
      this.getTransactions(this.state.pageSize, 1)
    });
  };

  //display warning popup for logbook 
  togglelogBook() {
    this.setState({
      logBookmodel: !this.state.logBookmodel
    });
  }

  handleOndragOver = event => {
    event.preventDefault();
  }

  handleOndrop = event => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files;
    this.handleFile(imageFile);
    $('.drop-it-wrap').addClass('highlight_border');
    setTimeout(() => {
      $('.drop-it-wrap').removeClass('highlight_border');
    }, 1000);
  }

  handleFile = file => {
    //you can carry out any file validations here...
    let existingImgCount = this.state.previewUrl.length;

    if (existingImgCount < 2) {
      this.setState({
        image: [...this.state.image, file[0]],
        previewUrl: [...this.state.previewUrl, URL.createObjectURL(file[0])]
      })
    }
  }

  async uploadImage() {
    let chargePointId = this.state.fields.chargePointId;

    var url = baseUrl.URLPath + servicesUrl.uploadCPImages + "?chargePointId=" + chargePointId;

    let formdate = new FormData();

    for (const file of this.state.image) {

      formdate.append("images", file);
    }

    let image0 = new Image();
    let image1 = new Image();
    var imgheight, imgwidth, imgheight1, imgwidth1;

    let im = this.state.previewUrl?.map((image, index) => {
      if (index === 0) {
        image0.src = image;
        // image0.onload = () => { 
        imgheight = image0.height;
        imgwidth = image0.width;
        // }
      } else if (index === 1) {
        image1.src = image;
        // image1.onload = () => { 
        imgheight1 = image1.height;
        imgwidth1 = image1.width;
        // }
      }
    })

    let img0DimValid = imgheight ? (imgwidth > 1920 || imgheight > 1080) : false;
    let img0SizeValid = imgheight ? this.state.image[0].size > 5000000 : false;
    let img1DimValid = imgheight1 ? (imgwidth1 > 1920 || imgheight1 > 1080) : false;
    let img1SizeValid = imgheight1 ? this.state.image[1].size > 5000000 : false;
    let img0TypeValid = false;
    let img1TypeValid = false;
    if (this.state.image[0]?.name) {
      img0TypeValid = (this.state.image[0]?.name.split('.')[1] == 'jpg' ||
        this.state.image[0]?.name.split('.')[1] == 'jpeg' ||
        this.state.image[0]?.name.split('.')[1] == 'png' ||
        this.state.image[0]?.name.split('.')[1] == 'svg') ? false : true;
    }
    if (this.state.image[1]?.name) {
      img1TypeValid = (this.state.image[1]?.name.split('.')[1] == 'jpg' ||
        this.state.image[1]?.name.split('.')[1] == 'jpeg' ||
        this.state.image[1]?.name.split('.')[1] == 'png' ||
        this.state.image[1]?.name.split('.')[1] == 'svg') ? false : true;
    }


    if (img0DimValid || img1DimValid) {
      this.toggleImageSizeError()
    } else if (img0SizeValid || img1SizeValid) {
      this.toggleImageMemoryError();
    } else if (img0TypeValid || img1TypeValid) {
      this.toggleImageTypeError();
    } else {
      if (this.state.image?.length > 0) {
        let type = ApiMethodTypes.POST;

        fetch(url, {
          method: type,
          body: formdate,
          headers: getAuthorization()
        }).then(resp => {
          if (resp.status == 401) {
            // this.togglesession();
            // localStorage.clear();
            window.location.reload();
          } else {
            return resp.json();
          }
        }).then((response) => {
          if (response.message === 'charge_point_add_images_success') {
            this.setState({
              uploadImageTitle: this.props.t('success'),
              uploadImageMessage: this.props.t(response.message),
              uploadModalSuccess: !this.state.uploadModalSuccess
            })
          } else {
            this.setState({
              uploadImageTitle: this.props.t('alert'),
              uploadImageMessage: this.props.t(response.message),
              uploadModalSuccess: !this.state.uploadModalSuccess
            })
          }
        });

      } else {
        this.toggleImgEmptyError();
      }
    }
  }

  async deletImage(imageId) {
    let chargePointId = this.state.fields.chargePointId;

    var url = baseUrl.URLPath + servicesUrl.deleteImages + "?chargePointId=" + chargePointId + "&imageId=" + imageId;

    let type = ApiMethodTypes.DELETE;
    let headers = getAuthorization();

    let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t);

    if (response) {
      if (response.status === 404) {
        this.setState({
          deleteImageMessage: this.props.t(response.message),
          deleteModalSuccess: !this.state.deleteModalSuccess,
          deleteModaltitle: this.props.t('alert')
        })
      } else {
        this.setState({
          deleteImageMessage: this.props.t(response.message),
          deleteModalSuccess: !this.state.deleteModalSuccess,
          deleteModaltitle: this.props.t('success')
        })
      }
    }
  }

  async getImages() {
    let chargePointId = this.state.fields.chargePointId;

    var url = baseUrl.URLPath + servicesUrl.getCPImages + "?chargePointId=" + chargePointId;

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();

    let response = await APICallUtility.cpmsAPIFetch(url, type, {}, headers, this.props.t);

    if (response) {
      if (response.status === 404) {
        this.setState({
          getImagesList: []
        })
      } else {
        this.setState({
          getImagesList: response
        })
      }
    }
  }

  handleDragLeave() {
    if (!this.state.inDropZone)
      return;
    this.setState({ inDropZone: false });
  }

  handleFilesFromInput(e) {
    $('.drop-it-wrap').addClass('highlight_border');

    let existingImgCount = this.state.previewUrl.length;
    let files = this.state.image ?? [];
    let previews = this.state.previewUrl ?? [];
    let currentCount = e.currentTarget.files?.length;

    if ((existingImgCount === 0 && currentCount === 2) || (existingImgCount === 1 && currentCount === 1) || (existingImgCount === 0 && currentCount === 1)) {
      Array.from(e.currentTarget.files).forEach(file => {
        files.push(file);
        previews.push(URL.createObjectURL(file))
      });

      this.setState({
        image: files,
        previewUrl: previews
      });

      setTimeout(() => {
        $('.drop-it-wrap').removeClass('highlight_border');
      }, 1000);
    } else if ((existingImgCount === 0 && currentCount > 2) || (existingImgCount === 1 && currentCount > 1)) {
      this.toggleImagesLimit()
      setTimeout(() => {
        $('.drop-it-wrap').removeClass('highlight_border');
      }, 1000);
    }
  }

  removeImage(removeIndex) {
    let images = this.state.image;
    let previewImages = this.state.previewUrl;

    images.splice(removeIndex, 1);
    previewImages.splice(removeIndex, 1);

    this.setState({
      ...this.state,
      image: images,
      previewUrl: previewImages
    })
  }

  //display warning popup for set charging limit 
  toggleASetChargingLimit() {
    this.setState({
      ASetChargingLimit: !this.state.ASetChargingLimit
    });
  }

  async downloadSafeEVFile() {
    var url = baseUrl.URLPath + servicesUrl.downloadSafeEvFiles + "?transactionId=" + this.state.transactionId;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeadersWithTimezone();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t, false, true);

    if (response !== undefined) {
      let url = window.URL.createObjectURL(response);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Chargingstation_' + this.state.transData.chargePointId + "_Sessionid_" + this.state.transactionId + '_SAFE_FILE.xml';
      a.click();
    }
  }


  render() {
    const { t } = this.props
    let editAction = false;
    let carAction = false;
    let location = false;
    let actionDisplay = false;

    let classes = classNames(`drop-it-wrap batch ${this.props.className || ''}`);

    const dropEvents = {
      onDrop: this.handleOndrop,
      onDragOver: this.handleOndragOver,
      onDragLeave: this.handleDragLeave,
      onClick: () => this.fileInput.current.click()
    };

    const fileInputAttrs = {
      ref: this.fileInput,
      type: 'file',
      onChange: this.handleFilesFromInput.bind(this),
      style: { position: 'absolute', left: -99999999 },
      accept: 'image/*'
    };

    this.state.getOperationsList.map((Operation, index) => {
      if (Operation.operation == "Charging Session" && Operation.active == true) {
        Operation.subOperations.map((oper, i) => {
          if (oper.name == "Remote Start Transaction" && oper.access == true) {
            editAction = true;
          }
          if (oper.name == "Logbook" && oper.access == true) {
            carAction = true;
          }
        })
      }

      if (Operation.operation == "Reservation & Second Seating" && Operation.active == true && this.props.Reservation_R && (this.state.reservationSeatingEnabled || JSON.parse(localStorage.getItem("userPackage")) === null)) {

        location = true;
      }

    })

    var startMeterDate = '';
    if (this.state.startDate1 != null || this.state.startDate1 != "") {
      startMeterDate = moment(this.state.startDate1).format("DD MMM YYYY");
    }
    if (this.state.startDate1 == "") {
      startMeterDate = "";
    }
    var endMeterDate = '';
    if (this.state.endDate1 != null || this.state.endDate1 != "") {
      endMeterDate = moment(this.state.endDate1).format("DD MMM YYYY");
    }
    if (this.state.endDate1 == "") {
      endMeterDate = "";
    }

    var startBusinessDate = '';
    if (this.state.businessstartDate != null || this.state.businessstartDate != "") {
      startBusinessDate = moment(this.state.businessstartDate).format("DD MMM YYYY");
    }
    if (this.state.businessstartDate == "") {
      startBusinessDate = "";
    }
    var endBusinessDate = '';
    if (this.state.businessendDate != null || this.state.businessendDate != "") {
      endBusinessDate = moment(this.state.businessendDate).format("DD MMM YYYY");
    }
    if (this.state.businessendDate == "") {
      endBusinessDate = "";
    }

    var startTime = '';
    var startTime1 = '';
    if (this.state.transData.startTime != null) {
      startTime = moment(this.state.transData.startTime).format("DD-MMM-YYYY").toUpperCase();
      startTime1 = moment(this.state.transData.startTime).format("HH:mm").toUpperCase();
    }
    var stopTime = '';
    var stopTime1 = '';
    if (this.state.transData.stopTime != null) {
      stopTime = moment(this.state.transData.stopTime).format("DD-MMM-YYYY").toUpperCase();
      stopTime1 = moment(this.state.transData.stopTime).format("HH:mm").toUpperCase();
    }
    var chargingGoalReachedTime = '';
    if (this.state.transData.chargingGoalReachedTime != null) {
      chargingGoalReachedTime = moment(this.state.transData.chargingGoalReachedTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    }


    localStorage.setItem("saveDetails", true);

    const { pageNumber, numPages } = this.state;

    let editResUser = "";
    if (this.state.rEditAccess == true && localStorage.getItem("role") == "WEBASTO_END_USER_RESIDENTIAL") {
      editResUser = "OwnCharger"
    }
    if (this.state.rEditAccess == false && localStorage.getItem("role") == "WEBASTO_END_USER_RESIDENTIAL") {
      editResUser = "SharedCharger"
    }

    // condition for enableCSOperation
    let csOperation = localStorage.getItem("csOperation")
    if (this.state.enableCSOperation == csOperation) {
      this.state.operationdisplay = "block";
    }
    else {
      this.state.operationdisplay = "none";
    }

    var start_time = '';
    var start_time1 = '';
    if (this.state.transData.startTime != null) {
      start_time = moment(this.state.transData.startTime).format("DD-MMM-YYYY").toUpperCase();
      start_time1 = moment(this.state.transData.startTime).format("HH:mm").toUpperCase();
      $('#start-time').show();
      $('#start-time1').show();
    } else {
      $('#start-time').hide();
      $('#start-time1').hide();
    }
    var stop_time = '';
    var stop_time1 = '';
    if (this.state.transData.stopTime != null) {
      $('#stop-time').show();
      $('#stop-time1').show();
      stop_time = moment(this.state.transData.stopTime).format("DD-MMM-YYYY").toUpperCase();
      stop_time1 = moment(this.state.transData.stopTime).format("HH:mm").toUpperCase();
    } else {
      $('#stop-time').hide();
      $('#stop-time1').hide();

    }
    var chargingGoalReachedTime = '';
    if (this.state.transData.chargingGoalReachedTime != null) {
      chargingGoalReachedTime = moment(this.state.transData.chargingGoalReachedTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    }
    let user = this.state.user;
    let operationPermissions = this.state.opertaion_permissions;

    var articleContent = "";
    if (this.state.description != null) {
      articleContent = this.state.description
    }

    if (this.state.fields.active == "0" || this.state.fields.active == "Inactive") {
      this.state.fields.active = "Inactive";
    } else {
      if (this.state.fields.active != "") {
        this.state.fields.active = "Active";
      }
    }


    var status_img = "";
    var status_text = "";
    if (this.state.transData.chargingSessionState == "activeCharging") {
      status_img = "activeCharging";
      status_text = t('active_charging');
    }
    if (this.state.transData.chargingSessionState == "Finished") {
      status_img = "Finished";
      status_text = t('finished');
    }
    if (this.state.transData.chargingSessionState == "activeNotCharging") {
      status_img = "activeNotCharging";
      status_text = t('active_not_charging');
    }
    if (this.state.transData.chargingSessionState == "prepairing") {
      status_img = "prepairing";
      status_text = t('prepairing');
    }
    if (this.state.transData.chargingSessionState == "activePreparing") {
      status_img = "prepairing";
      status_text = t('active_preparing');
    }
    if (this.state.transData.chargingSessionState == "SuspendedEV") {
      status_img = "activeNotCharging";
      status_text = t('active_not_charging');
    }
    if (this.state.transData.chargingSessionState == "Halted") {
      status_img = "Halted";
      status_text = t('halted');
    }

    let title = this.state.getFieldsList.map((tableTilte, index) => {
      if (tableTilte.isSelected) {
        var icon = "";
        if (tableTilte.key == "id" || tableTilte.key == "start_time" || tableTilte.key == "stop_time") {
          icon = <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i>
        }

        if (tableTilte.key == "actions") {
          actionDisplay = true;
        }

        if (tableTilte.key == "referenceNumber" || tableTilte.key == "customerNumber") {
          if (editAction == true) {
            return (
              <th key={tableTilte.key}>
                {t(tableTilte.key)}
                {icon}
              </th>
            )
          }
        } else {
          return (
            <th key={tableTilte.key} >
              {t(tableTilte.key)}
              {icon}
            </th>
          )
        }
      }
    });

    var page_title1 = "";
    if (this.props.location.state == null) {
      var data1 = JSON.parse(localStorage.getItem("cpview"));
      page_title1 = data1.chargePointId;
    } else {
      page_title1 = this.props.location.state.chargePointId;
    }
    const data = this.state.metervalues.map((dataArray, index) => {
      ;
      // var valueTime = moment(dataArray.valueTime).format("DD-MMM-YYYY HH:mm").toUpperCase();

      //alert(JSON.stringify(dataArray));

      if (dataArray.valueTime == null || dataArray.valueTime == "") {
        var valueTime = "";
        var valueTime1 = "";

      } else {
        var valueTime = moment(dataArray.valueTime).format("DD-MMM-YYYY").toUpperCase();
        var valueTime1 = moment(dataArray.valueTime).format("HH:mm").toUpperCase();
      }

      return (
        <tr>
          <td>{dataArray.value}</td>
          <td>{valueTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {valueTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {valueTime1} </div></>} </td>
          {/* <td>{dataArray.chargingPower}</td> */}
          <td className="wrap-word"><div className="nowrap pull-left pb-0 w-100">{dataArray.chargingPower}<b> kWh</b></div><div className="timespan pull-left pb-0">{dataArray.chargingPower * 1000} wh</div></td>

          <td>{dataArray.unit}</td>

        </tr>
      )

    })
    const transactions = this.state.transactions.map((transaction, index) => {
      var startTime, startTime1, stopTime, stopTime1;
      var status_img = "";
      var status_text = "";

      if (transaction.startTime == null || transaction.startTime == "") {
        startTime = "";
        startTime1 = "";
      } else {
        startTime = moment(transaction.startTime).format("DD MMM YYYY");
        startTime1 = moment(transaction.startTime).format("HH:mm");
      }

      if (transaction.stopTime == null || transaction.stopTime == "") {
        stopTime = "";
        stopTime1 = "";
      } else {
        stopTime = moment(transaction.stopTime).format("DD MMM YYYY");
        stopTime1 = moment(transaction.stopTime).format("HH:mm");
      }

      var status_img = "";
      var status_text = "";
      if (transaction.chargingSessionState == "activeCharging") {
        status_img = "activeCharging";
        status_text = t('active_charging');
      } else if (transaction.chargingSessionState == "Finished") {
        status_img = "Finished";
        status_text = t('finished');
      } else if (transaction.chargingSessionState == "activeNotCharging") {
        status_img = "activeNotCharging";
        status_text = t('active_not_charging');
      } else if (transaction.chargingSessionState == "prepairing") {
        status_img = "prepairing";
        status_text = t('prepairing');
      } else if (transaction.chargingSessionState == "activePreparing") {
        status_img = "prepairing";
        status_text = t('active_preparing');
      } else if (transaction.chargingSessionState == "SuspendedEV") {
        status_img = "activeNotCharging";
        status_text = t('active_not_charging');
      } else if (transaction.chargingSessionState == "Halted") {
        status_img = "Halted";
        status_text = t('halted');
      }

      var chargedEnergy = '';

      if (transaction.chargedEnergy == null || transaction.chargedEnergy == "") {
        chargedEnergy = 0;
      } else {
        chargedEnergy = transaction.chargedEnergy;
      }

      if (transaction.transactionId === transaction.vendorTransactionId) {
        return (
          <tr>
            {
              this.state.getFieldsList.map((tableTilte, index) => {
                if (tableTilte.isSelected == true) {
                  return Object.entries(transaction).map(([key, value]) => {
                    if (tableTilte.key == key) {
                      if (key == "transactionId") {
                        return (
                          <td className="no-wrap">
                            <span className="underline btn_cursor" onClick={this.viewMeterValues.bind(this, transaction)}>
                              {transaction.transactionId}
                            </span>
                            {transaction.vendorTransactionId ? '(' + transaction.vendorTransactionId + ')' : ''}
                          </td>
                        );
                      } else if (key == "startTime") {
                        return (
                          <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
                        )
                      } else if (key == "stopTime") {
                        return (
                          <td className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>}  </td>
                        )
                      } else if (key == "chargedEnergy") {
                        return (
                          <td className="wrap-word"><div className="nowrap pull-left pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: transaction?.currencyCode, symbol: '' })}<b> kWh</b></div><div className="timespan pull-left pb-0">{(chargedEnergy * 1000)?.toFixed()} wh</div></td>
                        )
                      } else if (key == "chargingSessionState") {
                        return (
                          <td className="wrap-word p-rel">
                            <div class="click-to-top">
                              <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                              <span>{status_text}</span>
                            </div>
                          </td>
                        )
                      } else if (key == "customerNumber" || key == "referenceNumber") {
                        if (editAction == true) {
                          return (
                            <td>{value}</td>
                          )
                        }
                      } else if (key == "chargePointId") {
                        return (
                          <td>{value} {transaction.connectorId ? '(' + transaction.connectorId + ')' : ''}</td>
                        )
                      } else if (key == "chargingSessionCost" || key == "costsPerKwh" || key == "costsPerChargingSession") {
                        return (
                          <td>{currencyFormatter.format(value, { code: transaction?.currencyCode, symbol: '' })}</td>
                        )
                      } else {
                        return (
                          <td>{value}</td>
                        );
                      }
                    }
                  })
                }
              })
            }
            {actionDisplay && <>
              {localStorage.getItem("role") !== "WEBASTO_CUSTOMER_ADMIN_READ_ONLY" ? <>
                <td>
                  {
                    (editAction == true) ? <>
                      {(transaction.chargingSessionState == "prepairing" || transaction.chargingSessionState == "activePreparing" || transaction.chargingSessionState == "activeCharging") ?
                        (transaction.energyLimitPermission) ? <i class="fas fa-pencil-alt pr-2 action-icon" title={t('remote_start_transaction')} onClick={this.toggleRemoteStart.bind(this, transaction.chargePointId, transaction.transactionId, transaction.energyLevel, transaction.idTag, transaction.note)}></i> : <i class="fas fa-pencil-alt pr-2 action-icon icon_fadeOut" title={t('remote_start_transaction')}></i>
                        : ""}
                    </> : <></>
                  }
                  {
                    (carAction == true) ? <i class="fas fa-car-side" title={t('add_mileage_and_inventory_no')} onClick={() => {
                      this.setState({ transId: transaction.transactionId, mileage: transaction.mileage, inventoryNumber: transaction.vehicleInventoryNumber });
                      this.toggleMileageNdInventory();
                    }}></i> : <></>
                  }
                </td>
              </> : <td></td>
              }
            </>
            }
            <td></td>

          </tr>
        )
      } else {
        return (
          <tr>
            {
              this.state.getFieldsList.map((tableTilte, index) => {
                if (tableTilte.isSelected == true) {
                  return Object.entries(transaction).map(([key, value]) => {
                    if (tableTilte.key == key) {
                      if (key == "transactionId") {
                        return (
                          <td className="no-wrap">
                            <span className="underline btn_cursor" onClick={this.viewMeterValues.bind(this, transaction)}>
                              {transaction.transactionId}
                            </span>
                            {transaction.vendorTransactionId ? '(' + transaction.vendorTransactionId + ')' : ''}
                          </td>
                        );
                      } else if (key == "startTime") {
                        return (
                          <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
                        )
                      } else if (key == "stopTime") {
                        return (
                          <td className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>}  </td>
                        )
                      } else if (key == "chargedEnergy") {
                        return (
                          <td className="wrap-word"><div className="nowrap pull-left pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: transaction?.currencyCode, symbol: '' })}<b> kWh</b></div><div className="timespan pull-left pb-0">{(transaction.chargedEnergy * 1000)?.toFixed()} wh</div></td>
                        )
                      } else if (key == "chargingSessionState") {
                        return (
                          <td className="wrap-word  p-rel ">
                            <div class="click-to-top">
                              <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                              <span>{status_text}</span>
                            </div>
                          </td>
                        )
                      } else if (key == "customerNumber" || key == "referenceNumber") {
                        if (editAction == true) {
                          return (
                            <td>{value}</td>
                          )
                        }
                      } else if (key == "chargePointId") {
                        return (
                          <td>{value} {transaction.connectorId ? '(' + transaction.connectorId + ')' : ''}</td>
                        )
                      } else if (key == "chargingSessionCost" || key == "costsPerKwh" || key == "costsPerChargingSession") {
                        return (
                          <td>{currencyFormatter.format(value, { code: transaction?.currencyCode, symbol: '' })}</td>
                        )
                      } else {
                        return (
                          <td>{value}</td>
                        );
                      }
                    }
                  })
                }
              })
            }
            {actionDisplay && <>
              {localStorage.getItem("role") !== "WEBASTO_CUSTOMER_ADMIN_READ_ONLY" ? <>
                <td>
                  {
                    (editAction == true) ? <>
                      {(transaction.chargingSessionState == "prepairing" || transaction.chargingSessionState == "activePreparing" || transaction.chargingSessionState == "activeCharging") ?
                        (transaction.energyLimitPermission) ? <i class="fas fa-pencil-alt pr-2 action-icon" title={t('remote_start_transaction')} onClick={this.toggleRemoteStart.bind(this, transaction.chargePointId, transaction.transactionId, transaction.energyLevel, transaction.idTag, transaction.note)}></i> : <i class="fas fa-pencil-alt pr-2 action-icon icon_fadeOut" title={t('remote_start_transaction')}></i>
                        : ""}
                    </> : <></>
                  }
                  {
                    (carAction == true) ? <i class="fas fa-car-side" title={t('add_mileage_and_inventory_no')} onClick={() => {
                      this.setState({ transId: transaction.transactionId, mileage: transaction.mileage, inventoryNumber: transaction.vehicleInventoryNumber });
                      this.toggleMileageNdInventory();
                    }}></i> : <></>
                  }
                </td>
              </> : <td></td>
              }
            </>
            }
            <td></td>
          </tr>
        )
      }
    });

    const tagList = this.state.rfidTagsList?.map((tag, index) => {
      return (
        <> {(tag.idTag.trim() != 0 || tag.idTag.trim() != "") ? <option value={tag.idTag}>{tag.idTag}</option> : null} </>
      )
    });

    const errorsData = this.state.businessError.map((businessError, index) => {
      ;

      if (businessError.timeStamp == null || businessError.timeStamp == "") {
        var timeStamp = "";
        var timeStamp1 = "";
      } else {
        var timeStamp = moment(businessError.timeStamp).format("DD-MMM-YYYY").toUpperCase();
        var timeStamp1 = moment(businessError.timeStamp).format("HH:mm").toUpperCase();
      }

      return (
        <tr>
          <td>{timeStamp && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {timeStamp}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {timeStamp1} </div></>} </td>
          <td>{businessError.internalErrorCode}</td>
          <td>{businessError.desc}</td>
        </tr>
      )

    })

    const connectorStatus = this.state.connectorStatus.map((connectorStatus, index) => {
      var stringDate = connectorStatus.updatedTime;
      stringDate = moment(connectorStatus.updatedTime).local().format('YYYY-MM-DD HH:mm:ss');

      if (connectorStatus.updatedTime == null || connectorStatus.updatedTime == "") {
        var createdTime = "";
        var createdTime1 = "";
      } else {
        var createdTime = moment(stringDate).format("DD MMM YYYY");
        var createdTime1 = moment(stringDate).format("HH:mm");
      }

      // logic for getting chargingProfiles by comma saparated
      var dict = connectorStatus.chargingProfiles;

      const commaSep = dict.map((item, index) => {
        return (
          <Link to={{
            pathname: '/viewChargingProfile',
            state: item.id,
          }}>{item.profileName + ",  "}
          </Link>
        )
      })
      return (
        <tr>
          <td>{connectorStatus.connectorId}</td>
          <td>{connectorStatus.status}</td>
          <td>{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>}</td>
          <td>{commaSep}</td>
        </tr>
      )
    });

    const keys = this.state.configKeys.map((configKeys, index) => {
      if (configKeys.updatedDate == null || configKeys.updatedDate == "") {
        var updatedDate = "";
        var updatedTime = "";
      } else {
        var updatedDate = moment(configKeys.updatedDate).format("DD MMM YYYY");
        var updatedTime = moment(configKeys.updatedDate).format("HH:mm");
      }
      return (
        <tr>
          <td className="cbreakword w-20">{configKeys.key}</td>
          <td className="cbreakword w-56">{configKeys.value}</td>
          <td>{updatedDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {updatedDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {updatedTime} </div> </>}</td>
          {hasPermission(user, ["ChargerConfiguration_R"]) && <>
            <td>
              <i className="far fa-star fa-sm pr-2 action-icon" onClick={this.togglekey.bind(this, configKeys.key, true)} ></i>
              {(configKeys.readonly == false && this.state.onlineStatus && localStorage.getItem("role") != "") && <i className="fas fa-pencil-alt pr-2 action-icon"
                onClick={this.togglekedit.bind(this, configKeys.value, configKeys.key)}
              ></i>}
            </td>
          </>
          }
        </tr>
      )
    });
    const favkeys = this.state.configFavKeys.map((configKeys, index) => {
      if (configKeys.updatedDate == null || configKeys.updatedDate == "") {
        var updatedDate = "";
        var updatedTime = "";
      } else {
        var updatedDate = moment(configKeys.updatedDate).format("DD MMM YYYY");
        var updatedTime = moment(configKeys.updatedDate).format("HH:mm");
      }
      return (
        <tr>
          <td className="cbreakword w-20">{configKeys.key}</td>
          <td className="cbreakword w-56">{configKeys.value}</td>
          <td>{updatedDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {updatedDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {updatedTime} </div> </>}</td>
          {hasPermission(user, ["ChargerConfiguration_R"]) && <>
            <td>
              <i className="fas fa-star fa-sm pr-2 action-icon" onClick={this.togglekey.bind(this, configKeys.key, false)} ></i>
              {(configKeys.readonly == false && this.state.onlineStatus && localStorage.getItem("role") != "") && <i className="fas fa-pencil-alt pr-2 action-icon"
                onClick={this.togglekedit.bind(this, configKeys.value, configKeys.key)}
              ></i>}
            </td>
          </>}

        </tr>
      )
    });

    let wallboxEnable = false;
    if ((localStorage.getItem('role') == "WEBASTO_END_USER_COMMERCIAL" && (this.state.fields.isSiteAdminAccess == true || this.state.fields.isChargerOwner == true)) || localStorage.getItem('role') == "WEBASTO_SUPER_ADMIN_DEVOPS" || localStorage.getItem('role') == "WEBASTO_CUSTOMER_ADMIN" || localStorage.getItem('role') == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" || localStorage.getItem('role') == "WEBASTO_SITE_ADMIN" || localStorage.getItem('role') == "WEBASTO_END_USER_RESIDENTIAL") {
      wallboxEnable = true;
    }

    let logbookActive = false;
    this.state.getOperationsList.map((Operation, index) => {
      if (Operation.operation == "Charging Session" && Operation.active == true) {
        Operation.subOperations.map((oper, i) => {
          if (oper.name == "Logbook" && oper.access == true) {
            logbookActive = true;
          }
        })
      }
    });

    let access = true;
    if ((localStorage.getItem('role') == roles.WEBASTO_END_USER_RESIDENTIAL || localStorage.getItem('role') == roles.WEBASTO_END_USER_COMMERCIAL) && (this.state.fields.isSiteAdminAccess != true && this.state.fields.isChargerOwner != true)) {
      access = false;
    }

    let firmware_message = this.state.firmwareInstalledBuildsCount + " " + t('firmware_notification_1') + " ~" + this.state.firmwareInstalledUpdateTime + " " + t('firmware_notification_2')

    let firmware_status = <div className="row">
      <div className="col-md-12">
        <div className="content-innerdiv">
          <div>
            <label>{t('updating_to')}:</label>
            <label>{this.state.firmwareCurrentVersion + " (" + this.state.firmwareUpdatedCount + " " + t('out_of') + " " + this.state.firmwareTotalCount + ")"}</label>
          </div>
          <div>
            <label>{t('status')}:</label>
            <label>{this.state.firmwareStatus}</label>
          </div>
        </div>
      </div>
    </div>

    const cloudUsers = this.state.dataArray2?.map((dataArray2, index) => {
      return (
        <tr key={index}>
          <td>{dataArray2.email}</td>
          <td>{dataArray2.firstName}</td>
          <td>{dataArray2.lastName}</td>
          <>
            {(dataArray2.status == true) ? <td>Active</td> : <td>Inactive</td>}
          </>
          <td> <Link to={{ pathname: '/cloudUserHistory' }} onClick={() => {
            localStorage.setItem("selectedUserId", dataArray2.id);
            localStorage.setItem("isCloudUsersPage", true);
            localStorage.setItem("selectedUserEmail", dataArray2.email);
            localStorage.setItem("isFromWallboxList", true);
            localStorage.setItem("lastTabName", "CloudUsers");
          }}>View</Link></td>
        </tr>
      )
    })

    const localUsers = this.state.localUsers?.map((user, index) => {
      var getWallBoxData = {
        wallBoxNo: this.state.chargePointId,
        user
      }
      return (
        <tr>
          <td>{user.email}</td>
          <td>{user.password}</td>
          <td>{user.userType}</td>
          <td><Link to={{
            pathname: '/userHistory',
            state: getWallBoxData
          }} onClick={() => {
            localStorage.setItem("lastTabName", "Users");
          }}>View</Link></td>
          {
            ((this.state.fields.isChargerOwner === true && localStorage.getItem('role') === "WEBASTO_END_USER_RESIDENTIAL")) || localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS ? (
              <td style={{ textAlign: "center" }}>
                <i className="far fa-trash-alt" title="Delete" style={{ cursor: 'pointer' }} onClick={this.toggleConfirmDeleteUser.bind(this, user.email)}></i>
                {/* <i className="fas fa-pencil-alt action-icon edit_usericon" title="Update Password" style={{ cursor: 'pointer' }} onClick={this.toggleUpdatePasswordLocalUser.bind(this, user.email, user.password, user.chargePointId)}></i> */}
              </td>
            ) : null
          }
        </tr>
      );
    });


    return (
      <>
        <main class="content-div">
          <p>
            {" "}
            {page_title1} ({this.state.fields.active})
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/chargePoint">{t('Overview')}</Link> &gt;{" "}
              <span className="breadcrumb_page">{t('view')}</span>
            </div>
          </p>

          <div className="page-outerdiv">
            <Alert color={this.state.colour1} isOpen={this.state.sucess1}>{this.state.getConfigMessage}</Alert>
            <form
              className="needs-validation"
              onSubmit={this.toggle1}
              noValidate
            >
              <MDBNav className=" mt-1 p-rel border-0">
                <div>
                  <button type="button" data-test="button" className="btn_primary" onClick={this.backToList}>
                    <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>{t('back')}
                  </button>
                </div>
                <MDBNavItem className="ml-5 nav-first ">
                  <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "1" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "1")} role="tab">
                    {t('Overview')}
                  </MDBNavLink>
                </MDBNavItem>
                {localStorage.getItem("role") != "WEBASTO_SUPERADMIN_FIRST_LEVEL" ?
                  <>
                    <MDBNavItem className="">
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "2" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "2")} role="tab">
                        {t('charging_sessions')}
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem className="">
                      {hasPermission(user, ["ConnectorStatus_R"]) && (
                        <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "3" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "3")} role="tab">
                          {t('Connectors')}
                        </MDBNavLink>
                      )}
                    </MDBNavItem>
                    <MDBNavItem className="">
                      {hasPermission(user, ["Installation_R"]) && access && (
                        <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "4" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "4")} role="tab">
                          {t('installation')}
                        </MDBNavLink>
                      )}
                    </MDBNavItem>
                    <MDBNavItem className="">
                      {hasPermission(user, ["showDiagnostics_R"]) && access && (
                        <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "5" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "5")} role="tab">
                          {t('diagnostics')}
                        </MDBNavLink>
                      )}
                    </MDBNavItem>
                    <MDBNavItem className={`${(location) ? !hasPermission(user, ["Pictures_R"]) ? "nav-last" : "" : "nav-last"}`}>
                      {hasPermission(user, ["GetConfiguration_R"]) && access && (
                        <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "6" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "6")} role="tab">
                          {t('configuration')}
                        </MDBNavLink>
                      )}
                    </MDBNavItem>
                    <MDBNavItem className="nav-last">
                      {hasPermission(user, ["Pictures_R"]) && location && (
                        <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "7" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "7")} role="tab">
                          {t('pictures')}
                        </MDBNavLink>
                      )}
                    </MDBNavItem>
                  </>
                  : null}

                {/* -- Split button -->  */}
                {hasPermission(user, ["Operation_R"]) && <>
                  <div class="operation-right">
                    <button
                      type="button"
                      data-test="button"
                      className="btn_primary"
                      style={{ display: this.state.operationdisplay }}
                      data-toggle="modal"
                      data-target="#operationsModal"
                      onClick={() => {
                        this.state.fields["onChangeOperationValue"] = "";
                        this.state.errors["enterOper"] = "";
                        this.setState({
                          tempOperation: this.state.getOperationList
                        });
                      }}
                    >
                      {t('Operations')}
                      <span>
                        <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Settings.png"} className="ml-2" style={{ width: "14px" }} alt="" />
                      </span>
                    </button>

                    <div
                      class="modal"
                      id="operationsModal"
                      ref={(node) => { if (node) { node.style.setProperty("z-index", "100001", "important"); } }}
                    >
                      {/* Operations Modal */}
                      <div class="modal-dialog t-50 cp-operations_model">
                        <div class="modal-content b-15">
                          {/* <!-- Modal Header --> */}
                          <div class="modal-header row mx-0">
                            <h4 class="modal-title fw-800 mt-1 col-md-6 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>
                              {t('Operations')}
                            </h4>
                            <div className="col-md-5 px-0 mt-1">
                              <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input
                                  type="text"
                                  id="onChangeOperationValue"
                                  value={this.state.fields["onChangeOperationValue"]}
                                  onChange={this.changeOperations.bind(this, "onChangeOperationValue")}
                                  class="form-control input-searchBox pad-2"
                                  placeholder={t('search_for_operation')}
                                  name="srch-term"
                                  ref={(node) => { if (node) { node.style.setProperty("font-size", "12px", "important"); } }}
                                />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.clearOperation}>
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeOperationsFilter}>{t('find')}</button>
                                </div>
                                <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["enterOper"]}</span></span>
                              </div>
                            </div>
                            <button type="button" data-test="button" className="Op-modal-close font-weight-bold col-md-1 tn-4 f-24" data-dismiss="modal" style={{ left: '7px' }}>
                              &times;
                            </button>
                          </div>

                          {/* <!-- Modal body --> */}
                          <div class="modal-body">
                            <div className="row">
                              {this.state.isOperationsActive && this.state.tempOperation.map(
                                (operation, index) => {
                                  let operationData = {
                                    chargePoint: "chargePointId",
                                    operationId: this.state.chargePointId
                                  }

                                  let testOperations = true;
                                  if (access) {
                                    testOperations = true;
                                  } else {
                                    if (hasPermission(operationPermissions, [`${operation.permissions}`])) {
                                      testOperations = false;
                                    }
                                  }

                                  if (operation.name == 'Logs' || operation.name == 'CPMS Logs') {
                                    return <></>
                                  } else {
                                    return (
                                      <>
                                        {this.state.opertaion_permissions.permissions.length === 0 ? (
                                            <div className="col-md-6">
                                              <div className="cp-operations mb-2">
                                                <Link to={{ pathname: `/${operation.link}`, state: operationData }} onClick={() => { $(".modal-backdrop").remove(); }}>
                                                  {t(operation.name)}{" "}
                                                  <span className="float-right">
                                                    <i className="fas fa-angle-right"></i>
                                                  </span>
                                                </Link>
                                              </div>
                                            </div>
                                          ) : (
                                            this.state.opertaion_permissions.permissions.includes(operation.permissions[0]) && (
                                              <div className="col-md-6">
                                                <div className="cp-operations mb-2">
                                                  <Link to={{ pathname: `/${operation.link}`, state: operationData }} onClick={() => { $(".modal-backdrop").remove(); }}>
                                                    {t(operation.name)}{" "}
                                                    <span className="float-right">
                                                      <i className="fas fa-angle-right"></i>
                                                    </span>
                                                  </Link>
                                                </div>
                                              </div>
                                            )
                                          )}  
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>

                          {/* <!-- Modal footer --> */}
                          <div class="modal-footer border border-0">
                            <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" data-dismiss="modal">
                              {t('close')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                }
              </MDBNav>
              
              <MDBNav className=" mt-1 p-rel border-0">
              <div style={{marginLeft: "4.5rem"}}>
                  
              </div>
              {localStorage.getItem("role") != "WEBASTO_SUPERADMIN_FIRST_LEVEL" ?
              <>
               {
                (this.state.isModelDX) && (
                  <>
                  <MDBNavItem className="ml-5 nav-first ">
                  {
                    (localStorage.getItem('role') == roles.WEBASTO_END_USER_RESIDENTIAL || localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS) && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "8" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "8")} role="tab">
                        Local Users
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                <MDBNavItem className="nav">
                  {
                    localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "9" ? "activetab" : ""}`} onClick={this.tabToggle.bind(this, "9")} role="tab">
                        Cloud Users
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                <MDBNavItem className="nav">
                  {
                    (this.state.fields.isChargerOwner === true && localStorage.getItem('role') === "WEBASTO_END_USER_RESIDENTIAL") && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "10" ? "activetab" : ""}`} onClick={this.syncLocalUsers.bind(this, "9")} role="tab">
                        Sync Local Users
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                <MDBNavItem className="nav">
                  {
                    localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "10" ? "activetab" : ""}`} onClick={this.syncChargerStats.bind(this, "10")} role="tab">
                        Sync Charger Statistics
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                <MDBNavItem className="nav">
                  {
                    localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "10" ? "activetab" : ""}`} onClick={this.syncFreeChargeStats.bind(this, "11")} role="tab">
                        Sync Free Charge Statistics
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                <MDBNavItem className="nav-last">
                  {
                    (this.state.fields.isChargerOwner === true && localStorage.getItem('role') === "WEBASTO_END_USER_RESIDENTIAL") && (
                      <MDBNavLink to="#" className={`b-75 ${this.state.activeItem == "11" ? "activetab" : ""}`} onClick={this.configureMQTT.bind(this, "11")} role="tab">
                        Configure MQTT
                      </MDBNavLink>
                    )
                  }
                </MDBNavItem>
                </>
                )
              }
              </>
            : null}
              </MDBNav>
              <MDBTabContent activeItem={this.state.activeItem} className="pt-2 p-rel border-0 p-0">
                {/* details */}
                <MDBTabPane tabId="1" role="tabpanel">
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="content-innerdiv">
                        <div>
                          <label>{t('Organization')}:</label>
                          <label>{this.state.fields.chargePointVendor}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {
                      // (logbookActive == true && access && hasPermission(user, ["Logbook_R"])) ? (                      
                      (logbookActive == true && this.state.fields.logBookAccess) ? (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="content-innerdiv">
                              <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                                <b style={{ fontSize: "14px" }}>
                                  {t('logbook')}{" "}
                                  <span className="custom_tooltip">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div className="custom_tooltiptext">
                                      <div className="custom_tooltip_header">{t('logbook')}</div>
                                      <div className="custom_tooltip_body">
                                        <div>
                                          {t('who want to start a chargingsession within the Webasto ChargeConnect')}
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </b>
                              </label>
                              <Form.Check
                                className="pull-left mr-2 mt-2 pt-1 code_clr pl-3"
                                custom
                                value={this.state.fields.logbookData}
                                checked={this.state.fields.logbookData}
                                onChange={this.logbookActivateDeactivate.bind(this)}
                                id="logbook"
                                type="checkbox"
                                label=""
                                style={{ position: "relative", left: "20px" }}
                              // disabled = {this.state.disabledLogbook}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="content-innerdiv">
                              <div>
                                <label>{t('description')}:</label>
                                <label>{this.state.fields.description}</label>
                              </div>
                              <div>
                                <label>{t('installation_site')}:</label>
                                <label>
                                  {this.state.fields.installationSite ? t(this.state.fields.installationSite) : ""}
                                </label>
                              </div>
                              <div>
                                <label>{t('forwarded_to')}:</label>
                                <label>
                                  {this.state.fields.vendorName ? t(this.state.fields.vendorName) : ""}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="content-innerdiv">
                          <div>
                            <label>{t('description')}:</label>
                            <label>{this.state.fields.description}</label>
                          </div>
                          <div>
                            <label>{t('installation_site')}:</label>
                            {this.state.fields.installationSite ? t(this.state.fields.installationSite) : ""}
                          </div>
                          <div>
                            <label>{t('forwarded_to')}:</label>
                            <label>
                              {this.state.fields.vendorName ? t(this.state.fields.vendorName) : ""}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="content-innerdiv">
                        <div className="page-subtitle">
                          {t('boot_configuration')} <i class="fas fa-cogs"></i>
                        </div>
                        <div>
                          <label>{t('fw_version')}: </label>
                          <label>{this.state.fields.fwVersion}</label>
                          {
                            (access && hasPermission(user, ["ChargerFirmware_R"])) &&
                            <>
                              {
                                ((this.state.fields.chargePointModel?.search(/(?:DX|FC)/i) > -1) || (this.state.fields.fwVersion === null)) ?
                                <></>
                                  : (this.state.firmwareCurrentVersion === null) ?
                                  <button
                                    type='button'
                                    className='ml-3 border-0 rounded'
                                    style={{ color: '#fff', backgroundColor: '#00529e' }}
                                    onClick={this.checkUpdateFirmwareAvailable.bind(this, page_title1)}
                                  >
                                    {t('check_for_update')}
                                  </button> :
                                  <button
                                    type='button'
                                    className='ml-3 border-0 rounded'
                                    style={{ color: '#fff', backgroundColor: '#00529e' }}
                                    onClick={this.getLatestOTAStatus.bind(this)}
                                  >
                                    {t('firmware_update')}
                                  </button>
                              }
                            </>
                          }
                        </div>
                        {
                          ((this.state.fields.chargePointModel?.search(/(?:DX|FC)/i) > -1) && (this.state.fields.softwareVersion !== null)) ?
                          <div>
                          <label>{t('power_version')}: </label>
                          <label>{this.state.fields.softwareVersion}</label>
                          </div> : <></>                            
                        }
                        <div>
                          <label>{t('model')}: </label>
                          <label>{this.state.fields.chargePointModel}</label>
                        </div>
                        <div>
                          <label>{t('mes_serial_number')}: </label>
                          <label>{this.state.fields.chargePointSerialNumber}</label>
                        </div>
                        <div>
                          <label>{t('chargebox_serial_number')}: </label>
                          <label>{this.state.fields.chargeBoxSerialNumber}</label>
                        </div>
                        <div>
                          <label>{t('meter_serial_number')}: </label>
                          <label>{this.state.fields.meterSerialNumber}</label>
                        </div>
                        <div>
                          <label>{t('part_number')}: </label>
                          <label>{this.state.fields.partNumber}</label>
                        </div>
                        <div>
                          <label>{t('metre_type')}: </label>
                          <label>{this.state.fields.meterType}</label>
                        </div>
                        <div>
                          <label>{t('iccid')}: </label>
                          <label>{this.state.fields.iccid}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {(wallboxEnable == true && access) ? (
                        <>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="content-innerdiv">
                                <div className="page-subtitle fs-20">
                                  {t('address')} <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <div>
                                  <label>{t('house_number')}:</label>
                                  <label>
                                    {this.state.fields.address.houseNumber != undefined && this.state.fields.address.houseNumber != "" ?
                                      this.state.fields.address.houseNumber : ""}
                                  </label>
                                </div>
                                <div>
                                  <label>{t('street')}:</label>
                                  <label>{this.state.fields.address.street}</label>
                                </div>
                                <div>
                                  <label>{t('city')}:</label>
                                  <label>{this.state.fields.address.city}</label>
                                </div>
                                <div>
                                  <label>{t('zipCode')}:</label>
                                  <label>{this.state.fields.address.zipCode}</label>
                                </div>
                                <div>
                                  <label>{t('country')}:</label>
                                  <label>{this.state.fields.address.country}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-12">
                              <div className="content-innerdiv">
                                <div className="page-subtitle fs-20">
                                  {t('assignment')}
                                  {wallboxEnable == true ? (
                                    <>
                                      {this.state.assignedEmail != undefined && this.state.assignedEmail != "" && this.state.assignedEmail != null ? (
                                        <button
                                          type="button"
                                          className="btn_secondary float-right"
                                          onClick={this.togglewallboxConfirm}
                                          style={{ height: "35.2px" }}
                                        >
                                          {t('delete')}{" "}
                                          <i class="fas fa-trash-alt ml-2"></i>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn_primary float-right"
                                          onClick={this.togglesession1.bind(this)}
                                          style={{ height: "35.2px" }}
                                        >
                                          {t('assign')}
                                        </button>
                                      )}
                                    </>
                                  ) : null}
                                </div>
                                <div>
                                  <label>{t('wallbox_assigned_to')} </label>
                                  <label>{this.state.assignedEmail}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="content-innerdiv">
                          <div className="page-subtitle fs-20">
                            {t('address')} <i class="fas fa-map-marker-alt"></i>
                          </div>
                          <div>
                            <label>{t('house_number')}:</label>
                            <label>{this.state.fields.address.houseNumber}</label>
                          </div>
                          <div>
                            <label>{t('street')}:</label>
                            <label>{this.state.fields.address.street}</label>
                          </div>
                          <div>
                            <label>{t('city')}:</label>
                            <label>{this.state.fields.address.city}</label>
                          </div>
                          <div>
                            <label>{t('zipCode')}:</label>
                            <label>{this.state.fields.address.zipCode}</label>
                          </div>
                          <div>
                            <label>{t('country')}:</label>
                            <label>{this.state.fields.address.country}</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <MDBRow className="mb-4 mt-3">
                    <MDBCol md="12" className="text-right">
                      <Link
                        to={{
                          pathname: "/editChargepoint",
                          state: {
                            chargePointId: this.state.fields.chargePointId,
                            id: this.state.fields.id,
                          },
                        }}
                      >
                        {this.state.rEditAccess == true &&
                          localStorage.getItem("role") ==
                          "WEBASTO_END_USER_RESIDENTIAL" && access ? (
                          <button
                            type="button"
                            data-test="button"
                            className="mt-5 mr-3 btn_primary"
                            id="editBtn"
                            style={{ height: "35.2px" }}
                          >
                            {t('edit')} <i class="fas fa-pencil-alt ml-2"></i>
                          </button>
                        ) : (
                          hasPermission(user, ["ChargePoint_U"]) && access && (
                            <button
                              type="button"
                              data-test="button"
                              style={{
                                display: this.state.hideActionBtn,
                                height: "35.2px",
                              }}
                              className="mt-5 mr-3 btn_primary"
                              id="editBtn"
                            >
                              {t('edit')} <i class="fas fa-pencil-alt ml-2"></i>
                            </button>
                          )
                        )}
                      </Link>
                    </MDBCol>
                  </MDBRow>
                </MDBTabPane>
                {/* end details */}

                {/* transactions */}
                <MDBTabPane tabId="2" role="tabpanel">
                  <div id="transactions">
                    <div className="row  mt-2">
                      <MDBCol md="4" className='pr-0 calWidth'>
                        <span title={t('from_time')} className="cal_label mr-2">{t('from_time')}</span>
                        <div className="pull-left mt-2" >
                          <DatePicker
                            selected={this.state.fromDate}
                            onChange={this.handleFromChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            id="expiryDateId"
                            className="cal_margin tag_callogs"
                            timeCaption="time"
                            dateFormat="dd-MMM-yyyy HH:mm"
                            maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                          />
                        </div>
                        <div className="error_msg w3-animate-top col-md-12 datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                      </MDBCol>
                      <MDBCol md="4" className='pr-0 calWidth'>
                        <span title={t('to_time')} className="cal_label mr-2">{t('to_time')}</span>
                        <div className="pull-left mt-2" >
                          <DatePicker
                            selected={this.state.toDate}
                            onChange={this.handleToChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            id="expiryDateId"
                            className="cal_margin tag_callogs"
                            timeCaption="time"
                            dateFormat="dd-MMM-yyyy HH:mm"
                            minDate={new Date(this.state.fromDate)}
                            maxDate={new Date()}
                          />
                        </div>
                        <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
                      </MDBCol>
                      <div className="col-md-2 mt-2">
                        {hasPermission(user, ["SessionExport_R"]) && <>
                          <button
                            type="button"
                            data-test="button"
                            className="mt-2 mr-3 btn_primary"
                            onClick={this.exportTransactionCSV}
                          >
                            {t('export')} <i className="fas fa-file-export ml-2"></i>
                          </button>
                        </>
                        }
                      </div>
                      <div className="col-md-3 mt-2 pt-1">
                        <div className="pull-right">
                          <label className="sub-text pull-left">
                            {t('show_entries')}
                          </label>
                          <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv">
                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                              <option>10</option>
                              <option>20</option>
                              <option>30</option>
                              <option>40</option>
                              <option>50</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2 ">
                      <div class="col-md-12 p-rel">
                        {/* settings popup */}
                        <div className='settingOuterDiv'>
                          <MDBModal isOpen={this.state.settingPopup} size="md" className="settingDiv" backdrop="static" data-backdrop="static" data-keyboard="false">
                            <MDBModalHeader toggle={this.togglesettingPopup} >{t('table_content')}</MDBModalHeader>
                            <MDBModalBody className="mt-0">
                              <div className="row">
                                {this.state.getFieldsList.map((el, index) => {
                                  if (editAction == false && (el.displayName == "Customer Number" || el.displayName == "Reference Number")) {
                                  } else {
                                    return (
                                      <div className="col-md-6">
                                        <Form.Check
                                          className=""
                                          custom
                                          id={`${el.key}_${index}`}
                                          type="checkbox"
                                          checked={this.isItemSelected(el.key)}
                                          onChange={(event) => this.updateCheckboxSelection(event, el.key)}
                                          label={t(el.key)}
                                        />
                                      </div>
                                    )
                                  }
                                })
                                }
                              </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                              <button type="button" data-test="button" className={this.state.selectedItems.length > 0 ? "btn_primary mr-2" : "btn_primary mr-2 btn_cancel"} onClick={this.saveTableField} disabled={this.state.selectedItems.length > 0 ? "" : "true"}>{t('save')}<i class="fas fa-save ml-2"></i></button>
                            </MDBModalFooter>
                          </MDBModal>
                        </div>

                        <div className='overflowx'>
                          <Table striped hover size="sm" className="page-table responsive">
                            <thead>
                              <tr>
                                {title}
                                <th className="p-rel">
                                  <div className="gear_iconDiv">
                                    <i className="fas fa-cog gear_icon" onClick={this.settingAction}></i>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 mb-4 text-right" style={{ zIndex: "0" }}>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pageSize}
                          totalItemsCount={this.state.noOfRecords}
                          onChange={this.handlePageChange.bind(this)}
                          prevPageText={t('prev')}
                          nextPageText={t('next')}
                          itemClassPrev="prevBtn"
                          pageRangeDisplayed="1"
                          activeClass="activeLi active"
                          itemClassNext="nextBtn"
                          hideFirstLastPages={true}
                          disableInitialCallback={true}
                          disabledClass="disabled disabledtextColor"
                        />
                      </div>
                      <div class="col-md-12 mt-4"></div>
                    </div>
                  </div>
                  <div id="metervalues">
                    {/* page title */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="pull-left">
                          <div className="page-subtitle">{t('meter_values')}</div>
                        </div>
                        <div className="pull-right ">
                          <button
                            class="pull-right btn_primary ml-2"
                            type="button"
                            onClick={this.getMeterVaulesByRefresh}
                          >
                            {t('refreshBtn')} <i className="fas fa-refresh ml-2"></i>
                          </button>
                          <button
                            class="pull-right btn_primary"
                            type="button"
                            onClick={this.showTrasactions}
                          >
                            <i className="fas fa-angle-left mr-2"></i> {t('back')}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 ">
                        <div className="content-innerdiv">
                          <div >
                            <label>{t('charge_point_id')}</label>
                            <label>{this.state.transData.chargePointId}</label>
                          </div>
                          <div >
                            <label>{t('connector_id')}</label>
                            <label>{this.state.transData.connectorId}</label>
                          </div>
                          <div >
                            <label>{t('charging_duration_label')}</label>
                            <label><i class="far fa-clock"></i> {this.convertMinSec(this.state.transData.chargingDuration)}</label>
                          </div>

                          <div >
                            <label>{t('startValue')}</label>
                            <label>{this.state.transData.startValue}</label>
                          </div>
                          <div>
                            <label>{t('vehicle_mileage')}</label>
                            <label>{(this.state.transData.mileage != null && this.state.transData.mileage != "") ? this.state.transData.mileage : ""}</label>
                          </div>
                          <div>
                            <label>{t('vehicle_inventory_number')}</label>
                            <label>{(this.state.transData.vehicleInventoryNumber != null && this.state.transData.vehicleInventoryNumber != "") ? this.state.transData.vehicleInventoryNumber : ""}</label>
                          </div>
                          <div >
                            <label>{t('max_charging')}</label>
                            <label>{this.state.transData.maximumChargingPower}</label>
                          </div>
                          <div >
                            <label>{t('tagType')}</label>
                            <label>{this.state.transData.tagType}</label>
                          </div>
                          <div >
                            <label>{t('chargingStationLocation')}</label>
                            <label>{this.state.transData.chargingStationLocation}</label>
                          </div>
                          <div>
                            <label>{t('customerNumber')}</label>
                            <label>{this.state.transData.customerNumber}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4" >
                        <div className="content-innerdiv">
                          <div>
                            <label>{t('charging_status')}</label>
                            <label className="wrap-word  p-rel "> <div class="click-to-top">
                              <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                              <span>{status_text}</span>
                            </div>
                            </label>
                          </div>
                          <div >
                            <label>{t('id_tag_label')}</label>
                            <label>{this.state.transData.idTag}</label>
                          </div>
                          <div >
                            <label>{t('startTime')}</label>
                            <label className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </label>
                          </div>
                          <div >
                            <label>{t('chargingSessionDuration')}</label>
                            <label><i class="far fa-clock"></i>  {this.convertMinSec(this.state.transData.chargingSessionDuration)}</label>
                          </div>
                          <div >
                            <label>{t('goal_reached_time')}</label>
                            <label>{chargingGoalReachedTime}</label>
                          </div>
                          <div >
                            <label>{t('stopValue')}</label>
                            <label>{this.state.transData.stopValue}</label>
                          </div>
                          <div >
                            <label>{t('current_charging_power')}</label>
                            <label>{this.state.transData.currentChargingPower}</label>
                          </div>
                          <div >
                          <label>{t('referenceNumber')}</label>
                          <label>{this.state.transData.referenceNumber}</label>
                        </div>
                          {(this.state.transData.transactionEndOCMFData != null && this.state.transData.transactionStartOCMFData !== null) &&
                            <>
                              <div>
                                <label>{t('PublicKey')}</label>
                                <label className='publicKey'>{this.state.transData.chargerPublicKey}</label>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="content-innerdiv">
                          <div >
                            <label>{t('transaction_id')}</label>
                            <label>{this.state.transactionId}</label>
                          </div>
                          <div >
                            <label>{t('reservationId')}</label>
                            <label> {this.state.transData.reservationId}</label>
                          </div>
                          <div >
                            <label>{t('stopTime')}</label>
                            <label className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>} </label>
                          </div>
                          <div >
                            <label>{t('chargedEnergy')}</label>
                            <label>{this.state.transData.chargedEnergy ? currencyFormatter.format(this.state.transData.chargedEnergy, { code: this.state.transData.currencyCode, symbol: '' }) : '0'} {t('kwh')}</label>
                          </div>
                          <div >
                            <label>{t('stop_reason')}</label>
                            <label>{this.state.transData.stopReason}</label>
                          </div>
                          <div>
                            <label>{t('energyLevel')}</label>
                            <label>{(this.state.transData.energyLevel != "" && this.state.transData.energyLevel != null && this.state.transData.energyLevel != undefined) ? this.state.transData.energyLevel : ""}kWh</label>
                          </div>
                          <div>
                            <label>{t('note')}</label>
                            <label>{this.state.transData.note}</label>
                          </div>
                          {(this.state.transData.transactionEndOCMFData != null && this.state.transData.transactionStartOCMFData !== null) && <>
                            <div>
                              <label className='vertical_top mt-2'>{t('safe_ev_files')}</label>
                              <label><button type="button" data-test="button" className="border-0 rounded pull-right mr-2 p-1 pl-2 pr-2 safeev_btn" onClick={this.downloadSafeEVFile}> {t('download_files')} </button></label>
                            </div>
                          </>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="pull-left">
                          <div className="page-subtitle">{t('charging_session_costs')}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-12'>
                        <div className="content-innerdiv">
                          <div className="row">
                            <div className='col-md-3 content'>
                              <div>
                                <label>{t('plugged_in_time')}</label>
                                <label>{startTime && <> {startTime} {startTime1} </>} </label>
                              </div>
                              <div>
                                <label>{t('plugged_out_time')}</label>
                                <label>{stopTime && <> {stopTime} {stopTime1} </>} </label>
                              </div>
                            </div>
                            <div className='col-md-5 content'>
                              <div>
                                <label>{t('startValue')}</label>
                                <label>{this.state.transData.startValue ? this.state.transData.startValue + " Wh" : null}</label>
                              </div>
                              <div>
                                <label>{t('stopValue')}</label>
                                <label>{this.state.transData.stopValue ? this.state.transData.stopValue + " Wh" : null}</label>
                              </div>
                              <div>
                                <label>{t('chargedEnergy')}</label>
                                <label>{this.state.transData.chargedEnergy ? currencyFormatter.format(this.state.transData.chargedEnergy, { code: this.state.transData.currencyCode, symbol: '' }) : '0'} kWh</label>
                              </div>
                            </div>
                            <div className='col-md-4 content'>
                              <div>
                                <label>{t('costsPerChargingSession')}</label>
                                <label>{currencyFormatter.format(this.state.transData.costsPerChargingSession, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}</label>
                              </div>
                              <div>
                                <label>{t('costsPerKwh')}</label>

                                <label>
                                  {this.state.transData.chargedEnergy ? currencyFormatter.format(this.state.transData.chargedEnergy, { code: this.state.transData.currencyCode, symbol: '' }) + ' kWh' : '0 kWh'} {' * '}
                                  {currencyFormatter.format(this.state.transData.costsPerKwh, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}{' = '}
                                  {currencyFormatter.format(this.state.transData.chargedEnergy * this.state.transData.costsPerKwh, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}
                                </label>

                              </div>
                              <div>
                                <label style={{ fontSize: '16px' }}>{t('chargingSessionCost')}</label>
                                <label style={{ fontSize: '16px', fontWeight: 'bold' }}>{currencyFormatter.format(this.state.transData.chargingSessionCost, { code: this.state.transData.currencyCode, symbol: '' })}{' '}{this.state.transData.chargingSessionCurrency}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="page-subtitle">{t('charging_power')}</div>
                      </div>
                      <div className="col-md-6">
                        <div className="pull-right">
                          <label className="pull-left sub-text"> {t('view')} </label>
                          <div className="toggle-btnb">
                            <label className={this.state.bgColor1} onClick={this.changeColor.bind(this)}>{t('graph')}</label>
                            <label className={this.state.bgColor2} onClick={this.changeColor.bind(this)}>{t('table')}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* table start */}
                    <div style={{ display: this.state.tableView }}>
                      <div className="row mt-2" id="tableView">
                        <div className="col-md-3">
                          <div class="input-group">
                            <i className="fas fa-search fa-sm input-searchIcon"></i>
                            <input type="text" id="findValue" value={this.state.findValue} onChange={this.changeHandler.bind(this, "findValue")} onKeyDown={this.keyPress3} class="form-control input-searchBox pad-2" placeholder={t('find_a_value')} />
                            <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.clearValue}>
                              <span aria-hidden="true">x</span>
                            </button>
                            <div class="input-group-btn">
                              <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeValue}>{t('find')}</button>
                            </div>
                          </div>
                          <span className="error_msg w3-animate-top mt-2">
                            <span style={{ color: "red" }}>{this.state.errors["chargePowerError"]}</span>
                          </span>
                        </div>
                        <div className="col-md-3">
                          <div class="input-group">
                            <input type="text" class="form-control input-searchBox" value={(startMeterDate != "" || endMeterDate != "") ? (startMeterDate + " - " + endMeterDate) : ""} placeholder={t('date')} name="srch-term" id="srch-term" />
                            <div class="input-group-btn">
                              <button class="input-searchBtn" type="submit" onClick={this.displayfromCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                            </div>
                          </div>

                          <div style={{ display: this.state.calenderfromDisplay }} className="calenderContainer">
                            <div onClick={this.closeMeterValuesCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                            <Calendar
                              onChange={this.onChangeMeterValueDate}
                              value={this.state.value}
                              selectRange="true"
                              format="dd-M-yyyy"
                              dayPlaceholder=""
                              yearPlaceholder=""
                              monthPlaceholder=""
                              nativeInputAriaLabel="Date"
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pull-right">
                            <label className="sub-text pull-left" >{t('show_entries')}</label>
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                              <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries1" onChange={this.showEntriesChange1}>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <Table striped hover size="sm" className="page-table">
                            <thead>
                              <tr>
                                <th>{t('value')}</th>
                                <th>{t('value_time')}</th>
                                <th>{t('charging_power')}</th>
                                <th>{t('unit')}</th>
                              </tr>
                            </thead>
                            <tbody>{data}</tbody>
                          </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                          <Pagination
                            hideDisabled
                            activePage={this.state.activePage1}
                            itemsCountPerPage={this.state.pageSize1}
                            totalItemsCount={this.state.noOfRecords1}
                            onChange={this.handlePageChange1.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: this.state.graphView }}>
                      <div className="row" id="graphView">
                        <div className="col-md-12">
                          <div className="mt-1 img-main-div">
                            <Line
                              data={this.state.dataLine}
                              height={"105px"}
                              options={
                                ({ responsive: true },
                                {
                                  legend: {
                                    labels: {
                                      fontColor: "orange",
                                    },
                                  },
                                },
                                {
                                  scales: {
                                    yAxes: [
                                      {
                                        scaleLabel: {
                                          display: true,
                                          labelString: "kW",
                                          fontColor: "#00529e",
                                        },
                                        ticks: this.state.displayMaxValues,
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        scaleLabel: {
                                          display: true,
                                          fontColor: "#00529e",
                                        },
                                      },
                                    ],
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* businessErrors start table */}
                    <div className="col-md-12">
                      <label className="mt-3">
                        <h6>{t('business_errors')}:</h6>{" "}
                      </label>
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>{t('time_stamp')}</th>
                            <th>{t('internal_error_code')}</th>
                            <th>{t('description')}</th>
                          </tr>
                        </thead>
                        <tbody>{errorsData}</tbody>
                      </Table>
                    </div>
                    {/* End table  */}
                  </div>
                </MDBTabPane>
                {/* end transactions */}

                {/*  connectors */}
                <MDBTabPane tabId="3" role="tabpanel">
                  <div class="row  mt-4">


                    <div class="col-md-10">
                      <button
                        class="btn_primary pull-right"
                        type="button"
                        data-test="button"
                        onClick={this.getConnectorStatus}
                      >
                        {t('refreshBtn')} <i className="fas fa-refresh fa-sm ml-2"></i>
                      </button>
                    </div>
                    <div class="col-md-2">
                      {
                        localStorage.getItem('role') == 'WEBASTO_SUPER_ADMIN_DEVOPS' &&
                        <button className="btn_primary pull-right " onClick={this.exportStatus.bind(this, page_title1)} type="button" data-test="button">
                          {t('export')}
                          <i className="fas fa-file-export ml-2"></i>
                        </button>
                      }
                    </div>

                  </div>
                  <div class="row mt-2 ">
                    <div class="col-md-12">
                      <table class="table page-table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>
                              {t('connector_id')}{" "}
                              <i
                                class="fas fa-sort sortIcon"
                                onClick={this.ascendingOrderForConnectorStatus.bind(
                                  this
                                )}
                              ></i>
                            </th>
                            <th>{t('chargingSessionState')}</th>
                            <th>
                              {t('timestamp')}{" "}
                              <i
                                class="fas fa-sort sortIcon"
                                onClick={this.ascendingOrderForConnectorStatus.bind(
                                  this
                                )}
                              ></i>
                            </th>
                            <th>{t('chargingProfileId')}</th>
                          </tr>
                        </thead>
                        <tbody>{connectorStatus}</tbody>
                      </table>
                    </div>
                  </div>
                </MDBTabPane>
                {/*  end connectors */}

                {/*  get Installation */}
                <MDBTabPane tabId="4" role="tabpanel">
                  {this.state.pdfloading == "true" ? (
                    <Loader loader={this.state.pdfloading} />
                  ) : (
                    ""
                  )}
                  <Alert
                    color={this.state.colour}
                    isOpen={this.state.sucess}
                    className="mt-2"
                  >
                    {this.state.message}
                  </Alert>
                  <div>
                    <Document
                      file={`data:application/pdf;base64, ${this.state.basepdfurl}`}
                      onLoadSuccess={this.onDocumentLoad}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </Document>
                  </div>
                </MDBTabPane>
                {/*  end get Installation */}

                {/*  get Diagnostics */}
                <MDBTabPane tabId="5" role="tabpanel">
                  {this.state.loading == "true" ? (
                    <Loader loader={this.state.loading} />
                  ) : (
                    ""
                  )}
                  <Alert
                    color={this.state.colour}
                    isOpen={this.state.sucess}
                    className="mt-2"
                  >
                    {this.state.message}
                  </Alert>
                  <div class="row  mt-2">
                    <div class="col-md-6 ">
                      {" "}
                      <button
                        class="btn_primary"
                        type="button"
                        data-test="button"
                        onClick={this.getDiagnostics}
                      >
                        {t('Get Diagnostics')}
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div className="col-sm-6">
                      <div className="content-innerdiv">
                        <div>
                          <span>
                            <b> {t('file_name')}: </b>
                          </span>
                          <label>{this.state.diagnosticsData.fileName}</label>
                        </div>
                        <div>
                          <span>
                            <b>{t('period')}: </b>
                          </span>
                          <label>
                            <span>
                              <b> {t('from')} </b>
                            </span>
                            {this.state.diagnosticsData.diagnosticsStartDate
                              ? moment(
                                this.state.diagnosticsData
                                  .diagnosticsStartDate
                              )
                                .format("DD-MMM-YYYY HH:mm")
                                .toUpperCase()
                              : null}
                            -{" "}
                            <span>
                              <b> {t('to')} </b>{" "}
                            </span>
                            {this.state.diagnosticsData.diagnosticsStopDate
                              ? moment(
                                this.state.diagnosticsData.diagnosticsStopDate
                              )
                                .format("DD-MMM-YYYY HH:mm")
                                .toUpperCase()
                              : null}
                          </label>
                        </div>
                        <div>
                          <label>{t('details')}:</label>
                          <div className="diagnostics_css mt-1">
                            <Markup
                              content={this.state.diagnosticsData.statusTables}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="content-innerdiv">
                        <div className="view-bg-row">
                          <span>
                            <b> {t('file_uploaded_date')}:</b>
                          </span>
                          <label>
                            {this.state.diagnosticsData.fileUploadedDate
                              ? moment(
                                this.state.diagnosticsData.fileUploadedDate
                              )
                                .format("DD-MMM-YYYY HH:mm")
                                .toUpperCase()
                              : null}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBTabPane>
                {/*  end get Diagnostics */}
                {/*  Configurration */}
                <MDBTabPane tabId="6" role="tabpanel">
                  <Alert
                    color={this.state.colour3}
                    isOpen={this.state.sucess2}
                    className="mt-2"
                  >
                    {this.state.configmessage}
                  </Alert>
                  <div class="row  mt-2">
                    <div class="col-md-6 ">
                      {hasPermission(user, ["ChargerConfiguration_R"]) && <>
                        <button
                          class="btn_primary"
                          type="button"
                          data-test="button"
                          onClick={this.getConfigurations}
                        >
                          {t('Get Configuration')}
                        </button>
                      </>
                      }
                    </div>
                  </div>

                  {/* favourites configration */}
                  <div class="row mt-2 ">
                    <div className='col-md-12'>
                      <h6 className='mb-0 pb-1'> <b>  {t('favourites')}: {this.state.cpmodel}</b> <label className='pl-5'> <b>{t('charger_status')} : <i class="fas fa-square-full" style={{ color: this.state.onlineStatus ? "#29be29" : "#ef1111" }}> </i></b> </label></h6>
                    </div>
                    <div class="col-md-12">
                      <table class="table  table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th className='p-rel'>
                              <span className='p-rel'>
                                {t('configurationkey')}
                                <i
                                  class="fas fa-filter ml-5"
                                  id="dropdownMenuReference"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-reference="parent"
                                  onClick={this.tabToggle.bind(this, "6")}
                                ></i>
                                <div
                                  class="dropdown-menu config_key"
                                  aria-labelledby="dropdownMenuReference"
                                >
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.sortByAtoZFav.bind(this)}
                                  >
                                    {t('sort_by_a-z')}
                                  </Link>
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.sortByZtoAFav.bind(this)}
                                  >
                                    {t('sort_by_z-a')}
                                  </Link>
                                </div>
                              </span>
                            </th>
                            <th className='p-rel'>
                              <span className='p-rel'>
                                {t('configuration_value')}
                                <i
                                  class="fas fa-filter ml-5"
                                  id="dropdownMenuReference"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-reference="parent"
                                  onClick={this.tabToggle.bind(this, "6")}
                                ></i>
                                <div
                                  class="dropdown-menu config_value"
                                  aria-labelledby="dropdownMenuReference"
                                >
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.lowToHighFav.bind(this)}
                                  >
                                    {t('sort_by_lowhigh')}
                                  </Link>
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.highToLowFav.bind(this)}
                                  >
                                    {t('sort_by_highlow')}
                                  </Link>
                                </div>
                              </span>
                            </th>
                            <th>
                              {t('last_update')}
                            </th>
                            {hasPermission(user, ["ChargerConfiguration_R"]) && <>
                              <th>
                                {t('action')}
                              </th>
                            </>}

                          </tr>
                        </thead>
                        <tbody>{favkeys}</tbody>
                      </table>
                    </div>
                  </div>
                  {/* end */}
                  <div class="row mt-2 ">
                    <div className='col-md-12'>
                      <h6 className='mb-0 pb-1'> <b>{t('configuration')}: </b></h6>
                    </div>
                    <div class="col-md-12">
                      <table class="table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th className='p-rel'>
                              <span className='p-rel'>
                                {t('configurationkey')}
                                <i
                                  class="fas fa-filter ml-5"
                                  id="dropdownMenuReference"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-reference="parent"
                                  onClick={this.tabToggle.bind(this, "6")}
                                ></i>
                                <div
                                  class="dropdown-menu config_key"
                                  aria-labelledby="dropdownMenuReference"
                                >
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.sortByAtoZ.bind(this)}
                                  >
                                    {t('sort_by_a-z')}
                                  </Link>
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.sortByZtoA.bind(this)}
                                  >
                                    {t('sort_by_z-a')}
                                  </Link>
                                </div>
                              </span>
                            </th>
                            <th className='p-rel'>
                              <span className="p-rel">
                                {t('configuration_value')}
                                <i
                                  class="fas fa-filter ml-5"
                                  id="dropdownMenuReference"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-reference="parent"
                                  onClick={this.tabToggle.bind(this, "6")}
                                ></i>
                                <div
                                  class="dropdown-menu config_value"
                                  aria-labelledby="dropdownMenuReference"
                                >
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.lowToHigh.bind(this)}
                                  >
                                    {t('sort_by_lowhigh')}
                                  </Link>
                                  <Link
                                    class="dropdown-item dropdown-item-op"
                                    onClick={this.highToLow.bind(this)}
                                  >
                                    {t('sort_by_highlow')}
                                  </Link>
                                </div>
                              </span>
                            </th>
                            <th>
                              {t('last_update')}

                            </th>
                            {hasPermission(user, ["ChargerConfiguration_R"]) && <>
                              <th>
                                {t('action')}
                              </th>
                            </>}
                          </tr>
                        </thead>
                        <tbody>{keys}</tbody>
                      </table>
                    </div>
                  </div>
                </MDBTabPane>
                {/*  end configuration */}
                {/* Location & Pictures start */}
                <MDBTabPane tabId="7" role="tabpanel">
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <div className='picturesBg'>
                        <div className="row">
                          <div className="col-md-9">
                            <h4 style={{ fontWeight: 'bold' }}>{t('pictures')}</h4>
                          </div>
                          <div className="col-md-3">
                            <div className="pull-right">
                              <label className="pull-left">
                                <button type="button" data-test="button" className="btn_primary" onClick={this.toggleUploadImages}>
                                  <i className="fa fa-plus"></i> {t('add')}
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {t('pictures_desc')}
                          </div>
                        </div>
                        <div className="row mt-4">
                          {
                            this.state.getImagesList[0] ?
                              <div className="col-md-3">
                                <img src={this.state.getImagesList[0]?.imageName} alt="" className="image_container" />
                                <a className="remove-image" href="#" style={{ display: 'inline' }} onClick={this.toggleDeleteModal.bind(this, this.state.getImagesList[0]?.id)}>&#215;</a>
                              </div>
                              :
                              <div className="col-md-3">
                                <div className='image_container' style={{ position: 'relative' }}>
                                  <i class="fas fa-plus fa-5x image_add" onClick={this.toggleUploadImages}></i>
                                </div>
                              </div>
                          }
                          {
                            this.state.getImagesList[1] ?
                              <div className="col-md-3">
                                <img src={this.state.getImagesList[1]?.imageName} alt="" className="image_container" />
                                <a className="remove-image" href="#" style={{ display: 'inline' }} onClick={this.toggleDeleteModal.bind(this, this.state.getImagesList[1]?.id)}>&#215;</a>
                              </div>
                              :
                              <div className="col-md-3">
                                <div className='image_container' style={{ position: 'relative' }}>
                                  <i class="fas fa-plus fa-5x image_add" onClick={this.toggleUploadImages}></i>
                                </div>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBTabPane>
                {/* Local Users start */}
                <MDBTabPane tabId="8" role="tabpanel">
                  {
                    ((this.state.fields.isChargerOwner === true && localStorage.getItem('role') === "WEBASTO_END_USER_RESIDENTIAL")) || localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS ? (
                      <div class="row mt-4">
                        <div class="col-md-12">
                          <div className="pull-right">
                            <label className="pull-left">
                              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleAddUser}>
                                <i className="fa fa-plus"></i> {t('add')}
                              </button>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table page-table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Password</th>
                            <th>User Type</th>
                            <th>History</th>
                            {
                              ((this.state.fields.isChargerOwner === true && localStorage.getItem('role') === "WEBASTO_END_USER_RESIDENTIAL")) || localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS ? (
                                <th style={{ textAlign: "center" }}>Actions</th>
                              ) : null
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {localUsers}
                          {
                            (this.state.localUsers?.length == 0) ?
                              <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>No Data Available</td>
                              </tr> : null
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </MDBTabPane>
                {/* Cloud Users start */}
                <MDBTabPane tabId="9" role="tabpanel">
                  <div class="row  mt-4">
                    <div class="col-md-12">
                      <Table striped hover size="sm" className="page-table">
                        <thead>
                          <tr>
                            <th>E-mail</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Status</th>
                            <th>History</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cloudUsers}
                          {/* {"Cloud Users"} */}
                          {
                            (this.state.dataArray2?.length == 0) ?
                              <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>No Data Available</td>
                              </tr> : null
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </MDBTabPane>
              </MDBTabContent>
            </form>
          </div>
          {/* popup for session expire */}

          <MDBModal
            isOpen={this.state.modalsession}
            toggle={this.togglesession}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglesession}>
              {t('session_has_expired')}
            </MDBModalHeader>
            <MDBModalBody>{t('please_login_again')}</MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button
                type="button"
                data-test="button"
                onClick={this.sessiontagOk}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal
            isOpen={this.state.modelwallbox}
            toggle={this.togglesession1}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglesession1}>
              {t('wallbox_to_residential_enduser')}
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-12 md-form">
                  <input
                    name="wallbox"
                    type="text"
                    id="wallbox"
                    className="form-control text_lowercase"
                    onChange={this.changeWallboxEmail}
                    value={this.state.assignedmail}
                  />
                  <label
                    htmlFor="wallbox"
                    className={`${this.state.assignedmail != "" &&
                      this.state.assignedmail != null
                      ? "active"
                      : ""
                      } ml-15`}
                  >
                    {t('user_email_id') + "*"}
                  </label>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.togglesession1}
                className="btn_cancel"
              >
                {t('cancel')} <i class="fas fa-times ml-2"></i>
              </button>
              <button
                type="button"
                data-test="button"
                onClick={this.assignWallbox.bind(this, this.state.assignedmail)}
                className="btn_primary"
              >
                {t('save')} <i class="fas fa-save ml-2"></i>
              </button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={this.state.wallboxError}
            toggle={this.togglewallboxError}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglewallboxError}>
              {t('Error')}
            </MDBModalHeader>
            <MDBModalBody>{this.state.error}</MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.togglewallboxError}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={this.state.wallboxSucess}
            toggle={this.togglewallboxSucess}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglewallboxSucess}>
              {t('success')}
            </MDBModalHeader>
            <MDBModalBody>{this.state.sucessMessage}</MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.togglewallboxSucess}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={this.state.confirmdelete}
            toggle={this.togglewallboxConfirm}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglewallboxConfirm}>
              {t('alert')}
            </MDBModalHeader>
            <MDBModalBody>
              {t('you_want_to_delete_assignment')}
            </MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.removeWallbox.bind(
                  this,
                  this.state.assignedEmail
                )}
                className="btn_primary"
              >
                {t('yes')}
              </button>
              <button
                type="button"
                data-test="button"
                onClick={this.togglewallboxConfirm}
                className="btn_primary"
              >
                {t('close')}
              </button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={this.state.sucesslogbook}
            toggle={this.togglesucesslogbook}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.togglesucesslogbook}>
              {t('success')}
            </MDBModalHeader>
            <MDBModalBody>{this.state.logbookSuccessMessage}</MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.togglesucesslogbook}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.remoteStart} toggle={this.toggleRemoteStart} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleRemoteStart} className="mt-3"><b>{t('remote_start')}</b></MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="8">
                  <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)} value={this.state.selectedRfid}>
                    <option>-- {t('select_rfid_tag')} *--</option>
                    {tagList}
                  </select>
                  <span className="error_msg w3-animate-top error_rfid" style={{ color: "red" }}></span>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="12">
                  <div className="md-form">
                    <input type="text" id="note" className="form-control pl-0" onChange={(e) => { this.setState({ note: e.target.value }) }} value={this.state.note} />
                    <label htmlFor="note" className={(this.state.note != "" && this.state.note != null) ? "active" : ""}><b>{t('note(optional)')}</b></label>
                    <span className="error_Msg w3-animate-top error_note" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow id="customization" className="mt-3">
                <MDBCol md="12" className="energy_limitation">
                  <Form.Check
                    className="mt-3"
                    custom
                    value={(this.state.isactiveEnergy == true) ? true : false}
                    checked={(this.state.isactiveEnergy == true) ? true : false}
                    id={`energyLimit`}
                    type="checkbox"
                    label={<div><b>{t('activate_energy_limitation')}</b></div>}
                    onChange={() => {
                      this.setState({ isactiveEnergy: !this.state.isactiveEnergy }, () => {
                        if (this.state.isactiveEnergy == false) {
                          this.setState({
                            energyLevel: ""
                          })
                        } else if (this.state.isactiveEnergy == true) {
                          this.setState({
                            energyLevel: ""
                          })
                        }
                      })
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="6">
                  <div className="md-form">
                    <input type="text" id="energyLevel" disabled={(this.state.isactiveEnergy != true) ? true : false} className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateEnergyLevel} value={this.state.energyLevel} />
                    <label htmlFor="energyLevel" className={(this.state.energyLevel != "" && this.state.energyLevel != null) ? "active" : ""}><b>{t('limit_charged_energy_to(kWh)')}</b></label>
                    <span className="error_Msg w3-animate-top error_energyLevel" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.beginRemoteStart.bind(this, this.state.selectedcpId, this.state.selectedTransactionId)}>{t('start')}</button>
              <button type="button" className="btn_cancel" onClick={this.toggleRemoteStart.bind(this)}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.successTransaction} toggle={this.successTransaction} size="md" className="model_top">
            <MDBModalHeader toggle={this.successTransaction}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('transaction_started_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.successTransaction.bind(this); window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.mileageNdInventory} toggle={this.toggleMileageNdInventory} size="md" className="model_top">
            <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
              <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('insert_mileage_inventory_no_to_chargingsession')}</h4>
            </div>
            <MDBModalBody>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="mileage" className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateMileage.bind(this)} value={this.state.mileage} />
                    <label htmlFor="mileage" className={(this.state.mileage != "" && this.state.mileage != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('the_current_mileage_of_the_vehicle')}:*</label>
                    <span className="error_Msg w3-animate-top error_mileage" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="inventoryNumber" className="form-control pl-0" onChange={this.updateInventory.bind(this)} value={this.state.inventoryNumber} pattern="[a-zA-Z0-9]*" />
                    <label htmlFor="inventoryNumber" className={(this.state.inventoryNumber != "" && this.state.inventoryNumber != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('the_inventory_no_of_vehicle')}:*</label>
                    <span className="error_Msg w3-animate-top error_inventoryNumber" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <div class="modal-footer border border-0">
              <button type="button" className="btn_primary" onClick={this.saveMileageNdInventory.bind(this, this.state.mileage, this.state.inventoryNumber)}>{t('save')} <i class="fas fa-save ml-2"></i></button>
              <button type="button" className="btn_cancel" onClick={this.toggleMileageNdInventory.bind(this)}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </div>
          </MDBModal>
          <MDBModal isOpen={this.state.modalMileageNdInventory} toggle={this.toggleSaveMileageNdInventory} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleSaveMileageNdInventory}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('mileage_and_inventory_no_saved_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.getTransactions(this.state.pageSize, this.state.activePage); this.toggleSaveMileageNdInventory(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message popup for archive  */}
          <MDBModal isOpen={this.state.modalkye} toggle={this.togglekey} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglekey}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('do_you_want_to')} {this.state.favValue ? "favourite" : "unfavourite"} {t('key')} ?
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.Closekey}>{t('close')}</button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.addInFavList}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.modalkedit} toggle={this.togglekedit} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglekedit}>
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('change_configuration_of')}</div>
            </MDBModalHeader>
            <MDBModalBody>
              <label className='cbreakword '><b>{this.state.editConfigKey}</b></label><br></br>
              <label className='pb-2 pt-2 cbreakword '>{t('current_value')} {this.state.editConfigKeyValue}</label><br></br>
              <div className="w-100 mb-3">
                <label className='pr-3'>{t('new_value')} </label>
                <div className="" style={{ position: "relative", width: "50%", display: "inline-block" }} >
                  <div className="ml-1" >
                    <input
                      type="text"
                      id="onChangeOperationValue"
                      value={this.state.configNewValue}
                      // pattern="[0-9]*"
                      onChange={this.changeConfigValue.bind(this)}
                      class="form-control cpConfigTxt pad-2"
                      style={{ borderLeft: "0px", borderTop: "0px", borderRight: "0px" }}
                    />
                  </div>
                  <span className="error_msg w3-animate-top cal_errormsg"> <span style={{ color: "red" }} >{this.state.errors["emptycpConfigNewValue"]}</span></span>
                </div>
              </div>

              <div style={{ fontSize: "13px" }}>
                {/* <b>Note: Settings should only be changed by qualified Person</b> */}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.Closekedit}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.togglekyeWar}>{t('save')}  <i class="fas fa-save ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>

          {/* edit warning of config key */}

          <MDBModal isOpen={this.state.modalkyeWar} toggle={this.togglekyeWar} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglekyeWar}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('want_to_change_configuration_value')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.togglekyeWar}>{t('close')}</button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.updateConfigListValue}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.modalkyeSuc} toggle={this.togglekyeSuc} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglekyeSuc}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('configuration_key_update_sent_successfully')}
              {/* Update Request for the configuration key value has been sent successfully. */}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.togglekyeSuc}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.isFirmwareUptodate} size="md" className="model_top">
            <MDBModalHeader>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {t('latest_fwversion_in_charger')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.triggerFirmwareAvailable}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isChargerOwner} size="md" className="model_top">
            <MDBModalHeader>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {t('neither_group_admin_nor_owner_of_group')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.triggerNoPermission}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isFirmwareAvailable} size="md" className="model_top">
            <MDBModalHeader>{t('Notifications')}</MDBModalHeader>
            <MDBModalBody>
              {firmware_message}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_cancel" onClick={this.triggerFirmwareNoNeed}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.updateFirmware.bind(this, this.state.chargePointId)}>{t('continue')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isFirmwareInitiated} size="md" className="model_top">
            <MDBModalHeader>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('firmware_initiated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={() => {
                window.location.reload();
              }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isFirmwareNotAvailable} size="md" className="model_top">
            <MDBModalHeader>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {t('firmware_details_not_available')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.triggerisFirmwareNotAvailable}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isImagesLimitExceeded} size="md" className="model_top">
            <MDBModalHeader>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_point_images_limit')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleImagesLimitModal}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.isUploadImagesOpen} size="lg">
            <MDBModalHeader>{t('upload_pictures')}</MDBModalHeader>
            <MDBModalBody>
              <div style={{ position: 'relative' }}>
                <div className="row mt-4" style={{ width: '100%', height: '100%' }}>
                  {
                    (this.state.previewUrl.length > 0 && this.state.previewUrl[0]) ?
                      <div className="col-md-3" style={{ position: 'absolute', top: '37px', left: '61px' }}>
                        <img src={this.state.previewUrl[0]} alt="" className="sub_img_container" />
                        <a className="remove_sub_image" href="#" style={{ display: 'inline' }} onClick={this.removeImage.bind(this, 0)}>&#215;</a>
                      </div>
                      : this.state.previewUrl.length > 0 ?
                        <div className="col-md-3" style={{ position: 'absolute', top: '37px', left: '61px' }}>
                          <div className='sub_img_container'>
                            <i class="fas fa-plus fa-5x image_add" {...dropEvents}></i>
                          </div>
                        </div> : <></>
                  }
                  {
                    (this.state.previewUrl.length > 0 && this.state.previewUrl[1]) ?
                      <div className="col-md-3" style={{ position: 'absolute', top: '37px', left: '271px' }}>
                        <img src={this.state.previewUrl[1]} alt="" className="sub_img_container" />
                        <a className="remove_sub_image" href="#" style={{ display: 'inline' }} onClick={this.removeImage.bind(this, 1)}>&#215;</a>
                      </div>
                      : this.state.previewUrl.length > 0 ?
                        <div className="col-md-3" style={{ position: 'absolute', top: '37px', left: '271px' }}>
                          <div className='sub_img_container'>
                            <i class="fas fa-plus fa-5x image_add" {...dropEvents}></i>
                          </div>
                        </div> : <></>
                  }
                  <div className={classes} {...dropEvents}>
                    <input multiple {...fileInputAttrs} />
                    {
                      this.state.previewUrl.length > 0 ? <></> : <div className="default">
                        {t('drag_drop_desc')} <br /> <span className='sub_text'>{t('max_2_files')}</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.uploadImage}>{t('upload')}</button>
              <button type="button" data-test="button" className="btn_cancel" onClick={this.toggleUploadImages}>{t('cancel')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* chargepoint image delete confirmation modal by Lakshmikanth */}
          <CustomModal
            open={this.state.deleteModal}
            title={t('confirmation')}
            bodyMessage={t('image_delert_confirm')}
            PrimaryButtonText={t('yes')}
            PrimaryButtonOperation={this.deletImage.bind(this, this.state.selectedImage)}
            AdditionalButtonText={t('no')}
            AdditionalButtonOperation={this.toggleDeleteModal}
          />

          {/* chargepoint image delete success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.deleteModalSuccess}
            title={this.state.deleteModaltitle}
            bodyMessage={t(this.state.deleteImageMessage)}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.refreshImageAfterDelete}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.uploadModalSuccess}
            title={this.state.uploadImageTitle}
            bodyMessage={t(this.state.uploadImageMessage)}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.refreshImageAfterUpload}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.imgsizeerror}
            title={t('alert')}
            bodyMessage={t('image_limitation_1')}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleImageSizeError}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.imgmemoryerror}
            title={t('alert')}
            bodyMessage={t('image_limitation_2')}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleImageMemoryError}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.imgemptyerror}
            title={t('alert')}
            bodyMessage={t('img_validation')}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleImgEmptyError}
          />

          {/* logbook warning popup message */}
          <CustomModal
            open={this.state.logBookmodel}
            toggle={this.togglelogBook}
            title={t('alert')}
            bodyMessage={t("package_doesnt_enabled_logbook")}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.togglelogBook}
          />

          {/* set charging limit warning popup message */}
          <CustomModal
            open={this.state.ASetChargingLimit}
            toggle={this.toggleASetChargingLimit}
            title={t('alert')}
            bodyMessage={t("reservation_slot_not_available")}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleASetChargingLimit}
          />

          {/* chargepoint image limit exceeded modal by Lakshmikanth */}
          <CustomModal
            open={this.state.images_limit}
            visibility={'z_top'}
            title={t('alert')}
            bodyMessage={t('charge_point_images_limit')}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleImagesLimit}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.imgtypeerror}
            title={t('alert')}
            bodyMessage={t('image_limitation_3')}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.toggleImageTypeError}
          />

          {/* table content popup success  */}
          <MDBModal isOpen={this.state.successTbContent} toggle={this.successTableContent} size="md" className="model_top">
            <MDBModalHeader toggle={this.successTableContent}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('table_saved_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.successTableContent.bind(this); window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <CustomModal
            open={this.state.firmwareAlert}
            title={t('alert')}
            bodyMessage={t(this.state.firmwareMessage)}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.closeFirmwareMessage}
          />

          {/* chargepoint image upload success modal by Lakshmikanth */}
          <CustomModal
            open={this.state.firmwareDownloadStatus}
            title={t('Firmware Status')}
            bodyMessage={firmware_status}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.triggerOTADownloadStatus}
          />

          {/* cp online satus popup */}
          <CustomModal
            open={this.state.cponlinestatusAlert}
            title={t('alert')}
            bodyMessage={t(this.state.cponlinestatus)}
            PrimaryButtonText={t('ok')}
            PrimaryButtonOperation={this.closeCponlinestatus}
          />

          {/* confirmation message popup for archive  */}
          <MDBModal isOpen={this.state.confirmdeleteuser} size="md" className="model_top">
            <MDBModalHeader>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              Are you sure you want to delete?
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleConfirmDeleteUser}>{t('close')}</button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.deleteLocalUser}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message popup for archive  */}
          <MDBModal isOpen={this.state.deleteSucess} size="md" className="model_top">
            <MDBModalHeader>{t('Success')}</MDBModalHeader>
            <MDBModalBody>
              {this.state.deleteMessage}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleDeleteUser}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirmation message popup for archive  */}
          <MDBModal isOpen={this.state.userAddSucess} size="md" className="model_top">
            <MDBModalHeader>{t('Success')}</MDBModalHeader>
            <MDBModalBody>
              Local User Added Successfully
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleSuccessUser}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* warning message popup for add local user getting error message  */}
          <MDBModal isOpen={this.state.userAddWarning} size="md" className="model_top">
            <MDBModalHeader>{t('Warning')}</MDBModalHeader>
            <MDBModalBody>
              {this.state.updatemsg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleUserMsg}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* add local user modal */}
          <MDBModal isOpen={this.state.addUser} size="md" className="model_top">
            <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
              <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>Add Local User</h4>
            </div>
            <MDBModalBody>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="userEmail" className="form-control pl-0" value={this.state.userFields.useremail} onChange={this.updateUserFields.bind(this, "useremail")} />
                    <label htmlFor="userEmail" className={(this.state.userFields.useremail != "" && this.state.userFields.useremail != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>User Email:*</label>
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.emailError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="firstName" className="form-control pl-0" value={this.state.userFields.firstName} onChange={this.updateUserFields.bind(this, "firstName")} />
                    <label htmlFor="firstName" className={(this.state.userFields.firstName != "" && this.state.userFields.firstName != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>First Name:*</label>
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.firstNameError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="lastName" className="form-control pl-0" value={this.state.userFields.lastName} onChange={this.updateUserFields.bind(this, "lastName")} />
                    <label htmlFor="lastName" className={(this.state.userFields.lastName != "" && this.state.userFields.lastName != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>Last Name:*</label>
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.lastNameError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="userPassword" className="form-control pl-0" value={this.state.userFields.userPassword} onChange={this.updateUserFields.bind(this, "userPassword")} />
                    <label htmlFor="userPassword" className={(this.state.userFields.userPassword != "" && this.state.userFields.userPassword != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>Password:*</label>
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.passwordError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <div class="modal-footer border border-0">
              <button type="button" className="btn_primary" onClick={this.addLocalUser}><i className="fa fa-plus"></i> {t('add')}</button>
              <button type="button" className="btn_cancel" onClick={this.toggleAddUser}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </div>
          </MDBModal>

          {/* local user update password modal */}
          <MDBModal isOpen={this.state.localuserupdatepassowrd} size="md" className="model_top">
            <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
              <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>Update Password</h4>
            </div>
            <MDBModalBody>
              <MDBRow className="mt-3">
                <MDBCol md="12">
                  <div className="md-form">
                    <input type="password" disabled id="orangeForm-pass" className="form-control" value={this.state.localUserOldPassword} />
                    <label htmlFor="orangeForm-pass" className="active">Old-Password</label>

                    {/* <input type="text" id="lastName" className="form-control pl-0" value={this.state.userFields.lastName} 
                    onChange={this.updateUserFields.bind(this, "lastName")} />
                    <label htmlFor="lastName" className={(this.state.userFields.lastName != "" && this.state.userFields.lastName != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>Old Password:*</label> */}
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.lastNameError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="12">
                  <div className="md-form">
                    <input type="text" id="userPassword" className="form-control pl-0" value={this.state.localUserNewPassword}
                      // onChange={this.updateUserFields.bind(this, "userPassword")}
                      // onChange={(event) => this.onChangeLocalUserNewPassword(event)}
                      onChange={this.onChangeLocalUserNewPassword} />
                    <label htmlFor="userPassword" className={(this.state.userFields.userPassword != "" && this.state.userFields.userPassword != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>New Password:*</label>
                    <span className="error_Msg w3-animate-top" style={{ color: "red" }}>{this.state.errors.localUserpwdError}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <div class="modal-footer border border-0">
              <button type="button" className="btn_primary" onClick={this.updateLocalUserPassword}><i className="fa fa-plus"></i> {t('update')}</button>
              <button type="button" className="btn_cancel" onClick={this.toggleUpdatePasswordLocalUser}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </div>
          </MDBModal>

          {/* local user update password success message popup  */}
          <MDBModal isOpen={this.state.localUserUpdatePasswordSucess} size="md" className="model_top">
            <MDBModalHeader>{t('Success')}</MDBModalHeader>
            <MDBModalBody>
              Update password initiated successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.toggleLocalUserUpdatePasswordSuccess}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>          
          
          {/* sync local users success message popup  */}
          <MDBModal
            isOpen={this.state.syncSuccessModal}
            toggle={this.toggleSyncPopup}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.toggleSyncPopup}>
              {t('success')}
            </MDBModalHeader>
            <MDBModalBody>{this.state.syncSuccessMsg}</MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.toggleSyncPopup}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>

          {/* mqtt setup request intiniated success message popup  */}
          <MDBModal
            isOpen={this.state.mqttReqSuccessModal}
            toggle={this.toggleMqttReqPopup}
            size="md"
            className="model_top"
          >
            <MDBModalHeader toggle={this.toggleMqttReqPopup}>
              {t('success')}
            </MDBModalHeader>
            <MDBModalBody>{this.state.mqttReqSuccessMsg}</MDBModalBody>
            <MDBModalFooter>
              <button
                type="button"
                data-test="button"
                onClick={this.toggleMqttReqPopup}
                className="btn_primary"
              >
                {t('ok')}
              </button>
            </MDBModalFooter>
          </MDBModal>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>
          
          {this.state.isLoading ? (
            <Loader loader={this.state.isLoading} />
          ) : ("")}

        </main>
      </>
    );
  }
}


export default withTranslation()(Viewchargepoint)
