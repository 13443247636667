import React from 'react';
import ReactDOM from "react-dom";
import { MDBDataTable, MDBIcon, Select, MDBInput, MDBCol, MDBRow, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import { hasPermission } from '../auth';
//API Imports
import * as baseUrl from '../constants';
import servicesUrl from '../../common/servicesUrl';
import APICallUtility from '../../common/APICallUtility';
import ApiMethodTypes from '../../common/ApiMethodTypes';
import { getSecurityHeaders } from '../../common/HttpRequestClass';

import Status from './status';
import ChargerConnector from './chargerConnector';
import ChargingPower from './chargingPower';
import ChargingSessions from './chargingSessions';
import ComChargingPower from './comChargingPower';
import ReservationStatus from './reservationStatus';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chargingPowerFrequency: null,
      modalupgrade: false,
      isOpen: true,
      modal: false,
      isBannerVisible: true,
      userPackage: {},
      user: {
        permissions: []
      },
    }
    // this.closeUpgradeOption = this.closeUpgradeOption.bind();
  }

  //  api to get Reservation chart
  componentDidMount() {
    var userPackage = JSON.parse(localStorage.getItem("userPackage") || "[]");
    let tenant = localStorage.getItem('tenant');
    let user = this.state.user;
    user.permissions = localStorage.getItem("roleAccess");
    this.setState({
      userPackage: userPackage,
      user
    })
    this.getChargingPowerData();
    this.timerID = setInterval(() => this.combinedPowerInterval(), 1000 * this.state.chargingPowerFrequency);

    const isFirstTimeLogin = JSON.parse(localStorage.getItem("isFirstTimeLogin"));
    setTimeout(() => {
      if (!isFirstTimeLogin) {
        this.toggle();
        localStorage.setItem("isFirstTimeLogin", true);
      }
    }, 3000);
    // permission related code
    const bannerClose = JSON.parse(localStorage.getItem("upgradeBannerClosed"));
    if (bannerClose) {
      this.setState({ isBannerVisible: !this.state.isBannerVisible })
    }
  }

  combinedPowerInterval() {
    ReactDOM.render(<ComChargingPower />, document.getElementById('combinedChargingPower'));
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getChargingPowerData() {
    let url = baseUrl.URLPath + servicesUrl.getCombinedChargingPowerFrequency;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    this.setState({ chargingPowerFrequency: response });
  }

  closeUpgradeOption = () => {
    // const element = document.getElementById('upgrade');
    // element.remove();
    localStorage.setItem("upgradeBannerClosed", true);
    console.log(this.state.isBannerVisible);
    this.setState({ isBannerVisible: !this.state.isBannerVisible });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const { t } = this.props
    const user = this.state.user;
    return (
      <main className="content-div" >
        <div className="main-content-div">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="row">

                {(hasPermission(user, ['MySubscription_R']) && localStorage.getItem("tenant") === "webasto" && this.state.userPackage.identifier !== "Advanced") ?
                  this.state.isBannerVisible &&
                  <div className="col-md-12 mb-3" id="upgrade">
                    <div className="col">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/upgrade_icon.png"} />
                    </div>
                    <div className="col-7">
                      <h5 className="dblue_text">{t('upgrade_charging_experience')}</h5>
                      <p className="dc_text">{t('discover_webasto_subscription')}</p>
                    </div>
                    <div className="col-3">
                      <button className="btn btn-primary upgrade-btn" type="button" onClick={() => window.location.replace('mysubscription')}>{t('upgrade_now')}</button>
                    </div>
                    <div className="col">
                      <img className="banner-close-icon" src={process.env.PUBLIC_URL + "/assets/img/subscription/close.svg"} onClick={this.closeUpgradeOption} />
                    </div>
                  </div>
                  : ""}

                <div className="col-md-3 pr-0" >
                  <Status></Status>
                </div>
                <div className="col-md-3 pr-0 custom_module" >
                  <ChargingSessions></ChargingSessions>
                </div>
                <div className="col-md-3 pr-0" >
                  <ChargingPower></ChargingPower>
                </div>
                <div className="col-md-3 pr-0">
                  <ChargerConnector></ChargerConnector>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9 pr-0 " >
                  <div id="combinedChargingPower"></div>
                </div>
                <div className="col-md-3 pr-0 custom_module" >
                  <ReservationStatus></ReservationStatus>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(hasPermission(user, ['MySubscription_R']) && localStorage.getItem("tenant") === "webasto" && this.state.userPackage.identifier !== "Advanced") ?
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} className="custom_model_w model_no_borderradius" size="md" backdrop={true} wrapClassName="custom_backdropclass">
            <MDBModalBody className="text-center">
              <img className="upgrade-popup-close mb-3" src={process.env.PUBLIC_URL + "/assets/img/subscription/close.svg"} onClick={this.toggle} />
              <h6 className="mb-3 pt-4 dblue_text small-heading">{t('upgrade_charging_experience')}</h6>
              <img src={process.env.PUBLIC_URL + "/assets/img/subscription/upgrade_icon_lg.png"} className="mb-3" />
              <p className="upgrade-popup-ptext dc_text2 mb-3">{t('discover_webasto_subscription')}</p>
              <button onClick={() => window.location.replace('mysubscription')} type="button" className="btn btn-primary upgrade-btn-large mb-3">{t('upgrade_now')}</button>
            </MDBModalBody>
          </MDBModal>
          : ""}
      </main>
    )
  };
}

export default withTranslation()(Dashboard);

