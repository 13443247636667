import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBNav, MDBNavItem, MDBNavLink, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBIcon } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//API Imports
import * as baseUrl from './constants';
import servicesUrl from '../common/servicesUrl';
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isPackageAdvanced: false,
      userDetails: [],
      contactmodal: false,
      fields: { name: '', email: '', phonenumber: '', country: '', terminationreason: '' },
      errors: {},
      termsOfservice: false,
      dataPrivacy: false,
      isTop: true,
      successmodal: false,
      cancelmodal: false,
      userPackage: {},
      entryPackageDetails:[],
      advancedPackageDetails:[]
    }
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.getContactModal = this.getContactModal.bind(this);
    this.getCancelModal = this.getCancelModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleFirstCheckbox = this.handleFirstCheckbox.bind(this);
    this.handleSecondCheckbox = this.handleSecondCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  setSelectedTab(tabId, id) {
    if (this.state.selectedTab != tabId) {
      this.state.noWallboxExists = "";
    }
    this.setState({ ...this.state, selectedTab: tabId });
  }

  packages = () => {
    window.scrollTo({ top: 420, behavior: 'smooth' });
  }

  handleFirstCheckbox(e) {
    let errors = {};
    errors["termsagreeerror"] = "";
    this.setState({
      termsOfservice: e.target.checked,
      errors: errors
    });
  }
  handleSecondCheckbox(e) {
    let errors = {};
    errors["privacyagreeerror"] = "";
    this.setState({
      dataPrivacy: e.target.checked,      
      errors: errors
    });
  }

  getContactModal() {
    this.setState({ contactmodal: !this.state.contactmodal });
  }
  getCancelModal() {
    let errors = {};
    this.getUserDetails();
    this.setState({ cancelmodal: !this.state.cancelmodal,   errors: errors });
  }

  toggle = () => {
    let fields = this.state.fields;
    let errors = {};
    this.getUserDetails();
    this.setState({
      contactmodal: !this.state.contactmodal,
      errors: errors,
    });
  }

  async getAuthorizationDetails() {
    var url = baseUrl.URLPath + servicesUrl.getAuthorizationDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if (response) {
      localStorage.setItem("userPackage", JSON.stringify(response.userPackage));
    } else {
      console.log("Login Failed!");
    }
  }

  async getUserDetails() {
    var url = baseUrl.URLPath + servicesUrl.getUserDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let fields = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      // localStorage.setItem("tenant", response.tenant);
      // localStorage.setItem("UserTenant", response.tenant);
      // localStorage.setItem("userId", response.userId);
      // localStorage.setItem("email", response.email);
      // localStorage.setItem("tenant", response.tenant);
      // localStorage.setItem("role", response.role);
      // localStorage.setItem("enableFord", response.tenant);
    }
    fields['name'] = response.firstName
    fields['email'] = response.email
    fields['phonenumber'] = response.mobileNumber
    this.setState({ userDetails: response, fields: fields });
  }

  async getPackageDetails() {
    var url = baseUrl.URLPath + servicesUrl.getPackageDetails;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};
    let fields = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response.length !== 0) {
      let entryPackageDetails = [];
      let advancedPackageDetails = [];
      {response.map((element, index) => {
        if (element.identifier === "Advanced") {
          advancedPackageDetails = element;
        }else if(element.identifier === "Entry"){
          entryPackageDetails =element;
        }
      })}
      this.setState({ advancedPackageDetails, entryPackageDetails });
    }

  }


  

  componentDidMount() {   
    
    this.getUserDetails();
    this.getAuthorizationDetails();
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
    var userPackage = JSON.parse(localStorage.getItem("userPackage") || "[]");
    if (userPackage.identifier === "Advanced") {
      this.setState({
        userPackage: userPackage,
        isPackageAdvanced: true
      })
    } else {
      this.setState({
        userPackage: userPackage
      })
    }
  this.getPackageDetails();
  }

  changeHandler(field, e) {
    let errors = {};
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

    errors["nameempty"] = this.props.t('');
    errors["emailempty"] = this.props.t('');
    errors["phonenumberempty"] = this.props.t('');
    errors["countryempty"] = this.props.t('');
    errors["terminationreason"] = this.props.t('');
    this.setState({ errors: errors });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};

    var name = this.state.fields['name'];
    var email = this.state.fields['email'];
    var phonenumber = this.state.fields['phonenumber'];
    var country = this.state.fields['country'];

    if (name === '') {
      errors["nameempty"] = this.props.t('please_enter_name');
    }
    if (email === '') {
      errors["emailempty"] = this.props.t('please_enter_emailid');
    }
    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = this.props.t('enter_valid_email_id');
      }
    }
    if (phonenumber === '' || phonenumber === null || phonenumber === undefined) {
      errors["phonenumberempty"] = this.props.t('please_enter_phone_number');
    }
    if (phonenumber !== null && phonenumber !== '') {
      if (!phonenumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
        errors["validphoneNo"] = this.props.t('enter_10digit_mobile_no');
      }
    }
    if (country === '' || country === undefined) {
      errors["countryempty"] = this.props.t('please_enter_country');
    }
    if (this.state.termsOfservice === false) {
      errors["termsagreeerror"] = this.props.t('please_agree_to_continue');
    }
    if (this.state.dataPrivacy === false) {
      errors["privacyagreeerror"] = this.props.t('please_agree_to_continue');
    }

    else {
      if (errors.hasOwnProperty("nameempty") || errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("phonenumberempty") || errors.hasOwnProperty("validphoneNo") || errors.hasOwnProperty("countryempty") || errors.hasOwnProperty("termsagreeerror") || errors.hasOwnProperty("privacyagreeerror")) { }

      else {
        let url = baseUrl.URLPath + servicesUrl.updatePackage;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();

        //payload
        let payload = {
          "country": country,
          "email": email,
          "name": name,
          "phoneNumber": phonenumber,
          "userPackage": "Advanced"
        }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response?.message === "SUCCESS") {
          this.setState({
            addstatus: this.props.t('succees_condition'),
            addmsg: this.props.t('details_has_sent_successfully'),
            successmodal: !this.state.successmodal,
            contactmodal: !this.state.contactmodal,
            fields: '',
            errors: errors
          });
        } else {

        }
      }
    }
    this.setState({ errors: errors });
  }

  async submitHandler(event) {

    event.preventDefault();
    let errors = {};

    var name = this.state.fields['name'];
    var email = this.state.fields['email'];
    var terminationreason = this.state.fields['terminationreason'];

    if (name === '') {
      errors["noname"] = this.props.t('please_enter_name');
    }
    if (email === '') {
      errors["noemail"] = this.props.t('please_enter_emailid');
    }
    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entercorrectemail"] = this.props.t('enter_valid_email_id');
      }
    }
    if (terminationreason === '' || terminationreason === undefined) {
      errors["terminationreasonempty"] = this.props.t('please_enter_reason_for_termination');
    }
    if (this.state.termsOfservice === false) {
      errors["termsagreeerror"] = this.props.t('please_agree_to_continue');
    }
    if (this.state.dataPrivacy === false) {
      errors["privacyagreeerror"] = this.props.t('please_agree_to_continue');
    }

    else {
      if (errors.hasOwnProperty("noname") || errors.hasOwnProperty("noemail") || errors.hasOwnProperty("entercorrectemail") || errors.hasOwnProperty("terminationreasonempty") || errors.hasOwnProperty("termsagreeerror") || errors.hasOwnProperty("privacyagreeerror")) { }
      
      else {
        let url = baseUrl.URLPath + servicesUrl.cancelPackage;
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();

        //payload
        let payload = {
          "email": email,
          "name": name,
          "reasonForTermination": terminationreason,
          "currentPackage": "Advanced"
        }
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if (response?.message === "SUCCESS") {
          this.setState({
            addstatus: this.props.t('succees_condition'),
            addmsg: this.props.t('details_has_sent_successfully'),
            successmodal: !this.state.successmodal,
            cancelmodal: !this.state.cancelmodal,
            fields: '',
            errors: errors
          });
        } else {

        }
      }
    }
    this.setState({ errors: errors });
  }

  closetoggle = () => {
    this.setState({
      successmodal: !this.state.successmodal
    });
  }
  closeCancelPopup = () => {
    let fields = this.state.fields;
    let errors = {};

    fields.name = "";
    fields.email = "";
    fields.phonenumber = "";
    fields.terminationreason = "";

    this.setState({
      cancelmodal: !this.state.cancelmodal,
      errors: errors,
      fields
    });
  }

  render() {

    const { t } = this.props
    let isPackageAdvanced = this.state.isPackageAdvanced;
    let checkMark = <img src={process.env.PUBLIC_URL + "/assets/img/subscription/check.svg"} className="img-fluid rounded-start img-fluid rounded-start mb-1 pr-3" />;
    let cancelIcon = <img src={process.env.PUBLIC_URL + "/assets/img/subscription/cancel.svg"} className="img-fluid rounded-start img-fluid rounded-start mb-1 pr-3" />;

    return (
      <main class="content-div" >
        <div className="page-outerdiv packages" style={{ boxShadow: "none" }}>
          {!isPackageAdvanced ?
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <MDBRow>
                    <div className="container-fluid flex_center mb-4 mt-5">
                      <div className='col-5'>
                      <h3 className="pb15">{t('subscription_that_lets_use_webastochargeconnect_evenbetter')}</h3>
                      <p>{t('webastochargeconnect_premium_plan_is_seamless_charging_experience_you_use')}</p>
                      <div className='col-12 mt-4 flex_center'>
                      <button onClick={this.packages} type="button" className="btn view-plans-btn">{t('view_plans')}</button></div>                      
                      </div>
                      <div className='col-5'>                        
                        <img src={process.env.PUBLIC_URL + "/assets/img/subscription/subscription.png"} alt="logo" width="75%" style={{float: "right"}} />
                      </div>
                    </div>
                  </MDBRow>
                </div>
                <hr className='thickborder ml-3 mr-3' />
              </MDBCol>
            </MDBRow>
            :
            <MDBRow className="mb-4 advancedpackage pt-5" style={{ paddingBottom: "6rem" }}>
              <MDBCol sm="12">
                <div>
                  {/* <MDBRow>
                    <MDBCol md="12" className="text-center mb-5 mt-5">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/Webasto_logo.svg"} width="180" />
                    </MDBCol>
                  </MDBRow> */}
                  <MDBRow>
                    <MDBCol md="12 text-center">
                      <h6 className="pb-3">{t('your_plan')}</h6>
                      <h3 className="pb-3">{t('advanced')}</h3>
                      <div className="pt-3 pb-3">
                        <button onClick={this.getCancelModal} type="button" className="cancel-btn">{t('cancel_subscription')}</button>
                        <br />
                        <br />
                        <br />
                        <button onClick={this.packages} type="button" className="btn view-plans-btn pb-3">{t('packages_overview')}</button>
                      </div>

                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>}
          <MDBRow className="mb-4">
            <MDBCol sm="12">
              <MDBRow>
                <MDBCol md="12">
                  <h4 className="text-center mb-4 mt-3">{t('choose_your_subscription_plan')}</h4>
                  <MDBRow className="row subscriptions mb-4 mt-4 mr-1 ml-1 pb15">
                    <div className="col-md-4 custom-packages mb-3">
                      <div className="text-center" style={{ marginTop: "70px" }}>
                        <h4 className="pt-3 pb-3">{t('basic')}</h4>
                        <img src={process.env.PUBLIC_URL + "/assets/img/subscription/flag.svg"} className="img-fluid rounded-start mb-3" width="68" />
                        <div className="price">
                          <h4>{`${this.state.entryPackageDetails.price ? this.state.entryPackageDetails.price : 0} ${this.state.entryPackageDetails.currency ? this.state.entryPackageDetails.currency : ""}`}</h4>
                          <span>{t('per_charging_station_per_month')}</span>
                          {!isPackageAdvanced ?
                            <button disabled type="button" className="btn-primary current-btn">{t('current_plan')}</button> :
                            <button onClick={this.getCancelModal} type="button" className="btn-primary contactus-btn">{t('contact_us')}</button>}
                        </div>
                      </div>
                      <div className="plans text-left">
                        <p className='mt-3'>{t('1_group_per_user_account')}</p>
                        <p>{t('upto_5_charging_stations_in_this_group')}</p>
                        <p>{t('unlimited_number_of_members')}</p>
                        <ul style={{ listStyle: "none", marginLeft: "-30px" }} className='mt-3'>
                          <li>{checkMark}{t('control_of_charging_stations')}</li>
                          <li>{checkMark}{t('charging_station_mngmnt')}</li>
                          <li>{checkMark}{t('web_portal_analysis_cockpit')}</li>
                          <li>{checkMark}{t('single_group_management')}</li>
                          <li style={{ opacity: "0.5" }}>{cancelIcon}{t('multiple_group_management')}</li>
                          <li style={{ opacity: "0.5" }}>{cancelIcon}{t('partner_api_access')}</li>
                          <li>{checkMark}{t('overtheair_firmware_updates')}</li>
                          <li>{checkMark}{t('remote_support_by_webasto_hotline')}</li>
                          <li>{checkMark}{t('set_charging_costs_per_group')}</li>
                          <li>{checkMark}{t('export_of_charging_data_history')}</li>
                          <li style={{ opacity: "0.5" }}>{cancelIcon}{t('charging_through_vouchers')}</li>
                          <li style={{ opacity: "0.5" }}>{cancelIcon}{t('reservation_of_charging_stations')}</li>
                          <li style={{ opacity: "0.5" }}>{cancelIcon}{t('vehicle_drivers_logbook')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-1 d-none d-xl-block"></div>
                    <div className="col-md-4 custom-packages mb-3">
                      <div className="justify-content-center">
                        <div className="text-right mb-3 mt-4">
                          <img src={process.env.PUBLIC_URL + "/assets/img/subscription/best_value_bg.svg"} className="img-fluid rounded-start" width="120" style={{ marginRight: "-16px" }} />
                          <h6 className="best_value">{t('best_value')}</h6>
                        </div>
                        <div className="text-center">
                          <h4 className="pt-3 pb-3">{t('advanced')}</h4>
                          <img src={process.env.PUBLIC_URL + "/assets/img/subscription/crown.svg"} className="img-fluid rounded-start mb-3" width="68" />
                          <div className="price">
                            <h4>{`${this.state.advancedPackageDetails.price ? this.state.advancedPackageDetails.price :0} ${this.state.advancedPackageDetails.currency ? this.state.advancedPackageDetails.currency :""}`} </h4>
                            <span>{t('per_charging_station_per_month')}</span>
                            {isPackageAdvanced ?
                              <button disabled type="button" className="btn-primary current-btn">{t('current_plan')}</button> :
                              <button onClick={this.getContactModal} type="button" className="btn-primary contactus-btn">{t('contact_us')}</button>}
                          </div>
                        </div>
                        <div className="plans text-left">
                          <p className='mt-3'>{t('unlimited_groups')}</p>
                          <p>{t('unlimited_wallboxes_per_group')}</p>
                          <p>{t('unlimited_number_of_members')}</p>
                          <ul style={{ listStyle: "none", marginLeft: "-30px" }} className='mt-3'>
                            <li>{checkMark}{t('control_of_charging_stations')}</li>
                            <li>{checkMark}{t('charging_station_mngmnt')}</li>
                            <li>{checkMark}{t('web_portal_analysis_cockpit')}</li>
                            <li>{checkMark}{t('single_group_management')}</li>
                            <li>{checkMark}{t('multiple_group_management')}</li>
                            <li>{checkMark}{t('partner_api_access')}</li>
                            <li>{checkMark}{t('overtheair_firmware_updates')}</li>
                            <li>{checkMark}{t('remote_support_by_webasto_hotline')}</li>
                            <li>{checkMark}{t('set_charging_costs_per_group')}</li>
                            <li>{checkMark}{t('export_of_charging_data_history')}</li>
                            <li>{checkMark}{t('charging_through_vouchers')}</li>
                            <li>{checkMark}{t('reservation_of_charging_stations')}</li>
                            <li>{checkMark}{t('vehicle_drivers_logbook')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </MDBRow>
                </MDBCol>
              </MDBRow>

              {/* <hr className='thickborder ml-3 mr-3' /> */}
              {/* <div className="container-fluid pt-4 pb-4 pl-0" style={{display: "flex"}}> */}
              <MDBRow>
                <MDBCol md='12'>
                <div className='row mb-4 d-flex justify-content-center mr-3 '>
              
              <div className="col-sm-9 values mt-3">
                <h4 className="pb-3 text-center">{t('our_value_promise_to_you')}</h4>
                <div className="row">
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/flexible_plan.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('stay_flexible_with_our_subscription_plan')}</span>
                    </div>
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/latest_updates.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('be_the_first_to_get_the_latest_updates')}</span>
                    </div>
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/transparent_price.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('one_transparent_price_with_no_hidden_costs')}</span>
                    </div>
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/customer_support.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('excellent_customer_support_and_fast_availability')}</span>
                    </div>
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/price_drop.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('save_cost_and_time_with_our_premium_features')}</span>
                    </div>
                    <div className="col-sm-4 mb-3 text-center">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/business_model.svg"} className="img-fluid rounded-start mb-3" /><br />
                      <span>{t('extend_your_business_model')}</span>
                    </div>
                </div>
              </div>
             
            </div>
            <div className='row mb-4 d-flex justify-content-center mr-2'>
           

          <div className="col-sm-9 industries">
                <h6 className="pt-2 pb-0 small-heading">{t('a_solution_for_many_industries_and_charging_locations')}</h6>
                <MDBNav className="mb-4 mt-1 p-rel">
                  <MDBNavItem className="nav-first">
                    <MDBNavLink to="#" className={`pl-0 pr-3 ${this.state.selectedTab === 0 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 0)}><span><b>{t('workplace')}</b></span></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={`pl-4 pr-4 ${this.state.selectedTab === 1 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 1)}><span><b>{t('hospitality')}</b></span></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={`pl-4 pr-4 ${this.state.selectedTab === 2 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 2)}><span><b>{t('real_estate')}</b></span></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={`pl-3 pr-3 ${this.state.selectedTab === 3 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 3)}><span><b>{t('fleets')}</b></span></MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={`pl-4 pr-4 ${this.state.selectedTab === 4 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 4)}><span><b>{t('operators')}</b></span></MDBNavLink>
                  </MDBNavItem>
                </MDBNav>

                {
                  this.state.selectedTab === 0 &&
                  <MDBRow>
                    <MDBCol md="6">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/workplace.png"} className="img-fluid rounded-start" alt="" />
                    </MDBCol>
                    <MDBCol md="6">
                      <span>{t('our_premium_plan_gives_you_even_more_options_to_improve_your_employees_charging_experience')}</span>
                    </MDBCol>
                  </MDBRow>
                }
                {
                  this.state.selectedTab === 1 &&
                  <MDBRow>
                    <MDBCol md="6">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/hospitality.png"} className="img-fluid rounded-start" alt="" />
                    </MDBCol>
                    <MDBCol md="6">
                      <span>{t('optimize_the_charging_experience_of_your_customers_and_monetize_your_charging_sessions')}</span>
                    </MDBCol>
                  </MDBRow>
                }
                {
                  this.state.selectedTab === 2 &&
                  <MDBRow>
                    <MDBCol md="6">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/real_estate.png"} className="img-fluid rounded-start" alt="" />
                    </MDBCol>
                    <MDBCol md="6">
                      <span>{t('provide_residents_and_tenants_with_an_easytouse_and_affordable_charging_infrastructure')}</span>
                    </MDBCol>
                  </MDBRow>
                }
                {
                  this.state.selectedTab === 3 &&
                  <MDBRow>
                    <MDBCol md="6">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/fleets.png"} className="img-fluid rounded-start" alt="" />
                    </MDBCol>
                    <MDBCol md="6">
                      <span>{t('maintain_full_control_over_any_size_vehicle_fleet_in_multiple_locations')}</span>
                    </MDBCol>
                  </MDBRow>
                }
                {
                  this.state.selectedTab === 4 &&
                  <MDBRow>
                    <MDBCol md="6">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/operators.png"} className="img-fluid rounded-start" alt="" />
                    </MDBCol>
                    <MDBCol md="6">
                      <span>{t('explore_our_scalable_platform_for_efficient_and_flexible_charging_station_management')}</span>
                    </MDBCol>
                  </MDBRow>
                }
              </div>
         
            </div>
                </MDBCol>
              </MDBRow>
              
              {/* <hr className='thickborder ml-3 mr-3 mt-4 mb-4'/> */}
              <MDBRow>
                <MDBCol md="12">
                  <div className="mb-4 mt-3">
                    <h3 className="text-center">{t('manage_your_charging_stations_even_better')}</h3>
                    <h3 className="text-center">{t('with_the_webasto_chargeconnect_subscription')}</h3>
                  </div>

                  <div class="row justify-content-center align-items-center pt-4 pb-4">
                    <div class="col col-lg-4">
                    <img src={process.env.PUBLIC_URL + "/assets/img/subscription/multiple-groups.png"} width="85%" className="img-fluid rounded-start pl-1" />
                    </div>
                    <div class="col col-lg-5 ml-4">
                      <h4>{t('multiple_groups')}</h4>
                      <p>{t('expand_your_group_function_with_multiple_groups')}</p>
                      <p>{t('manage_and_keep_an_overview_of_your_locations')}</p>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center bg-light-grey pt-4 pb-4">
                    <div class="col col-lg-5">
                      <h4>{t('charging_station_management')}</h4>
                      <p>{t('manage_your_charging_stations_and_monitor_them_at_any_time')}</p>
                    </div>
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/charging_station_management.png"} className="img-fluid rounded-start img-right" />
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center pt-4 pb-4">
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/firmware_updates.png"} className="img-fluid rounded-start img-left" />
                    </div>
                    <div class="col col-lg-5">
                      <h4>{t('over_the_air_firmware_updates')}</h4>
                      <p>{t('install_firmware_updates_for_your_charging_stations_quickly_and_easily_via_remote_operation')}</p>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center bg-light-grey pt-4 pb-4">
                    <div class="col col-lg-5">
                      <h4>{t('data_integration_into_thirdparty_systems')}</h4>
                      <p>{t('webasto_provides_valuable_data_for_your_own_business_through_a_partner_api')}</p>
                    </div>
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/data_integration_into_thirdparty_systems.png"} className="img-fluid rounded-start img-right" />
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center pt-4 pb-4">
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/remote_service.png"} className="img-fluid rounded-start img-left" />
                    </div>
                    <div class="col col-lg-5">
                      <h4>{t('remote_service')}</h4>
                      <p>{t('webasto_support_has_qualified_staff_to_assist_you_if_you_have_any_trouble_using_the_hardware_or_the_cloud')}</p>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center bg-light-grey pt-4 pb-4">
                    <div class="col col-lg-5">
                      <h4>{t('charging_costs_per_group')}</h4>
                      <p>{t('you_can_set_a_charging_tariff_for_each_of_your_groups')}</p>
                    </div>
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/group_charging_costs.png"} className="img-fluid rounded-start img-right" />
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center pt-4 pb-4">
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/export_data.png"} className="img-fluid rounded-start img-left" />
                    </div>
                    <div class="col col-lg-5">
                      <h4>{t('exporting_charging_data')}</h4>
                      <p>{t('export_charging_data_any_time')}</p>
                      <p>{t('set_filters_for_time_periods_charging_stations_rfid_tags')}</p>
                      <p>{t('you_can_also_view_exact_charging_data_per_use')}</p>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center bg-light-grey pt-4 pb-4">
                    <div class="col col-lg-5">
                      <h4>{t('booking_of_charging_stations')}</h4>
                      <p>{t('guarantee_your_users_the_availability_of_a_charging_station')}</p>
                    </div>
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/booking_charge_stations.png"} className="img-fluid rounded-start img-right" />
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center pt-4 pb-4">
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/driver_logbook.png"} className="img-fluid rounded-start img-left" />
                    </div>
                    <div class="col col-lg-5">
                      <h4>{t('vehicle_driver_logbook')}</h4>
                      <p>{t('the_vehicle_id_and_the_current_mileage_are_important_key_information_for_your_fleet_vehicles')}</p>
                    </div>
                  </div>

                  <div class="row justify-content-center align-items-center bg-light-grey pt-4 pb-4 mb-5">
                    <div class="col col-lg-5">
                      <h4>{t('guest_access_through_vouchers')}</h4>
                      <p>{t('provide_your_guests_or_visitors_easy_access_to_your_charging_stations_using_a_QR_code')}</p>
                    </div>
                    <div class="col col-lg-4">
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/qr_code_vouchers.png"} className="img-fluid rounded-start img-right" />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>

              {this.state.isTop ? null : (
                <img src={process.env.PUBLIC_URL + "/assets/img/subscription/top_arrow.svg"}
                  onClick={this.scrollToTop}
                  className="btn-back-to-top" />
              )}


              <MDBModal isOpen={this.state.contactmodal} toggle={this.toggle} className="model_quickview model_no_borderradius" size="xl" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
                <MDBModalBody>
                  <MDBRow style={{ minHeight: "520px" }}>
                    <MDBCol md="5" className="skyblue-bg contactus-text">
                      <h4>{t('contact_us')}</h4>
                      <p>{t('thank_you_for_your_interest_in_the_webasto_chargeconnect_subscription')}</p>
                      <h5 className="pt-3">{t('what_happens_next')}</h5>
                      <div className='box'>
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/bullet_points_design.png"} width="" />
                      <div className="designlist">
                      <p>{t('our_team_will_respond_to_you_by_the_next_business_day')}</p>
                      <p>{t('we_will_discuss_your_requirements_and_find_the_perfect_solution')}</p>
                      <p>{t('lets_successfully_implement_the_transition_to_webastochargeconnect_subscription_together')}</p>
                      </div>
                      </div>
                    </MDBCol>
                    <MDBCol md="7">
                      <form className='needs-validation'>
                        <MDBRow className="pl-3 pr-3">
                          <MDBCol md="12" className="pt-4 pb-4" >
                            <MDBInput
                              value={this.state.fields["name"]}
                              name="name"
                              onChange={this.changeHandler.bind(this, "name")}
                              type="text"
                              id="name"
                              label={t('Name') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["nameempty"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4" >
                            <MDBInput
                              value={this.state.fields["email"]}
                              name="email"
                              onChange={this.changeHandler.bind(this, "email")}
                              type="text"
                              id="email"
                              label={t('E-mail') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></span>
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4" >
                            <MDBInput
                              maxLength={10}
                              minLength={10}
                              pattern="[1-9]{1}[0-9]{9}"
                              value={this.state.fields["phonenumber"]}
                              name="phonenumber"
                              onChange={this.changeHandler.bind(this, "phonenumber")}
                              type="text"
                              id="phonenumber"
                              label={t('Phone Number') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["phonenumberempty"]}</span></span>
                              <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["validphoneNo"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4" >
                            <MDBInput
                              value={this.state.fields["country"]}
                              name="country"
                              onChange={this.changeHandler.bind(this, "country")}
                              type="text"
                              id="country"
                              label={t('Country') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["countryempty"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4 pt-2">
                            <div id='checkboxs' className='mln-15'>
                              <div className="form-check ml-15 pb-3">
                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox1"
                                  onChange={this.handleFirstCheckbox.bind(this)} checked={this.state.termsOfservice}
                                  required />
                                <label className="form-check-label" htmlFor="checkBox1">{t('i_agree_to_the')}<a href={baseUrl.TERMSANDCONDITION_QR}  target="_blank" style={{ textDecoration: 'underline' }}>{t('terms_and_conditions')}</a></label>
                                <span className="error_msg w3-animate-top"><div style={{ color: "red", paddingTop: "10px" }}>{this.state.errors["termsagreeerror"]}</div></span>
                              </div>
                              <div className="form-check ml-15">
                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox2"
                                  onChange={this.handleSecondCheckbox.bind(this)} checked={this.state.dataPrivacy}
                                  required />
                                <label className="form-check-label" htmlFor="checkBox2">{t('i_agree_to_the')}<a href={baseUrl.DATAPRIVACYLINK_QR} target="_blank" style={{ textDecoration: 'underline' }}>{t('privacy_policy')}</a></label>
                                <span className="error_msg w3-animate-top"><div style={{ color: "red", paddingTop: "10px" }}>{this.state.errors["privacyagreeerror"]}</div></span>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                        <MDBModalFooter className="border-top-0 mt-2">
                          <button className="btn_cancel" onClick={this.toggle} type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
                          <button className="btn_primary" onClick={this.handleSubmit} data-test="button">{t('send')}</button>
                        </MDBModalFooter>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>

              <MDBModal isOpen={this.state.successmodal} toggle={this.closetoggle} size="md" className="model_top">
                <MDBModalHeader toggle={this.closetoggle}>{t('Success')}</MDBModalHeader>
                <MDBModalBody>
                  {this.state.addmsg}
                </MDBModalBody>
                <MDBModalFooter>
                  <button type="button" data-test="button" className="btn_primary" onClick={this.closetoggle}>{t('ok')}</button>
                </MDBModalFooter>
              </MDBModal>

              <MDBModal isOpen={this.state.cancelmodal} toggle={this.closeCancelPopup} className="model_quickview model_no_borderradius" size="xl" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
                <MDBModalBody>
                  <MDBRow style={{ minHeight: "520px" }}>
                    <MDBCol md="5" className="skyblue-bg contactus-text">
                      <h4>{t('cancel_subscription')}</h4><br/>                  
                      <div className='box'>
                      <img src={process.env.PUBLIC_URL + "/assets/img/subscription/bullet_points_design.png"} width="" />
                      <div className="designlist">
                      <p>{t('if_you_are_sure_you_want_to_cancel_your_subscription_please_fill_in_the_text_fields')}</p>
                      <p>{t('your_subscription_will_be_canceled_at_the_end_of_you_contracted_billing_period')}</p>
                      <p>{t('an_email_will_be_sent_to_the_webasto_support_team_and_your_account_will_be_downgraded')}</p>
                      </div>
                      </div>
                    </MDBCol>
                    <MDBCol md="7">
                      <form className='needs-validation'>
                        <MDBRow className="pl-3 pr-3">
                          <MDBCol md="12" className="pt-4 pb-4" >
                            <MDBInput
                              value={this.state.fields["name"]}
                              name="name"
                              onChange={this.changeHandler.bind(this, "name")}
                              type="text"
                              id="name"
                              label={t('Name') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["noname"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4" >
                            <MDBInput
                              value={this.state.fields["email"]}
                              name="email"
                              onChange={this.changeHandler.bind(this, "email")}
                              type="text"
                              id="email"
                              label={t('E-mail') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["noemail"]}</span></span>
                              <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entercorrectemail"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4" >
                            <MDBInput
                              value={this.state.fields["terminationreason"]}
                              name="terminationreason"
                              onChange={this.changeHandler.bind(this, "terminationreason")}
                              type="textarea"
                              id="terminationreason"
                              label={t('Reason for termination') + " *"}
                              required
                            >
                              <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["terminationreasonempty"]}</span></span>
                            </MDBInput>
                          </MDBCol>
                          <MDBCol md="12" className="pb-4 pt-2">
                            <div id='checkboxs' className='mln-15'>
                              <div className="form-check ml-15 pb-3">
                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox1"
                                  onChange={this.handleFirstCheckbox.bind(this)} checked={this.state.termsOfservice}
                                  required />
                                <label className="form-check-label" htmlFor="checkBox1">{t('i_agree_to_the')}<a href={baseUrl.TERMSANDCONDITION_QR}  target="_blank" style={{ textDecoration: 'underline' }}>{t('terms_and_conditions')}</a></label>
                                <span className="error_msg w3-animate-top"><div style={{ color: "red", paddingTop: "10px" }}>{this.state.errors["termsagreeerror"]}</div></span>
                              </div>
                              <div className="form-check ml-15">
                                <input type="checkbox" className="form-check-input cursor-p" id="checkBox2"
                                  onChange={this.handleSecondCheckbox.bind(this)} checked={this.state.dataPrivacy}
                                  required />
                                <label className="form-check-label" htmlFor="checkBox2">{t('i_agree_to_the')}<a href={baseUrl.DATAPRIVACYLINK_QR}  target="_blank" style={{ textDecoration: 'underline' }}>{t('privacy_policy')}</a></label>
                                <span className="error_msg w3-animate-top"><div style={{ color: "red", paddingTop: "10px" }}>{this.state.errors["privacyagreeerror"]}</div></span>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
                        <MDBModalFooter className="border-top-0 mt-2">
                          <button className="btn_cancel" onClick={this.closeCancelPopup} type="button" data-test="button"> {t('cancel')} <i className="fas fa-times ml-2"></i></button>
                          <button className="btn_primary" onClick={this.submitHandler} data-test="button">{t('send')}</button>
                        </MDBModalFooter>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>

              <MDBModal isOpen={this.state.successmodal} toggle={this.closetoggle} size="md" className="model_top">
                <MDBModalHeader toggle={this.closetoggle}>{t('Success')}</MDBModalHeader>
                <MDBModalBody>
                  {this.state.addmsg}
                </MDBModalBody>
                <MDBModalFooter>
                  <button type="button" data-test="button" className="btn_primary" onClick={this.closetoggle}>{t('ok')}</button>
                </MDBModalFooter>
              </MDBModal>
            </MDBCol>
          </MDBRow>
        </div>
      </main>
    );
  }
}
export default withTranslation()(Subscription);