/* eslint-disable no-useless-escape */
import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import * as baseUrl from './constants';
import * as Roles from './roles';
import * as GeneralUser from './GeneralUser';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import $ from "jquery";

//API Imports
import APICallUtility from '../common/APICallUtility';
import ApiMethodTypes from '../common/ApiMethodTypes';
import { getSecurityHeaders } from '../common/HttpRequestClass';
import servicesUrl from "../common/servicesUrl";

const history = createBrowserHistory();

class editUserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sucess1: false,
      colour: 'sucess',
      message: "",
      fields: { id: '', active: '', email: '', firstName: '', lastName: '', mobileNumber: '', organisation: '', role: '', employeeId: '', status: '', rfidTag: '', aliasName: '', language: '', userPackage: '' },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      dataArray: [],
      modalsession: false,
      roles: [],
      disabledAttr: true,
      isOpenEditProfileModal : false,
      multipleRfidArray: [
        {
          rfidTag: '', aliasName: '', tagType: 'other'
        }
      ],
      multipleRfidArrayAlias: [],
      availableSites: [],
      siteManagementsIds: [],
      selectedSites: [],
      listOfLanguages: [],
      listOfPackage: [],
      userOwnPackage: "",
      isServiceManagementActive: false,
      isOpenProfileModal : false,
      isSuccessPopupOpen : false,
      isServicePartnerNull : "",
      isDeactivePopupOpen:false,
      profileData : {serviceAccessCode : '',companyName : '', city:'', zipCode:'', address:'', country:'', telephone:'', email:'', websiteUrl:''},
      editProfile:false,
      isDeactiveSuccessPopupOpen : false,
    }
    // this.getLanguages = this.getLanguages.bind(this);
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.roleidChange = this.roleidChange.bind(this)
    this.organizationChange = this.organizationChange.bind(this)
    this.activeChange = this.activeChange.bind(this)
    this.getRoles = this.getRoles.bind(this)
    this.backToList = this.backToList.bind(this)
    this.confirmtoggle1 = this.confirmtoggle1.bind(this)
    this.addMultipleRfid = this.addMultipleRfid.bind(this)
    this.removeRfid = this.removeRfid.bind(this)
    this.viewUserDetails = this.viewUserDetails.bind(this)
    this.changeRfid = this.changeRfid.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this)
    this.getIsAllSitesSelected = this.getIsAllSitesSelected.bind(this);
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    // this.languageChange = this.languageChange.bind(this);
    this.packageLevelChange = this.packageLevelChange.bind(this);
    this.handleServiceManagement = this.handleServiceManagement.bind(this);
    this.toggleProfileModal = this.toggleProfileModal.bind(this);
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleCompanyNameError = this.handleCompanyNameError.bind(this);
    //this.handleTelephoneError = this.handleTelephoneError.bind(this);
    this.handleEmailError = this.handleEmailError.bind(this);
    this.handleSaveSMProfile = this.handleSaveSMProfile.bind(this);
    this.editServiceManagementProfile = this.editServiceManagementProfile.bind(this);
    this.toggleSuccessPopup = this.toggleSuccessPopup.bind(this);
    // this.toggleEditProfileModal = this.toggleEditProfileModal.bind(this);
    this.getAccessCodeWhenCheck = this.getAccessCodeWhenCheck.bind(this);
    this.handleSaveEditedSMProfile = this.handleSaveEditedSMProfile.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.toggleDeactivatePopup = this.toggleDeactivatePopup.bind(this);
    this.saveBtnDeactivatePopup = this.saveBtnDeactivatePopup.bind(this);
    this.toggleDeactivateSuccessPopup = this.toggleDeactivateSuccessPopup.bind(this);


  }

  toggleDeactivateSuccessPopup(){
    this.setState({isDeactiveSuccessPopupOpen : false, isDeactivePopupOpen: false, isServiceManagementActive: false})
    window.location.reload();
  }

  saveBtnDeactivatePopup(){
    //this.getAccessCodeWhenCheck(this.state.fields.id);
    this.DeactiveAccessCode(this.state.fields.id);
    this.setState({isDeactivePopupOpen : false, isServiceManagementActive:false})

  }
  toggleDeactivatePopup(){
    this.setState({isDeactivePopupOpen : false})
  }

  validateURL(e) {
    let errors = {}
    let websiteUrl = e.target.value;
    if(websiteUrl!=="" && websiteUrl!== null){
      //var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
      //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
      var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
      var var_name = pattern.test(websiteUrl);
      if(!var_name){
        errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        this.setState({errors : errors})
      }
      }
    }
  

  async editServiceManagementProfile(){
    let type = ApiMethodTypes.GET;
    var url = baseUrl.URLPath +servicesUrl.editServicePartner + "?userid="+ this.state.fields.id;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    
    let profileData ={address : response.address,
      serviceAccessCode : response.accessCode,
     city : response.city,
     companyName : response.companyName,
     country : response.country,
     email : response.email,
     telephone :response.telephone,
     websiteUrl : response.websiteURL,
     zipCode : response.zipCode};
     

    this.setState({isOpenProfileModal : true, profileData:profileData, editProfile:true, errors:{}})
  }

  async handleSaveEditedSMProfile(e){
    let errors = {}    
   e.preventDefault();
      //let telephone = this.state.profileData.telephone;
      let email = this.state.profileData.email;
      let websiteUrl = this.state.profileData.websiteUrl;

      // if (telephone !== '' && telephone !== null) {
        
      //   if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
      //     errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
      //   }
      // }
      if (email !== '' && email !== null) {
        if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
          errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
        }
      }
      if(websiteUrl!=="" && websiteUrl!== null){
        // var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
        // var var_name = urlregex.test(websiteUrl);
        // if(!var_name){
        //   errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        // }
        //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
        var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
        var var_name = pattern.test(websiteUrl);
        if(!var_name){
          errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
          this.setState({errors : errors})
        }
        }

      if(errors.hasOwnProperty("isemailEmpty") || errors.hasOwnProperty("iswebsiteUrlEmpty")){
        this.setState({errors: errors})
      }else{
        let type = ApiMethodTypes.PUT;
        var url = baseUrl.URLPath + servicesUrl.updateSMProfile + "?userid="+ this.state.fields.id;
        let headers = getSecurityHeaders();
        let payload = {
        "accessCode": this.state.profileData.serviceAccessCode,
        "companyName": this.state.profileData.companyName,
        "city": this.state.profileData.city,
        "zipCode": this.state.profileData.zipCode,
        "address": this.state.profileData.address,
        "country": this.state.profileData.country,
        "telephone": this.state.profileData.telephone,
        "email": this.state.profileData.email,
        "websiteURL": this.state.profileData.websiteUrl,
      };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
  
      if(response.message==="service_partner_update_success"){
        this.setState({ 
          isSuccessPopupOpen : true});
      
      }
      }
        
      
     

  }

  // toggleEditProfileModal(){
  //   this.setState({isOpenEditProfileModal : false})
  // }

  toggleSuccessPopup(){
    this.setState({isSuccessPopupOpen : false, isOpenProfileModal:false, isOpenEditProfileModal:false})
  }
  async handleSaveSMProfile(e){
    let errors = {}
    e.preventDefault();
    var companyName = this.state.profileData.companyName;
    if(companyName === "" || companyName===null){
      errors["iscompanyNameEmpty"] = this.props.t('please_enter_companyName');
      this.setState({errors : errors})
    }
    else{
      //let telephone = this.state.profileData.telephone;
      let email = this.state.profileData.email;
      let websiteUrl = this.state.profileData.websiteUrl;

      // if (telephone !== '') {
        
      //   if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
      //     errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
      //   }
      // }
      if (email !== '') {
        if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
          errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
        }
      }
      if(websiteUrl!=="" && websiteUrl!== null){
        // var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
        // var var_name = urlregex.test(websiteUrl);
        // if(!var_name){
        //   errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
        // }
        //var pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;
        var pattern = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/ ;
        var var_name = pattern.test(websiteUrl);
        if(!var_name){
          errors["iswebsiteUrlEmpty"] =this.props.t('enter_a_valid_url');
          this.setState({errors : errors})
        }
        }

      if(errors.hasOwnProperty("isemailEmpty") || errors.hasOwnProperty("iswebsiteUrlEmpty")){
        this.setState({errors: errors})
      }else{
        let type = ApiMethodTypes.POST;
        var url = baseUrl.URLPath +servicesUrl.saveSMProfile + "?userid="+ this.state.fields.id;
        let headers = getSecurityHeaders();
      let payload = {
        "accessCode": this.state.profileData.serviceAccessCode,
    "address": this.state.profileData.address,
    "city":this.state.profileData.city,
    "companyName":this.state.profileData.companyName,
    "country": this.state.profileData.country,
    "email": this.state.profileData.email,
    "telephone": this.state.profileData.telephone,
    "websiteURL": this.state.profileData.websiteUrl,
    "zipCode": this.state.profileData.zipCode
      };
      let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
      if(response.message==="service_partner_create_success"){
       
      this.setState({ 
          isSuccessPopupOpen : true, isServicePartnerNull:true});
      }
      } 
    }

    }

  handleEmailError(e){
    let errors = {}
    let email = e.target.value
    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["isemailEmpty"] = this.props.t('enter_valid_email_id');
      }
    }else{
      errors["isemailEmpty"] = "";
    }
    this.setState({errors : errors})

  }

  // handleTelephoneError(e){
  //   let errors = {}
  //   let telephone = e.target.value
  //   if (telephone !== '') {
      
  //     if(!telephone.match(/^(?:\+[\d\s-]{1,18}|[\d\s-]{1,18})$/)){
  //       errors["istelephoneEmpty"] = this.props.t("enter_valid_telephone_no");
  //     }
  //   }
  //   else{
  //     errors['validTelephone'] = '';
  //   }
  //   this.setState({errors : errors})

  // }

  handleCompanyNameError(e){
    let errors={}
    if (e.target.value===""){
      errors["iscompanyNameEmpty"] = this.props.t('please_enter_companyName');
    }
    else{
      errors["iscompanyNameEmpty"] = ""
    }
    this.setState({errors : errors})
  }

  handleFormInputChange(e){
    const { name, value } = e.target;
    let errors = {}
    
      this.setState((prevState) => ({
        profileData: { ...prevState.profileData, [name]: value, errors : errors},
      }));
    
  }
  
  handleServiceManagement(){
      //this.setState({isServiceManagementActive : true})
    
    if(this.state.isServiceManagementActive === true){
      this.setState({isDeactivePopupOpen : true})
    }else{
      this.getAccessCodeWhenCheck(this.state.fields.id);
    this.setState((prevState)=>({isServiceManagementActive : !prevState.isServiceManagementActive}))

    if(this.state.isServicePartnerNull===false){
      this.setState({isOpenProfileModal:true, errors:{}})
    }
    }
  }

  

  toggleProfileModal(){
    let profileData = {companyName : '', city:'', zipCode:'', address:'', country:'', telephone:'', email:'', websiteUrl:''}
      // this.setState({
      //   isServiceManagementActive: this.state.isServiceManagementActive, isOpenProfileModal : false,
      // profileData : profileData}) 
      if(!this.state.isServiceManagementActive){
        this.setState({
          isServiceManagementActive: false, isOpenProfileModal : false,
        profileData : profileData})
        
      }else{
        if(this.state.isServicePartnerNull)
          {
        this.setState({
          isServiceManagementActive: true, isOpenProfileModal : false,
        profileData : profileData})
          }
          else{
            this.setState({
              isServiceManagementActive: false, isOpenProfileModal : false,
            profileData : profileData})
          }
        
      }
  }

  // async getLanguages() {
  //   let url = baseUrl.URLPath + 'admin/v1.0/account/languages/preferred';
  //   let type = ApiMethodTypes.GET;
  //   let headers = getSecurityHeaders();
  //   let payload = {};

  //   let result = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

  //   if (result) {
  //     this.setState({
  //       listOfLanguages: result
  //     })
  //   }
  // }

  // languageChange() {
  //   let selectedLanguage = document.getElementById('language').value;
  //   let fields = this.state.fields;
  //   fields['language'] = selectedLanguage;

  //   this.setState({
  //     fields
  //   })
  // }

  changeRfid(index, field, e) {
    let tempArray = [...this.state.multipleRfidArrayAlias];
    tempArray[index][field] = e.target.value;
    this.setState({ multipleRfidArrayAlias: tempArray });
    this.setState({ disabledAttr: "" });
    if (field === "rfidTag") {
      $(`.rfidTag_${index}`).text("");
      $('.rfidexists').text("");
    } else if (field === "aliasName") {
      $(`.aliasName_${index}`).text("");
    }
    $('.empty_rfids').text("");
  }

  onShowAlert() {
    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false })
      }, 5000)
    });
  }

  addMultipleRfid() {
    this.setState({
      multipleRfidArrayAlias: [...this.state.multipleRfidArrayAlias, { rfidTag: "", aliasName: "", tagType: "other" }]
    })
  }

  removeRfid(index) {
    let data = this.state.multipleRfidArrayAlias

    // remove object
    data.splice(index, 1);
    this.setState({
      multipleRfidArrayAlias: data,
      disabledAttr: ""
    });

    let lookup = this.state.multipleRfidArrayAlias.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArrayAlias.filter(e => lookup[e.rfidTag]);

    if (duplicateCount.length === 0) {
      $('.rfidexists').text("");
    }
  }

  confirmtoggle1() {
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
  }

  backToList() {
    this.props.history.push('/userManagementController');
  }

  roleidChange() {
    var x = document.getElementById("role").value;
    let fields = this.state.fields;
    fields["role"] = x;
    this.setState({ fields });
  }

  tagOk() {
    this.props.history.push('/userManagementController');
    window.location.reload();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  addNew() {
    this.props.history.push('/Userlist');
  }

  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value === "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    if (field === "employeeId") {
      $('.employeeidexists').text("");
    }
    this.setState({ fields, disabledAttr: "" });
  }

  activeChange = () => {
    var x = document.getElementById("myCheck").checked;
    let fields = this.state.fields;
    if (x) {
      fields.status = true;
    } else {
      fields.status = false;
    }
    this.setState({ fields, disabledAttr: "" });
  }

  isHaveAlphabetic(string) {
    if (/[a-zA-Z]/.test(string) === true) {
      return true;
    } else {
      return false;
    }
  }

  isLengthAllowed(string) {
    if (string.length <= 20) {
      return true;
    } else {
      return false;
    }
  }

  isLengthAllowed128(string) {
    if (string.length <= 128) {
      return true;
    } else {
      return false;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    let errors = {};
    let validFreeCharging = 0;
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
    if (this.state.fields.role !== "WEBASTO_SUPER_ADMIN_DEVOPS" && this.state.fields.role !== "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" && this.state.fields.role !== "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
      this.state.multipleRfidArrayAlias.forEach((element, index) => {
        if (element.rfidTag !== "" && element.rfidTag !== null) {
          if (!element.rfidTag.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {
            errors["rfidempty"] = this.props.t('please_enter_valid_rfid_tag');
            $(`.rfidTag_${index}`).text(this.props.t('please_enter_valid_rfid_tag'));
          } else {
            $(`.rfidTag_${index}`).text("");
          }
        } else {
          $(`.rfidTag_${index}`).text(this.props.t('please_enter_rfid_tag'))
        }

        if (element.rfidTag === "0815" && element.rfidTag !== null && element.rfidTag !== "") {
          $(`.rfidTag_${index}`).text(this.props.t('rfid_tag_reserved_for_free_charging'));
          validFreeCharging = 1;
        }

        if (element.aliasName !== "" && element.aliasName !== null) {
          if (!element.aliasName.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) { }
          else {
            $(`.aliasName_${index}`).text("");
          }
        } else {
          $(`.aliasName_${index}`).text(this.props.t('please_enter_alias_name'))
        }
      })
    }

    var email = this.state.fields['email'];
    var organisation = this.state.fields['organisation'];
    var firstName = this.state.fields['firstName'];
    var lastName = this.state.fields['lastName'];


    var role = this.state.fields['role'];
    var rfid = this.state.fields['rfid'];


    const siteManagementsIds = this.state.selectedSites;

    if (email === '') {
      errors["emailempty"] = this.props.t('enter_email');
    }

    if (email !== '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = this.props.t('enter_valid_email_id');
      }
    }

    if (organisation === '') {
      errors["organisationempty"] = this.props.t('please_select_organization');
    }

    if (firstName === '') {
      errors["firstName"] = this.props.t('enter_first_name');
    }

    if (lastName === '') {
      errors["lastName"] = this.props.t('enter_last_name');
    }

    if (role === "") {
      errors["role"] = this.props.t('please_select_role');
    }

    if (role === Roles.WEBASTO_END_USER_COMMERCIAL && rfid === "") {
      errors["rfidempty"] = this.props.t('please_enter_rfid_tag')
    }

    if (validFreeCharging === 1 || errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("role") || errors.hasOwnProperty("employeeId") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("rfidempty")) { }
    else {
      let userRole = localStorage.getItem('role');
      var url = "";
      if (userRole === Roles.WEBASTO_CUSTOMER_ADMIN || userRole === Roles.WEBASTO_SITE_ADMIN) {
        url = TenantUser.UPDATE_USER + this.state.fields.id;
      } else if (userRole === Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || userRole === Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
        url = PlatformUser.UPDATE_USER + this.state.fields.id;
      } else if (userRole === Roles.WEBASTO_END_USER_RESIDENTIAL || userRole === Roles.WEBASTO_END_USER_COMMERCIAL) {
        url = GeneralUser.UPDATE_USER + this.state.fields.id;
      }

      let data = { "email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "employeeId": this.state.fields.employeeId, "organisation": this.state.fields.organisation, "status": this.state.fields.status, "rfids": this.state.multipleRfidArrayAlias, siteManagementsIds, "preferredLanguage": this.state.fields.language, "userPackage": this.state.fields.userPackage };
      if (role === Roles.WEBASTO_END_USER_COMMERCIAL && this.state.multipleRfidArrayAlias.length === 0) {
        $(".empty_rfids").text(this.props.t('commercial_atleast_one_rfid_be_present'));
      } else {
        document.getElementById("loader_image_div").style.display = "block";
        let type = ApiMethodTypes.PUT;
        let headers = getSecurityHeaders();


        let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t, true);

        if (response) {
          this.hideLoder();
          if (response.status === "SUCCESS") {
            this.toggle();
            if (localStorage.getItem('email') === this.state.fields.email) {
              localStorage.setItem('user', this.state.fields.firstName);
            }
            $('.employeeidexists').text("");
            if (localStorage.userId === this.state.fields.id) {
              if (this.state.fields.language === "French") {
                this.props.i18n.changeLanguage('fr');
                localStorage.preferredLanguage = 'French'
              } else if (this.state.fields.language === "English") {
                this.props.i18n.changeLanguage('en')
                localStorage.preferredLanguage = 'English'
              }
              localStorage.setItem("localSelectedLng", "");
            }
          } else if (response.status === "FAILURE" && response.errorCode === 3104 && response.errorMessage === 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists_with_email')
            this.setState({
              errors: errors
            });
          } else if (response.status === "FAILURE" && response.errorCode === 3123 && response.errorMessage === 'rfid_tag_issue') {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;

            if (rfids.errorReason === 'rfid_is_already_assigned') {
              $('.rfidexists').text(this.props.t('rfid_is_already_assigned'));
            }
            if (rfids.errorReason === 'duplicate_rfid_tag') {
              $('.rfidexists').text(this.props.t('duplicate_rfid_tag'));
            }
            if (rfids.errorReason === "alias_name_should_not_be_empty" || rfids.errorReason === "rfid_tag_should_not_be_empty") {
              $('.rfidexists').text("");
            }
          } else if (response.status === "FAILURE" && response.errorCode === 3124 && response.errorMessage === 'employeeid_is_already_exists') {
            $('.employeeidexists').text(this.props.t('employeeId_already_exists'));
          } else { }

          this.setState({
            errors: errors
          });
        }
      }
    }
    this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  organizationChange() {
    var x = document.getElementById("organisation").value;
    let fields = this.state.fields;
    fields["organisation"] = x;
    this.setState({ fields });
  }

  async getEditUserData() {
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ dataArray: response.organisationDTOS })
    }
  }

  componentDidMount() {
    //document.getElementById("myCheck").checked = true;
    this.getRoles();
    this.getEditUserData();
    this.viewUserDetails();
    // this.getLanguages();
    //this.handleSMCheckbox();
    
    


  }
  
  async getAccessCodeWhenCheck(id){
    let type = ApiMethodTypes.GET;
    var url = baseUrl.URLPath +servicesUrl.getAccessCode + "?userid="+ id;
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
   
  //  this.setState((prevState) => ({
  //   profileData: {
  //     ...prevState.profileData, 
  //     serviceAccessCode: response.accessCode,
  //   }
  // }));
  if (response){
    let dataFromResponse = {
      serviceAccessCode : response.accessCode ? response.accessCode :"" ,
      companyName : response.companyName ? response.companyName :"" ,
      city : response.city ? response.city :"" ,
      zipCode : response.zipCode ? response.zipCode :"" ,
      address : response.address ? response.address :"" ,
      country : response.country ? response.country :"" ,
      telephone : response.telephone ? response.telephone :"" ,
      email : response.email ? response.email :"" ,
      websiteUrl : response.websiteURL ? response.websiteURL :"" ,
    }
    if(response.companyName){
      this.setState({profileData : dataFromResponse, editProfile:true})
    }else{
      this.setState({profileData : dataFromResponse})
    }
    
  }
  

  }

  async DeactiveAccessCode(id){
    let type = ApiMethodTypes.PUT;
    var url = baseUrl.URLPath +servicesUrl.deactiveSMProfile + "?userid="+ id +"&status=false";
    let headers = getSecurityHeaders();
    let payload = {};
    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    if(response.message === "service_partner_deactivated"){
      this.setState({isDeactiveSuccessPopupOpen: true})
    }
   // this.setState({serviceAccessCode: accessCode});
  //  this.setState((prevState) => ({
  //   profileData: {
  //     ...prevState.profileData, 
  //     serviceAccessCode: response.accessCode,
  //   }
  // }));

  }

  

  async viewUserDetails() {
    var userDetails = this.props.location.state;
    let role = localStorage.getItem('role');

    var url = "";
    if (role === Roles.WEBASTO_CUSTOMER_ADMIN || role === Roles.WEBASTO_SITE_ADMIN) {
      url = TenantUser.GET_USER_DETAILS + userDetails;
    } else if (role === Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role === Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      url = PlatformUser.GET_USER_DETAILS + userDetails;
    } else if (role === Roles.WEBASTO_END_USER_RESIDENTIAL || role === Roles.WEBASTO_END_USER_COMMERCIAL) {
      url = GeneralUser.GET_USER_DETAILS + userDetails;
    }

    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      var data1 = response.userDTOS;
      var data2 = response.rfids;
      let fields = this.state.fields;
    var checkServiceManagementActive = response.userDTOS[0].partnerService;
    var temp = response.userDTOS[0].partnerService === null || response.userDTOS[0].partnerService === false ? false : response.userDTOS[0].partnerService;
      fields = data1[0];
      fields['language'] = data1[0].preferredLanguage;
      this.setState({ fields, multipleRfidArrayAlias: data2, siteManagementsIds: data1[0].siteManagementsIds, userOwnPackage: data1[0].userPackage, isServiceManagementActive:temp, isServicePartnerNull:checkServiceManagementActive  }, () => {
        this.getAllAvailableSites();
        //this.getAccessCodeWhenCheck(fields['id'], response.userDTOS[0].isServicePartner);
        if (data1[0].tenant === "webasto") {
          this.getPackages(data1[0].tenant);
        }
      });
      
      
    }
  }

  async getRoles() {
    var url = baseUrl.URLPath + "roles/";
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    let fields = this.state.fields;
    fields.status = true;
    if (response !== undefined) {
      this.setState({ roles: response.roles })
    }
    document.getElementById("role").value = this.state.fields.role;
  }
  getIsAllSitesSelected() {
    const { availableSites, selectedSites } = this.state;
    let isAllChecked = false;
    if (availableSites && selectedSites) {
      for (let i = 0; i < availableSites.length; i++) {
        if (selectedSites.some(e => e === availableSites[i].id) === true) {
          isAllChecked = true;
        } else {
          isAllChecked = false;
          break;
        }
      }
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }
  onUpdateSiteSelectAll(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      var managementsIds = [];
      this.state.availableSites.forEach((site, index) => {
        managementsIds = [...managementsIds, site.id];
      });
      this.setState({ selectedSites: managementsIds });
    } else {
      this.setState({ selectedSites: [] });
    }
  }
  onSelectSite(event, el) {
    const isChecked = event.target.checked;
    var managementsIds = [];
    managementsIds = this.state.selectedSites;
    if (isChecked) {
      managementsIds = [...managementsIds, el.id];
    } else {
      const index = managementsIds.indexOf(el.id);
      if (index > -1) {
        managementsIds.splice(index, 1);
      }
    }
    this.setState({ selectedSites: managementsIds });
  }
  async getAllAvailableSites() {
    let orgId = localStorage.getItem('orgId');

    let url = `${baseUrl.URLPath}sitemanagement/usersites?orgId=` + orgId + '&userId=' + this.state.fields.id;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response) {
      debugger
      let beforeSort = response;
      let afterSort = [];
      let names = [];
      beforeSort.forEach((item, index) => {
        names.push(item.name);
      });
      names = names.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      names.forEach((site, index) => {
        beforeSort.forEach((itm, index) => {
          if (itm.name === site) {
            afterSort.push(itm);
          }
        });
      });

      let selectedsites = this.state.selectedSites;
      beforeSort.forEach((sit, ii) => {
        this.state.siteManagementsIds.forEach((Id, j) => {
          if (sit.id === Id) {
            selectedsites.push(Id);
          }
        });
      });

      this.setState({ ...this.state, availableSites: beforeSort, selectedSites: selectedsites }, () => {
        console.log("selected: " + this.state.selectedSites);
      });
    }
  }

  // onchange of package level 
  packageLevelChange(e) {
    let fields = this.state.fields;
    fields["userPackage"] = e.target.value;
    this.setState({ fields }, () => {
    });
  }

  // get all packages 
  async getPackages(orgIdentifier) {
    var url = baseUrl.URLPath + "platform/user/packages?tenantId=" + orgIdentifier;
    let type = ApiMethodTypes.GET;
    let headers = getSecurityHeaders();
    let payload = {};

    let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

    if (response !== undefined) {
      this.setState({ listOfPackage: response }, () => {
      })
    }
  }

  render() {
    var statusOfUser = this.state.fields.status;
    const { t } = this.props
    var getRole = localStorage.getItem("role");
    let editCommercialAndResidential = false;
    let isCommercialAndResidential = false;
    let editCommercialAndResidentialAndSiteAdmin = false;
    let isCommercialAndResidentialAndSiteAdmin = false;
    let userPackageOrder = 0;
    if (this.state.listOfPackage) {
      this.state.listOfPackage.forEach((item, j) => {
        if (item.identifier === this.state.userOwnPackage) {
          userPackageOrder = item.packageOrder
        }
      });
    }

    if (getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole === "WEBASTO_CUSTOMER_ADMIN" ||
      getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ||
      getRole === "WEBASTO_SITE_ADMIN") {
      editCommercialAndResidential = true;
    }

    if (this.state.fields.role === "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL") {
      isCommercialAndResidential = true;
    }

    if (getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole === "WEBASTO_CUSTOMER_ADMIN" ||
      getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
      editCommercialAndResidentialAndSiteAdmin = true;
    }

    if (this.state.fields.role === "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL" || this.state.fields.role === "WEBASTO_SITE_ADMIN") {
      isCommercialAndResidentialAndSiteAdmin = true;
    }

    const packageList = this.state.listOfPackage?.map((data, index) => {
      // let disabledVal = false;
      // if (userPackageOrder > data.packageOrder) {
      //   disabledVal = true;
      // }
      return (
        <option value={data.identifier} data-order={data.packageOrder} > {data.packageName}</option>
      )
    })

    return (
      <main className="content-div edit-USer">
        <p>{t('edit_user')}
          <div className="breadcrumb_div">
            {t('Settings')} &gt; <Link to="/userManagementController"><span>{t('Users')} </span></Link> &gt; {t('edit_user')}
          </div>
        </p>
        <div className="page-outerdiv">
          <MDBRow className="mb-2">
            <MDBCol sm="12">
              <button type="button" className="btn_primary mt-1" onClick={this.backToList}><i className="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="12">
              <div>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  <MDBRow>
                    <div className="col-md-12 mb-3">
                      <span className="form-title">{t('user_details')}</span>
                    </div>
                    <MDBCol md="4">
                      <MDBInput disabled
                        value={this.state.fields["email"]}
                        onChange={this.changeHandler.bind(this, "email")}
                        type="text"
                        id="email"
                        name="Email"
                        label={t('e_mail')}
                        className="text_lowercase"
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validemail"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["firstName"]}
                        onChange={this.changeHandler.bind(this, "firstName")}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={t('firstName')}
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["firstName"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["lastName"]}
                        onChange={this.changeHandler.bind(this, "lastName")}
                        type="text"
                        id="lastName"
                        name="lastName"
                        label={t('lastName')}
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["lastName"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2" >
                      <MDBInput
                        value={this.state.fields["mobileNumber"]}
                        onChange={this.changeHandler.bind(this, "mobileNumber")}
                        type="text"
                        id="mobileNumber"
                        name="mobileNumber"
                        label={t('mobile_no')}
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                      </MDBInput>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mobileNumber"]}</span></span>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validphoneNo"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2"  >
                      <select disabled className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} value={this.state.fields.role}>
                        <option value=""> -- {t('select_role')} --</option>
                        <option value={this.state.fields.role}>{this.state.fields.role}</option>
                      </select>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["role"]}</span></span>
                    </MDBCol>
                    {/* <MDBCol md="4" className="mt-2"  >
                      <select className="browser-default custom-select select_height mb_8" id="language" onChange={this.languageChange.bind(this)} value={this.state.fields.language} disabled={!(localStorage.userId === this.state.fields.id)}>
                        <option value=""> -- {t('select_language')} --</option>
                        {
                          this.state.listOfLanguages?.map((language, index) => {
                            return <option key={index}>{language}</option>
                          })
                        }
                      </select>
                    </MDBCol> */}
                    <MDBCol md="4" className="mt-2" >
                      <MDBInput
                        type="text"
                        value={this.state.fields["employeeId"]}
                        onChange={this.changeHandler.bind(this, "employeeId")}
                        name="employeeId"
                        label={t('employee_number_optional')}
                        id="employeeId"
                      >
                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }} className="employeeidexists"></span></span>
                      </MDBInput>
                    </MDBCol>
                    {( (getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" || getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ) && this.state.fields.tenant === "webasto" && (this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL")) &&
                      <MDBCol md="4" className="mt-2">
                        <label class="pull-left mr-1 mt-2 pt-1 code_clr pl-0 mt-2">
                          {t('change_package_level')} *
                        </label>
                        <select className="browser-default custom-select select_height w_53 indent pull-right" id="packageLevel" onChange={this.packageLevelChange.bind(this)} value={this.state.fields.userPackage}>
                          {packageList}
                        </select>
                      </MDBCol>
                    }
                    <MDBCol md="4" className="mt-4">
                      <Form.Check
                        custom
                        checked={statusOfUser}
                        onChange={this.activeChange}
                        type="checkbox"
                        id="myCheck"
                        label={t('active')}
                      />
                    </MDBCol>
                    {((getRole === "WEBASTO_SUPER_ADMIN_DEVOPS" ||  getRole === "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE")
                    && this.state.fields.tenant === "webasto" && (this.state.fields.role === "WEBASTO_END_USER_RESIDENTIAL")) &&
                      <MDBCol md="4" className="mt-4 ml-0 flex-row-align-center pl-1 custom-checkbox" >
                        <Form.Check
                        custom
                        checked={this.state.isServiceManagementActive}
                        onChange={this.handleServiceManagement}
                        type="checkbox"
                        id="myCheckId"
                        className='activation-sm-style'
                        label={t('activation_service_management')}
                      />
                      {this.state.isServiceManagementActive && 
                                              <i class="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Edit" onClick={this.editServiceManagementProfile}></i>
                                            }
                      </MDBCol>
                    }
                    
                    {
                      ((isCommercialAndResidential && editCommercialAndResidential) || (isCommercialAndResidentialAndSiteAdmin && editCommercialAndResidentialAndSiteAdmin)) && this.state.availableSites && this.state.availableSites.length > 0 &&
                      <><div className="col-md-12 mt-2">
                        <div className="d-flex align-items-center">
                          <span className="form-title border-0 mt-0">{t('group_access')}</span>
                          <Form.Check
                            custom
                            type="checkbox"
                            id="siteAccessCheck"
                            label={t('all')}
                            className="ml-5"
                            checked={this.getIsAllSitesSelected()}
                            onChange={this.onUpdateSiteSelectAll}
                          />
                        </div>
                      </div>
                        <MDBCol md="10">
                          <MDBRow>
                            {
                              this.state.availableSites?.map((el, index) => {
                                let disabledChk = "";
                                let checked = false;
                                if (this.state.selectedSites.some(e => e === el.id) === true) {
                                  checked = true
                                }

                                if (el?.owner === true) {
                                  disabledChk = true;
                                }

                                return (
                                  <MDBCol md="3" key={index}>
                                    <Form.Check
                                      custom
                                      type="checkbox"
                                      id={`siteAccess_siteName_${el.id}`}
                                      label={el.name}
                                      checked={checked}
                                      disabled={disabledChk}
                                      onChange={(event) => this.onSelectSite(event, el)}
                                    />
                                  </MDBCol>
                                );
                              }
                              )
                            }
                          </MDBRow>
                          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["siteAccess"]}</span></span>
                        </MDBCol>
                      </>
                    }
                    {(this.state.fields.role !== "WEBASTO_SUPER_ADMIN_DEVOPS" && this.state.fields.role !== "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" && this.state.fields.role !== "WEBASTO_CUSTOMER_ADMIN_READ_ONLY") &&
                      <>
                        <MDBCol md="12" className="mt-4">
                          <i className="fa fa-plus-circle mr-2" aria-hidden="true" onClick={this.addMultipleRfid} style={{ fontSize: "18px" }}></i><h>{t('additional_rfid_tag')}</h>

                        </MDBCol>
                        {
                          this.state.multipleRfidArrayAlias.map((multipleRfidArrayAlias, index) => {
                            return (
                              <>
                                <MDBCol md="3" className="mt-3">
                                  <MDBInput
                                    id={`rfid_${index}`}
                                    name={`rfid_${index}`}
                                    type="text"
                                    label={t('rfid_tag_optional')}
                                    value={multipleRfidArrayAlias.rfidTag}
                                    onChange={this.changeRfid.bind(this, index, "rfidTag")}
                                  >
                                  </MDBInput>
                                  <span className={`rfidTag_${index}`} style={{ color: "red", fontSize: "13px" }}></span>
                                </MDBCol>
                                <MDBCol md="3" className="mt-3">
                                  <MDBInput
                                    id={`aliasName_${index}`}
                                    name={`aliasName_${index}`}
                                    type="text"
                                    label={t('rfid_tag_alias')}
                                    value={multipleRfidArrayAlias.aliasName}
                                    onChange={this.changeRfid.bind(this, index, "aliasName")}
                                  >
                                  </MDBInput>
                                  <span className={`aliasName_${index}`} style={{ color: "red", fontSize: "13px" }}></span>
                                </MDBCol>
                                <MDBCol md="5" className="form_margin">
                                  <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">{t('choose_rfid_tag_type')} * <span className="custom_tooltip"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                    <div className="custom_tooltiptext">
                                      <div className="custom_tooltip_header">{t('rfid_tag_type')}</div>
                                      <div className="custom_tooltip_body">
                                        <div>{t('type_of_rfid_tag')}</div>
                                        <div><span className="font-bold">{t('private')}: </span>{t('rfid_tag_intended_for_private_use')}</div>
                                        <div><span className="font-bold">{t('company')}: </span>{t('rfid_tag_was_issued_by_company')}</div>
                                        <div><span className="font-bold">{t('other')}: </span>{t('default_setting_no_further_logic_involved')}</div>
                                      </div>
                                    </div>
                                  </span></label>
                                  <select className="browser-default custom-select select_height w_53 indent mt-1" id="rfidTagType" onChange={this.changeRfid.bind(this, index, "tagType")} value={multipleRfidArrayAlias.tagType}>
                                    <option value="other">{t('other')}</option>
                                    <option value="private">{t('private')}</option>
                                    <option value="company">{t('company')}</option>
                                  </select>
                                  <div className="mt-3"><span className={`tagType_${index}`} style={{ color: "red", fontSize: "13px" }}></span></div>
                                </MDBCol>
                                <MDBCol md="1" className="my-3 mt-3">
                                  <i class="fas fa-times-circle mt-3" onClick={this.removeRfid.bind(this, index)}></i>
                                </MDBCol>
                              </>
                            )
                          })
                        }
                      </>
                    }

                  </MDBRow>
                  <div>
                    <span className="empty_rfids" style={{ color: "red", fontSize: "13px" }}></span>
                  </div>
                  <div className="mt-3">
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} className="rfidexists"></span></span>
                  </div>
                  <MDBCol md="12" className="text-right">
                    <button type="button" className="refresh-btn mt-5 mr-3" onClick={this.confirmtoggle1}><i className="fas fa-save mr-2"></i> {t('save')}</button>
                  </MDBCol>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
        <MDBModal isOpen={this.state.confirmmodal1} size="md" className="model_top">
          <MDBModalHeader toggle={this.confirmtoggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('sure_you_want_to_save_changes')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle1}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('user_details_updated_sucessfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.isOpenProfileModal}  size='md' className='modal-smpopup'>
          <MDBModalBody className="service-management-profile">
            <h4 className="mb-4"> {this.state.editProfile ?   t('edit_service_management_profile') : t('service_management_profile')  }</h4>
            <form className='smpopup-form'>
              <div className='smpopup-form-input-containers'>
              <div className='md-form mt-2 mb-3'>
              <input name="serviceAccessCode" type="text" id="serviceAccessCode" style={{color:'gray'}} readOnly={true} value={this.state.profileData.serviceAccessCode} 
              className='form-control pl-0 disable_clr'/>
              <label htmlFor="serviceAccessCode" className={`${
                            this.state.profileData["serviceAccessCode"] !== "" &&
                            this.state.profileData["serviceAccessCode"] !== null
                              ? "active"
                              : ""
                          } ml-11 disable_clr`}>{t('service_management_access_code_auto_generated')}</label>

              
              </div>
              
              <div className='md-form mb-4'>    
              <input name="companyName" type="text" id="companyName" value={this.state.profileData["companyName"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange}
              onBlur={this.handleCompanyNameError} maxLength="35" />
		          <label htmlFor="companyName" className={`${
                            this.state.profileData["companyName"] !== "" &&
                            this.state.profileData["companyName"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('company_name')}*</label>

              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iscompanyNameEmpty"]}</span></span>
              </div>
              
              <div className='md-form mb-3'>
              <input name="city" type="text" id="city" value={this.state.profileData["city"]} 
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="city" className={`${
                            this.state.profileData["city"] !== "" &&
                            this.state.profileData["city"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('city')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="zipCode" type="text" value={this.state.profileData.zipCode} id="zipCode"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="zipCode" className={`${
                            this.state.profileData["zipCode"] !== "" &&
                            this.state.profileData["zipCode"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('zipCode')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="address" type="text" value={this.state.profileData.address} id="address"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="address" className={`${
                            this.state.profileData["address"] !== "" &&
                            this.state.profileData["address"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('address')}</label>
              </div>

              <div className='md-form mb-3'>
              <input name="country" type="text" value={this.state.profileData.country} id="country"
              className='form-control pl-0' onChange={this.handleFormInputChange}/>
              <label  htmlFor="country" className={`${
                            this.state.profileData["country"] !== "" &&
                            this.state.profileData["country"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('country')}</label>
              </div>

              <div className='md-form mb-4'>
              <input name="telephone" type="text" value={this.state.profileData.telephone} id="telephone"
              className='form-control pl-0' onChange={this.handleFormInputChange} />
              <label  htmlFor="telephone" className={`${
                            this.state.profileData["telephone"] !== "" &&
                            this.state.profileData["telephone"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('telephone')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["istelephoneEmpty"]}</span></span>

              </div>

              <div className='md-form mb-4'>
              <input name="email" type="text" value={this.state.profileData.email} id="email"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.handleEmailError}/>
              <label  htmlFor="email" className={`${
                            this.state.profileData["email"] !== "" &&
                            this.state.profileData["email"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('email')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["isemailEmpty"]}</span></span>

              </div>

              <div className='md-form mb-3'>
              <input name="websiteUrl" type="text" value={this.state.profileData.websiteUrl} id="websiteUrl"
              className='form-control pl-0' onChange={this.handleFormInputChange} onBlur={this.validateURL}/>
              <label  htmlFor="websiteUrl" className={`${
                            this.state.profileData["websiteUrl"] !== "" &&
                            this.state.profileData["websiteUrl"] !== null
                              ? "active"
                              : ""
                          } ml-11`}>{t('website_url')}</label>
              <span className="error_msg w3-animate-top ml-0">{" "}<span style={{ color: "red" }}>{this.state.errors["iswebsiteUrlEmpty"]}</span></span>

              </div>


             
              </div>
              <div className='smpopup-btns mt-2' >
                {this.state.editProfile ? <button type="button" className='smpopup-savebtn' onClick={this.handleSaveEditedSMProfile}>{t('update')}</button> :
                  <button type="button" className='smpopup-savebtn' onClick={this.handleSaveSMProfile}>{t('save')}</button>
                }
                <button type="button" className='smpopup-cancelbtn' onClick={this.toggleProfileModal}>{t('cancel')}</button>
              </div>
            
            </form>
          </MDBModalBody>

          <MDBModal isOpen={this.state.isSuccessPopupOpen} size="md" className="model_top">
            <MDBModalBody>
            <div className='flex-col-justify-n-align-center'>
            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60"  alt="" />
            {this.state.editProfile ? <p className='successpopup-msg'>{t('sm_profile_hasbeen_successfully_changed')}</p> :
                  <p className='successpopup-msg'>{t('sm_profile_hasbeen_successfully_created')}</p>
            }            
            <button className='successpopup-closebtn' type='button' onClick={() => window.location.reload()}>{t('close')}</button>

            </div>
            </MDBModalBody> 
         </MDBModal>
          
        </MDBModal>

        {/* <MDBModal isOpen={this.state.isOpenEditProfileModal} toggle={this.toggleEditProfileModal} size='md' className='modal-smpopup'>
          <MDBModalBody className="service-management-profile">
          <h4 className="mb-4">{t('edit_service_management_profile')}</h4>
            
            <form className='smpopup-form'>
              <div className='smpopup-form-input-containers'>
              <div className="input-container-insmpopup">
                <label style={{color:'gray'}}>{t('service_management_access_code_auto_generated')}</label>
                <input name="accessCode"   style={{color:'gray'}} readOnly={true} type="text" value={this.state.serviceAccessCode} />
              </div>
              <div className='input-container-insmpopup'>
              <label>{t('company_name')}*</label>
              <input name="companyName" type="text" value={this.state.profileData["companyName"]} 
              className='form-control' onChange={this.handleFormInputChange}
              onBlur={this.handleCompanyNameError}/>
              <span style={{marginTop:2}} className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["iscompanyNameEmpty"]}</span></span>
              </div>

              <div className='input-container-insmpopup'>
              <label>{t('city')}</label>
              <input name="city" type="text" value={this.state.profileData.city} 
              className='form-control' onChange={this.handleFormInputChange}/>
              </div>

              <div className='input-container-insmpopup'>
              <label>{t('zipcode')}</label>
              <input name="zipCode" type="text" value={this.state.profileData.zipCode}
               className='form-control' onChange={this.handleFormInputChange}/>
              </div>

              <div className='input-container-insmpopup'>
              <label>{t('address')}</label>
              <input name="address" type="text" value={this.state.profileData.address} 
              className='form-control' onChange={this.handleFormInputChange}/>
              </div>

              <div className='input-container-insmpopup'>
              <label>{t('country')}</label>
              <input name="country" type="text" value={this.state.profileData.country} 
              className='form-control' onChange={this.handleFormInputChange}/>
              </div>

              <div className='input-container-insmpopup'>
              <label>{t('telephone')}</label>
              <input name="telephone" type="text" value={this.state.profileData.telephone} 
              className='form-control' onChange={this.handleFormInputChange}
              onBlur={this.handleTelephoneError}/>
              <span style={{marginTop:2}} className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["istelephoneEmpty"]}</span></span>

              </div>

              <div className='input-container-insmpopup'>
              <label>{t('email')}</label>
              <input name="email" type="text" value={this.state.profileData.email} 
              className='form-control' onChange={this.handleFormInputChange}
              onBlur={this.handleEmailError}/>
              <span style={{marginTop:2}} className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["isemailEmpty"]}</span></span>

              </div>

              <div className='input-container-insmpopup'>
              <label>{t('website_url')}</label>
              <input name="websiteUrl" type="text" value={this.state.profileData.websiteUrl}
               className='form-control' onChange={this.handleFormInputChange}/>
              <span style={{marginTop:2}} className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["urlempty"]}</span></span>

              </div>
              </div>
              <div className='smpopup-btns' >
                <button type="button" className='smpopup-savebtn' onClick={this.handleSaveEditedSMProfile}>{t('save')}</button>
                <button type="button" className='smpopup-cancelbtn' onClick={this.toggleEditProfileModal}>{t('cancel')}</button>
              </div>
            
            </form>
          </MDBModalBody>

          <MDBModal isOpen={this.state.isSuccessPopupOpen} toggle={this.toggleSuccessPopup} size="md" className="model_top">
          <MDBModalBody>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60"  alt="" />
            <p style={{marginTop:'15px', textAlign:'center'}}>Service management profile has <br/>been successfully changed.</p>
            <button className='successpopup-closebtn' type='button' onClick={this.toggleSuccessPopup}>{t('close')}</button>

            </div>
          </MDBModalBody> 
        </MDBModal>
          
        </MDBModal> */}


        <MDBModal isOpen={this.state.isDeactivePopupOpen}  size="md" className="model_top">
          <MDBModalBody>
            <div className='flex-col-justify-n-align-center'>
              <h3 className='deactivate-popup-h3'>{t('confirmation')}</h3>
            <p className='deactivate-confirm' style={{color:'black', marginBottom:'10px'}}>{t('do_you_want_deactivate_user_from_sm')}</p>
            <div className='smpopup-btns deativate-btndiv' >
                <button type="button" className='smpopup-savebtn' style={{width:"35%"}} onClick={this.saveBtnDeactivatePopup}>{t('yes')}</button>
                <button type="button" className='smpopup-cancelbtn ml-3' style={{width:"35%"}} onClick={this.toggleDeactivatePopup}>{t('no')}</button>
              </div>
            </div>
          </MDBModalBody> 
        </MDBModal>

        <MDBModal isOpen={this.state.isDeactiveSuccessPopupOpen} size="md" className="model_top">
          <MDBModalBody>
            <div className='flex-col-justify-n-align-center'>
            <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60"  alt="" />
            <p className='deactivate-confirm' style={{color:'black', marginBottom:'10px'}}>{t('the_user_deactivated_successfully_from_sm')}</p>
            <div className='smpopup-btns deativate-btndiv' >
                <button type="button" className='smpopup-savebtn' style={{width:"35%"}} onClick={this.toggleDeactivateSuccessPopup}>{t('close')}</button>
              </div>
            </div>
          </MDBModalBody> 
        </MDBModal>
      </main>
    );
  }
}


export default withTranslation()(editUserManagement);