/* eslint-disable no-useless-escape */
import { createBrowserHistory } from 'history'
import { MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact'
import { hasPermission } from '../auth';
import React from 'react'
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import NewSiteSettings from './newSiteSettings';
import NewSiteUsers from './newSiteUsers';
import NewSiteWallboxes from './newSiteWallboxes';
import NewSiteCosts from './newSiteCosts';
import { Doughnut } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer"
import Pagination from "react-js-pagination";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import $, { event } from "jquery";
import * as roles from '../roles';
import { withTranslation } from 'react-i18next';
import NewSiteReservationRules from './newSiteReservationRules';
import currencyFormatter from 'currency-formatter';
import CustomModal from '../../common/CustomModal';
import Loader from '../loader';
import {noDecimalCurrencies} from '../../common/noDecimalCurrencies';
import SuccessPopup from '../../common/SuccessPopup';

//API imports 
import * as baseUrl from '../constants'
import servicesUrl from '../../common/servicesUrl'
import APICallUtility from '../../common/APICallUtility'
import ApiMethodTypes from '../../common/ApiMethodTypes'
import {getSecurityHeaders} from '../../common/HttpRequestClass'

const history = createBrowserHistory();

class SiteManagement extends React.Component {
    typeAHeadRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            user:{},
            selectedTab: -1,
            minRotation: 0,
            maxRotation: 150,
            noOfRecords: 0,
            availableSites: {
                count: {
                    count: 0
                },
                sites: []
            },
            siteSettings: {
                name: '',
                city: '',
                zipcode: '',
                address: ''
            },
            selectedWallboxes: [],
            selectedSite: null,
            isNewSite: false,
            activePage: 1,
            pageSize: 10,
            searchStr: '',
            searchSuggestions: [],
            modalMsg: '',
            modal: false,
            modalAddSite: false,
            reloadOnOk: true,
            modalShowMaximumSites: false,
            isOnSearchFind: false,
            isSearchNotFound: false,
            canShowSearchMenu: true,
            fields: {
                id: '',
                name: '',
                city: '',
                zipcode: '',
                address: '',
                country:'',
                selectedItems: [],
                availableWallBoxes: [],
                siteWallboxes: [],
                deletedWallboxes: [],
                users: [],
                guarantedSlotDuration: '',
                cooldown: '',
                minimumChargingDuration: '',
                reservationStatus: false,	
                qrCodeAccessStatus: false,
                ownerQrStatus:false,
                energylimitationStatus:false
            },
           
            isReportingChecked: false,
            siteValidationError: '',
            isfieldvalidate: false,
            siteSucess: '',
            isSiteSuccess: false,
            emailError: '',
            wallboxError: "",
            displayInvite: 'none',
            inviteMessage: '',
            invitePopup: false,
            errorCode: '',
            noWallboxExists: '',
            email: '',
            inviteEmail: '',
            wallbox: '',
            isCreatedBy: '',
            btnAction: "",
            currency: '€',
            isCostPerSessionActive: false,
            isCostBasedonUsageActive: false,
            CostPerSessionValue: '',
            CostPerUsageValue: '',
            currencyList: [],
            currencyCode: 'EUR',
            currecyCodeList: {},
            errors: {},
            demotedList: [],
            promotedList: [],
            europeStates: ['EUR', 'SEK', 'NOK', 'TRY', 'RUB', 'DKK', 'PLN', 'CZK'],
            costdetails:false,
            costPerSession:"",
            costsBasedOnUsage:"",
            costCurrencyCode:"",
            chargingSessionCurrency:"",
            reservationSeatingEnabled:false,
            addQRCodePopup: false,
            setUpQRCode1: false,
            setUpQRCode2: false,
            setUpQRCode3: false,
            isLimitEnergyusageActive: false,
            isLimitChargingSessionsActive: false,
            isLimitPeriodOfUseActive: false,
            energylimit: '',
            sessionlimit: '',
            dayslimit: '',
            energylimitCode: 'kWh',
            duplicateqrcodevalue: '',
            showLoder: "false",	
            QRSiteId:"",
            qr_warning_msg:"",
            QrCodeModel:false,
            loading: false,
            usergroupsmodal: false,
            userGroupsData: [],
            addUserGroupsPopup: false,
            isNewUserGroup: false,
            inviteUserModal: false,
            usergroupname: "",
            siteId: "",
            userGroupSucess: "",
            isUserGroupSuccess: false,
            selectedUserGroup:"",
            modalDeleteUserGroup:false,
            userGroupDetails: {},
            isUserGroupDeleteSuccess:false,
            userGroupDeleteSucess:"",
            activePageGroup:1,
            pageSizeGroup:10,
            noOfRecordsGroup:0,
            userGroupId: "",
            userGroupsListErrMsg: "",
            userGroupsListWarningModal: false
        }
        this.searchSite = this.searchSite.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
        this.getKWRotation = this.getKWRotation.bind(this);
        this.addNewSite = this.addNewSite.bind(this);
        this.editSite = this.editSite.bind(this);
        this.onChangeWallboxes = this.onChangeWallboxes.bind(this);
        this.onConfirmDeleteSite = this.onConfirmDeleteSite.bind(this);
        this.getAvailableSites = this.getAvailableSites.bind(this);
        this.onCloseSiteAddPopup = this.onCloseSiteAddPopup.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
        this.onChangeTypeahead = this.onChangeTypeahead.bind(this);
        this.clearSearchSuggestions = this.clearSearchSuggestions.bind(this);
        this.switchSite = this.switchSite.bind(this);
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.toggleAddPopup = this.toggleAddPopup.bind(this);
        this.toggleDeleteSite = this.toggleDeleteSite.bind(this);
        this.getSiteLimit = this.getSiteLimit.bind(this);
        this.toggleMaximumSites = this.toggleMaximumSites.bind(this);
        this.updateSiteSettings = this.updateSiteSettings.bind(this);
        this.saveNewSite = this.saveNewSite.bind(this);
        this.toggleFieldValidations = this.toggleFieldValidations.bind(this);
        this.toggleSiteSuccess = this.toggleSiteSuccess.bind(this);
        this.removeSelectedItem = this.removeSelectedItem.bind(this);
        this.isAccessChecked = this.isAccessChecked.bind(this);
        this.isAdminChecked = this.isAdminChecked.bind(this);
        this.onChangeAccessCheckbox = this.onChangeAccessCheckbox.bind(this);
        this.onChangeAdminCheckbox = this.onChangeAdminCheckbox.bind(this);
        this.updateExistingSite = this.updateExistingSite.bind(this);
        this.wallboxSearch = this.wallboxSearch.bind(this);
        this.emailSearch = this.emailSearch.bind(this);
        this.inviteEmail = this.inviteEmail.bind(this);
        this.toggleInvitePopup = this.toggleInvitePopup.bind(this);
        this.toggleDisplayInvite = this.toggleDisplayInvite.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.clearWallboxerror = this.clearWallboxerror.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.onEmailkeyPress = this.onEmailkeyPress.bind(this);
        this.onchangeInviteMail = this.onchangeInviteMail.bind(this);
        this.handleWallboxChange = this.handleWallboxChange.bind(this);
        this.handleWallboxPress = this.handleWallboxPress.bind(this);
        this.updatedata = this.updatedata.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onChangeCostPerSessionActive = this.onChangeCostPerSessionActive.bind(this);
        this.onChangeCostBasedonUsageActive = this.onChangeCostBasedonUsageActive.bind(this);
        this.onChangeCostPerUsageValue = this.onChangeCostPerUsageValue.bind(this);
        this.onChangeCostPerSessionValue = this.onChangeCostPerSessionValue.bind(this);
        this.getCurrencies = this.getCurrencies.bind(this);
        this.updateDemotedAndPromotedUsersList = this.updateDemotedAndPromotedUsersList.bind(this);
        this.openCostdetails = this.openCostdetails.bind(this);
        this.closeCostdetails = this.closeCostdetails.bind(this);
        this.changeReportingChecked = this.changeReportingChecked.bind(this);
        this.logOffSite = this.logOffSite.bind(this);
        this.closeaddQRCodePopup = this.closeaddQRCodePopup.bind(this);
        this.openSetUpQRCode1 = this.openSetUpQRCode1.bind(this);
        this.closeSetUpQRCode1 = this.closeSetUpQRCode1.bind(this);
        this.openSetUpQRCode2 = this.openSetUpQRCode2.bind(this);
        this.closeSetUpQRCode2 = this.closeSetUpQRCode2.bind(this);
        this.openSetUpQRCode3 = this.openSetUpQRCode3.bind(this);
        this.closeSetUpQRCode3 = this.closeSetUpQRCode3.bind(this);
        this.onChangeLimitEnergyusageActive = this.onChangeLimitEnergyusageActive.bind(this);
        this.onChangeLimitPeriodOfUseActive = this.onChangeLimitPeriodOfUseActive.bind(this);
        this.onChangeLimitEnergyusageValue = this.onChangeLimitEnergyusageValue.bind(this);
        this.onChangeLimitPeriodOfUseValue = this.onChangeLimitPeriodOfUseValue.bind(this);
        this.onChangeDuplicateQRCodesValue = this.onChangeDuplicateQRCodesValue.bind(this);
        this.changeReservationStatus = this.changeReservationStatus.bind(this);	
        this.changeQRCodeStatus = this.changeQRCodeStatus.bind(this);
        this.toggQrCodeModel = this.toggQrCodeModel.bind(this);
        this.onBlurEvent = this.onBlurEvent.bind(this); 
        this.handleEnergyLimit = this.handleEnergyLimit.bind(this);
        this.handlePeriodOfUse = this.handlePeriodOfUse.bind(this);
        this.openUserGroups = this.openUserGroups.bind(this);
        this.closeUserGroupsPopup = this.closeUserGroupsPopup.bind(this);
        this.toggleAddUserGroups = this.toggleAddUserGroups.bind(this);
        this.addUserGroup = this.addUserGroup.bind(this);
        this.updateUserGroup = this.updateUserGroup.bind(this);
        this.toggleInviteUser = this.toggleInviteUser.bind(this);
        this.inviteUser = this.inviteUser.bind(this);
        this.onChangeUserGroupName = this.onChangeUserGroupName.bind(this);
        this.toggleUserGroupSuccess = this.toggleUserGroupSuccess.bind(this);
        this.onConfirmDeleteUserGroup = this.onConfirmDeleteUserGroup.bind(this);
        this.toggleDeleteUserGroup = this.toggleDeleteUserGroup.bind(this);
        this.onDeleteUserGroup = this.onDeleteUserGroup.bind(this);
        this.getUserGroupDetails = this.getUserGroupDetails.bind(this);
        this.toggleUserGroupDeleteSuccess = this.toggleUserGroupDeleteSuccess.bind(this)
        this.handleUserPageChange = this.handleUserPageChange.bind(this)
        this.onChangeUserGroupId = this.onChangeUserGroupId.bind(this);
        this.onChangeUserGroup = this.onChangeUserGroup.bind(this);
        this.toggleErrMsgModel = this.toggleErrMsgModel.bind(this);
        this.countryChange = this.countryChange.bind(this);
        this.changeenergylimitationStatus = this.changeenergylimitationStatus.bind(this);
    }
    
  
    toggleErrMsgModel() {
        this.setState({ userGroupsListWarningModal: !this.state.userGroupsListWarningModal })
    }
    onChangeUserGroupId(e){
        let userGroupId = e.target.value;
        let error = {};
        error["userGroupEmpty"] = "";
        this.setState({ userGroupId:userGroupId,errors:error})
    }
    toggleUserGroupSuccess() {
        this.setState({
            isUserGroupSuccess: !this.state.isUserGroupSuccess,
            addUserGroupsPopup: false
        }, () => this.getUserGroupListData(this.state.siteId))
    }
    onChangeUserGroupName(e) {
        let username = e.target.value;
        this.setState({ usergroupname: username })
    }
    inviteUser() {

    }

    toggleInviteUser() {
        let errors = {};
        errors["emailempty"] = '';
        errors["entervalidemail"] = '';
        this.setState({ 
        inviteUserModal: !this.state.inviteUserModal,
        errors: errors ,
        userGroupId:"",
        inviteEmail:""

    })
    }


    async addUserGroup(site) {
        let url = baseUrl.URLPath + servicesUrl.getSitemanagement + "/usergroup";
        let isValid = true;
        let data = {};

        data["name"] = this.state.usergroupname.toLowerCase();
        data["siteId"] = this.state.siteId.id;
        data['chargingSessionCurrency'] = this.state.currency;
        data['currencyCode'] = this.state.currencyCode;
        data['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
        data['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;

        if(this.state.usergroupname === "") {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('enter_user_group_name')
            }, ()=>{
                this.toggleFieldValidations();
            })
        }
        if (!this.state.isCostPerSessionActive && !this.state.CostPerSessionValue.toString()) {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('enter_cost_per_session_value')
            }, ()=>{
                this.toggleFieldValidations();
            })
        } else {
            if (this.state.CostPerSessionValue.toString() && !this.state.isCostPerSessionActive) {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',', '.');
                } else {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue;
                }
            }
        }
        
        if (!this.state.isCostBasedonUsageActive && !this.state.CostPerUsageValue.toString()) {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('please_enter_cost_per_usage_value')
            }, ()=>{
                this.toggleFieldValidations();
            })
        } else {
            if (!this.state.isCostBasedonUsageActive && this.state.CostPerUsageValue.toString()) {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',', '.');
                } else {
                    data['costsPerKwh'] = this.state.CostPerUsageValue;
                }
            }
        }

        if (isValid) {
            let type = ApiMethodTypes.POST;
            let headers = getSecurityHeaders();
            let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);
            if (response) {
                if (response.status === 409 || response.status === 400 || response.status === 403 || response.status === 404) {
                    if (response.message === 'group_name_already_exists' || response.status === 400) {
                        this.setState({
                            siteValidationError: this.props.t('group_name_already_exists')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if (response.message === 'package_charger_limit_exceeded'){
                        this.setState({
                            siteValidationError: this.props.t('package_charger_limit_exceeded')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if (response.message === 'group_not_exists'){
                        this.setState({
                            siteValidationError: this.props.t('group_not_exists')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if(response.message !== ''){
                        this.setState({
                            siteValidationError: this.props.t(response.message)
                        }, () => {
                            this.toggleFieldValidations();
                        }); 
                    }
                } else {
                    if(response.message === 'user_group_created_success') {
                    this.setState({
                        userGroupSucess: this.props.t('user_group_created_sucessfully')
                    }, () => {
                        this.toggleUserGroupSuccess();
                    });
                  }
                }
            }
        }
    }

    async updateUserGroup() {
        let url = `${baseUrl.URLPath}${servicesUrl.getSitemanagement}/usergroup?userGroupId=${this.state.userGroupId}`;
        let isValid = true;
        let data = {};

        data["name"] = this.state.usergroupname.toLowerCase();
        data["siteId"] = this.state.siteId.id;
        data['chargingSessionCurrency'] = this.state.currency;
        data['currencyCode'] = this.state.currencyCode;
        data['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
        data['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;

        if(this.state.usergroupname === "") {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('enter_user_group_name')
            }, ()=>{
                this.toggleFieldValidations();
            })
        }
        if (!this.state.isCostPerSessionActive && !this.state.CostPerSessionValue.toString()) {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('enter_cost_per_session_value')
            }, ()=>{
                this.toggleFieldValidations();
            })
        } else {
            if (this.state.CostPerSessionValue.toString() && !this.state.isCostPerSessionActive) {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',', '.');
                } else {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue;
                }
            }
        }
        
        if (!this.state.isCostBasedonUsageActive && !this.state.CostPerUsageValue.toString()) {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('please_enter_cost_per_usage_value')
            }, ()=>{
                this.toggleFieldValidations();
            })
        } else {
            if (!this.state.isCostBasedonUsageActive && this.state.CostPerUsageValue.toString()) {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',', '.');
                } else {
                    data['costsPerKwh'] = this.state.CostPerUsageValue;
                }
            }
        }

        if (isValid) {
            let type = ApiMethodTypes.PUT;
            let headers = getSecurityHeaders();
            let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);
            if (response) {
                if (response.status === 409 || response.status === 400 || response.status === 403 || response.status === 404) {
                    if (response.message === 'group_name_already_exists' || response.status === 400) {
                        this.setState({
                            siteValidationError: this.props.t('group_name_already_exists')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if (response.message === 'package_charger_limit_exceeded'){
                        this.setState({
                            siteValidationError: this.props.t('package_charger_limit_exceeded')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if (response.message === 'group_not_exists'){
                        this.setState({
                            siteValidationError: this.props.t('group_not_exists')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if(response.message !== ''){
                        this.setState({
                            siteValidationError: this.props.t(response.message)
                        }, () => {
                            this.toggleFieldValidations();
                        }); 
                    }
                } else {
                    if(response.message === 'user_group_updated_success') {
                        this.setState({
                            userGroupSucess: this.props.t('user_group_updated_sucessfully')
                        }, () => {
                            this.toggleUserGroupSuccess();
                        });
                    }
                }
            }
        }
    }

    toggleAddUserGroups(user) {
        if(this.state.isNewUserGroup) {
            this.setState({ 
                addUserGroupsPopup: !this.state.addUserGroupsPopup,
                isCostPerSessionActive: true, 
                isCostBasedonUsageActive: true,
                usergroupname: "",
                CostPerSessionValue: "",
                CostPerUsageValue: "" 
            })
        } else {
            this.setState({ 
                addUserGroupsPopup: !this.state.addUserGroupsPopup,
                // isCostPerSessionActive: false, 
                // isCostBasedonUsageActive: false,
            }, () => this.getUserGroupDetails(user)
        )
        }
    }

    async getUserGroupDetails(user) {
        let url =`${baseUrl.URLPath}${servicesUrl.getUserGroupDetails}?siteId=${this.state.siteId.id}&userGroupId=${user.id}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        let state = this.state;
        state.currencyCode = response.currencyCode;
        state.isCostBasedonUsageActive = response.isDisabledUsageCost;
        state.isCostPerSessionActive = response.isDisabledSessionCost;

        if (this.state.europeStates.includes(state.currencyCode)) {
            state.CostPerSessionValue = response.costsPerChargingSession.toString().replace('.', ',');
        } else {
            state.CostPerSessionValue = response.costsPerChargingSession;
        }

        if (this.state.europeStates.includes(state.currencyCode)) {
            state.CostPerUsageValue = response.costsPerKwh.toString().replace('.', ',');
        } else {
            state.CostPerUsageValue = response.costsPerKwh;
        }

        if (response.chargingSessionCurrency) {
            state.currency = response.chargingSessionCurrency;
        } else {
            state.currency = '€';
        }
        if (response) {
            this.setState({
                ...this.state,
                ...state,
                userGroupDetails: response,
                usergroupname:response.name,
                userGroupId: user.id
            })
        }
    }

    closeUserGroupsPopup() {
        this.setState({ usergroupsmodal: false })
    }

    openUserGroups(siteId) {
        this.setState({ 
            usergroupsmodal: !this.state.usergroupsmodal,
            siteId: siteId
        })

       this.getUserGroupListData(siteId);
    }

    async getUserGroupListData(siteId){
        let url =`${baseUrl.URLPath}${servicesUrl.getsiteUserGroupList}?siteId=${siteId.id}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response.status === 404) {
            this.setState({
               userGroupsListErrMsg: response.message,
               userGroupsListWarningModal: !this.state.userGroupsListWarningModal
            });
        } else {
            this.setState({
                userGroupsData: response,
            })
        }
        // if (response) {
        //     this.setState({
        //         userGroupsData: response,
        //     })
        // }
    }   

    onBlurEvent(event) {
        this.setState({ ...this.state, searchStr: event.target.value });
    }

    changeReportingChecked(){
        this.setState({
            isReportingChecked: !this.state.isReportingChecked
        })
    }

    handleWallboxPress(e) {
        if (e.keyCode === 13) {
            this.setState({ wallbox: e.target.value }, ()=>{
                this.wallboxSearch(this.state.fields.id, this.state.wallbox);
            });
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                break;
        }
    }

    handleWallboxChange(e) {
        $("#empty_wallboxes").text("")
        this.setState({
            wallbox: e.target.value
        }, () => {
            this.clearWallboxerror();
            if (this.state.wallbox.length === 0) {
                this.wallboxSearch(this.state.fields.id, this.state.wallbox);
            }
        });
    }

    onchangeInviteMail(e) {
        let val = e.target.value;
        this.state.errors["emailempty"] = "";
        this.state.errors["entervalidemail"] = "";
        this.setState({ inviteEmail: val});
    }

    clearWallboxerror() {
        this.setState({
            noWallboxExists: ''
        })
    }

    toggleDisplayInvite() {
        this.setState({
            displayInvite: 'none'
        });
    }

    toggleInvitePopup() {
        this.setState({
            invitePopup: !this.state.invitePopup
        });
    }

    toggleSiteSuccess() {
        this.setState({
            isSiteSuccess: !this.state.isSiteSuccess
        })
    }

    toggleFieldValidations() {
        this.setState({
            isfieldvalidate: !this.state.isfieldvalidate
        })
    }

    componentDidMount() {
        const user = {
            permissions: []
        };
        if (localStorage.getItem("roleAccess") !== undefined) {
            user.permissions = localStorage.getItem("roleAccess");
        }

        this.setState({
            user: user
        })
        if (localStorage.role === roles.WEBASTO_SUPER_ADMIN_DEVOPS ||
            localStorage.role === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE ||
            localStorage.role === roles.WEBASTO_CUSTOMER_ADMIN) {
            this.getSiteLimit();
        }
        this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
        this.getCurrencies();
        let userPackage = JSON.parse(localStorage.getItem("userPackage"));
        if (userPackage !== null) { 
         let reservationSeatingEnabled =  userPackage.reservationSeatingEnabled;
           this.setState({
                 reservationSeatingEnabled: reservationSeatingEnabled
            })
        }
    }
  
    async getCurrencies(){
        let url =`${baseUrl.URLPath}${servicesUrl.sitegetCurrencies}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response) {
            let currecyCodeList = {}
            for (let currency of response) {
                currecyCodeList[currency.currencysymbol] = currency.currencyCode;
            }
            this.setState({
                currencyList: response,
                currecyCodeList: currecyCodeList
            })
        }
    }    

    async getSiteLimit(){
        let url =`${baseUrl.URLPath}${servicesUrl.sitegetSiteLimit}`;
        let type = ApiMethodTypes.GET; 
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if (response) {
            const { isSitelimitChecked, sitelimit } = response;
            this.setState({ ...this.state, isSitelimitChecked, sitelimit });
        }
    }

    async getAvailableSites(page, pageSize, site) {
        this.setState({ loading: true })
        let url = `${baseUrl.URLPath}${servicesUrl.getGroupList}?pageSize=${pageSize}&pageNo=${page}&site=${site}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response.status !== 403 && response.status !== 404 && response.status !== 401){
            if (this.state.isOnSearchFind) {
                if (response.count.count > 0) {
                    this.setState({ ...this.state, availableSites: response, noOfRecords: response.count.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
                } else {
                    this.setState({ ...this.state, isSearchNotFound: true, loading: false });
                    this.state.isSearchNotFound = true;
                }
            } else {
                this.setState({ ...this.state, availableSites: response, noOfRecords: response.count.count, isSearchNotFound: false, isOnSearchFind: false, loading: false });
            }
        }else{
            this.setState({loading : false})
        }
        
    }
  
    async getUsers(site){
        let url =`${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${site}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);  
        let fields = this.state.fields;
        fields.users = response.users;
        this.setState({ fields });
    }

    async emailSearch(siteId, email) {
        let mail = "";
        let isvalid = false;

        if (email) {
            mail = email.trim();
            isvalid = true;
        }
        if (mail.length > 0 && isvalid) {
            let url = baseUrl.URLPath + servicesUrl.getSitemanagementUser + "?siteid=" + siteId + "&email=" + email;
            let type = ApiMethodTypes.GET;
            let headers = getSecurityHeaders();
            let payload = {}
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (response.status === 404) {
                this.setState({
                    ...this.state,
                    emailError: '',
                    displayInvite: 'block'
                });
            } else {
                let userArray = [];
                userArray.push(response);
                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        users: userArray
                    },
                    emailError: "",
                    displayInvite: 'none'
                });
            }

        } else {
            this.setState({
                ...this.state,
                emailError: '',
                displayInvite: 'none'
            }, () => { this.getUsers(siteId) });
        }

    }

    async inviteEmail(siteId, email){
        let emailId = this.state.inviteEmail;
        let userGroup = this.state.userGroupId;
        let errors = {};
        let isValid = true;

        if (emailId === '') {
            errors["emailempty"] = this.props.t('enter_email');
            isValid = false;
        }
        if (emailId !== '') {
            if (!emailId.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
              errors["entervalidemail"] = this.props.t('enter_valid_email_id');
              isValid = false;
            }
          }
          
        if (userGroup === '') {
            errors["userGroupEmpty"] = this.props.t('select_user_group');
            isValid = false;
        }
          if(isValid) {
            let url = baseUrl.URLPath + servicesUrl.siteInviteEmail;
            let type = ApiMethodTypes.POST;
            let headers = getSecurityHeaders();
            let payload = {
                email:email,
                siteId:siteId,
                userGroupId: this.state.userGroupId

            }
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);  
            if (response.message === 'group_invite_send_to_user') {
                this.setState({
                    inviteMessage: this.props.t(response.message),
                    invitePopup: true,
                    errorCode: 200,
                    displayInvite: 'none'
                }, () => this.toggleInviteUser());
            } else {
                this.setState({
                    inviteMessage: this.props.t(response.message),
                    invitePopup: true,
                    errorCode: response.status,
                    displayInvite: 'none'
                }, () => this.toggleInviteUser());
            }
          }
        
    this.setState({ errors: errors });
   }

    searchSite() {
        this.setState({ ...this.state, isOnSearchFind: true });
        this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
    }

    addNewSite() {
        const totalExistingSites = this.state.availableSites?.count?.count || 0;

        if (totalExistingSites < this.state.sitelimit || !this.state.isSitelimitChecked) {
            let fields = {
                id: '',
                name: '',
                city: '',
                zipcode: '',
                address: '',
                country:'',
                selectedItems: [],
                availableWallBoxes: [],
                siteWallboxes: [],
                deletedWallboxes: [],
                users: []
            }

            this.setState({ ...this.state, fields, wallbox: "", noWallboxExists: "", selectedTab: 0, isNewSite: true, modalAddSite: true, currency: '€', isCostPerSessionActive: true, isCostBasedonUsageActive: true, CostPerSessionValue: '', CostPerUsageValue: '', currencyCode: 'EUR', errors: {} });
        } else {
            this.setState({ ...this.state, modalShowMaximumSites: true, wallbox: "" });
        }
    }

    async editSite(site){
        let url =`${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${site.id}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders(); 
        let payload={}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        let state = this.state;
        state.fields.name = response.name;
        state.fields.address = response.address;
        state.fields.zipcode = response.zipcode;
        state.fields.city = response.city;
        state.fields.id = response.id;
        state.fields.users = response.users;
        state.fields.cooldown = response.cooldown;
        state.fields.minimumChargingDuration = response.minimumCharginduration;
        state.fields.guarantedSlotDuration = response.guarantedslotDuration;
        state.fields.reservationStatus = response.reservationStatus;
        state.fields.qrCodeAccessStatus = response.qrCodeStatus;
        state.fields.energylimitationStatus = response.energyLimit;
        state.fields.ownerQrStatus = response.ownerQrStatus;
        state.fields.country = response.country;

        // localStorage.setItem('usersFromApi', JSON.stringify(response.users));

        state.noWallboxExists = "";
        state.btnAction = "Edit";
        state.email = "";
        state.wallbox = "";
        state.currencyCode = response.currencyCode;
        state.isReportingChecked = response.reporting;
        state.isCostBasedonUsageActive = response.isDisabledUsageCost;
        state.isCostPerSessionActive = response.isDisabledSessionCost;

        if (this.state.europeStates.includes(state.currencyCode)) {
            state.CostPerSessionValue = response.costsPerChargingSession.toString().replace('.', ',');
        } else {
            state.CostPerSessionValue = response.costsPerChargingSession;
        }

        if (this.state.europeStates.includes(state.currencyCode)) {
            state.CostPerUsageValue = response.costsPerKwh.toString().replace('.', ',');
        } else {
            state.CostPerUsageValue = response.costsPerKwh;
        }

        if (response.chargingSessionCurrency) {
            state.currency = response.chargingSessionCurrency;
        } else {
            state.currency = '€';
        }

        this.setState({ 
            ...this.state,
            ...state,
            selectedTab: 0, 
            isNewSite: false, 
            modalAddSite: true, 
            isCreatedBy: response.createdBy 
        });
        
    }

    setSelectedTab(tabId, id) {
        if (this.state.selectedTab !== tabId) {
            this.setState({ ...this.state, selectedTab: tabId, noWallboxExists: "" });
        }
        if(tabId===2){
            this.getGroupUserList();
            this.getUserGroupListData(this.state.fields);
        }
    }



    getKWRotation(kwVal) {
        const retVal = kwVal * 180 / this.state.maxRotation - 90;
        return retVal;
    }

    onChangeWallboxes(selectedWallboxes) {
        this.setState({ ...this.state, selectedWallboxes });
    }

    onCloseSiteAddPopup() {
        this.setSelectedTab(-1);
    }

    onDeleteSite(site) {
        this.setState({ ...this.state, selectedSite: site, modalDeleteSite: true });
    }

    async onConfirmDeleteSite(){
        let url =`${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${this.state.selectedSite.id}`;
        let type = ApiMethodTypes.DELETE;
        let headers = getSecurityHeaders(); 
        let payload={}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response.status === 403){
            APICallUtility.renderAccessDenied(this.props.t);
            this.setState({ modalDeleteSite: false })
        } else {
            if(response.message==="site_deleted_success"){
                this.setState({ ...this.state, modalMsg: this.props.t('group_deleted_successfully'), modal: true, selectedTab: -1, modalDeleteSite: false, reloadOnOk: true });
            }
        }
    }

    getDoughnutData(site) {
        const isEmpty = !site.onlineCPCount && !site.oflineCPCount;
        let total = 0;
        let perOnline = 0;
        let perOffline = 0;

        if (!isEmpty) {
            total = site.oflineCPCount + site.onlineCPCount;
            perOnline = (site.onlineCPCount / total) * 100;
            perOffline = (site.oflineCPCount / total) * 100;
            perOffline = perOffline.toFixed();
            perOnline = perOnline.toFixed();
        }

        const data = {
            datasets: [
                {
                    data: isEmpty ? [100] : [perOffline, perOnline],
                    backgroundColor: isEmpty ? ['#d6d6d6'] : ['#00529e', 'rgba(0, 255, 0, 1)'],
                    borderWidth: 1,
                },
            ],
            options: {
                plugins: {
                    legend: {
                        display: true
                    }
                }
            }
        };

        return data;
    }

    handlePageChange(pageNumber) {
        var value =  this.typeAHeadRef.current.inputNode.value;
        if (pageNumber !== this.state.activePage) {
            this.setState({ ...this.state, activePage: pageNumber });
            if(this.state.isSearchNotFound){
                this.getAvailableSites(pageNumber, this.state.pageSize,"");
                this.setState({
                    isSearchNotFound: false,
                    isOnSearchFind: false,
                    searchStr:value
                })
            }else{
                this.getAvailableSites(pageNumber, this.state.pageSize,"");
            }
        }
    }

    handleUserPageChange(pageNumber){
        if (pageNumber !== this.state.activePageGroup) {
            this.setState({ ...this.state, activePageGroup: pageNumber }, () => {	
                this.getGroupUserList();
            });
            
            // if(this.state.isSearchNotFound){
            //     // this.getAvailableSites(pageNumber, this.state.pageSize,"");
            //     // this.setState({
            //     //     isSearchNotFound: false,
            //     //     isOnSearchFind: false,
            //     //     searchStr:value
            //     // })
            // }else{
            //     this.getAvailableSites(pageNumber, this.state.pageSize,"");
            // }
        }
    }

    showEntriesChange(event) {
        const pageSize = event.target.value;
        if (pageSize !== this.state.pageSize) {
            this.setState({
                activePage: 1,
                pageSize: pageSize,
                searchStr: ""
            },()=>{
                this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
            })
        }
    }

    handleKeyDown(event) {
        this.getSearchSuggestions(event.target.value);
        if (event.key === 'Enter') {
            this.setState({ ...this.state, searchStr: event.target.value, isOnSearchFind: true }, ()=>{
                this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
            });
            $("#rbt_id").hide();
        } else {
            this.setState({ ...this.state, isSearchNotFound: false });
        }
    }

   
    async getSearchSuggestions(searchStr){
        let url =`${baseUrl.URLPath}${servicesUrl.getGroupsByPartial}?site=${searchStr}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders(); 
        let payload={}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response){
            this.setState({ ...this.state, searchSuggestions: response })  
        }
    }

    onChangeTypeahead(site) {
        $('.empty_site').text("")
        this.getAvailableSites(1, this.state.pageSize, site);
    }

    clearSearchSuggestions() {
        this.typeAHeadRef.current.clear();
        $('.empty_site').text("")
        this.setState({ ...this.state, activePage: 1, searchStr: '' }, ()=>{
            this.getAvailableSites(1, this.state.pageSize, '');
        });
    }
    
    async switchSite(selectedSite){
        let url = `${baseUrl.URLPath}${servicesUrl.changeSite}`;
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
        headers["siteid"] = selectedSite.id;
        let payload={}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response){
            localStorage.setItem("Authorization", `${response.tokenType} ${response.token}`);
            const { id, name } = selectedSite;
            const siteDetails = { id, name };
            localStorage.setItem("SelectedSite", JSON.stringify(siteDetails));
            history.push('/dashboard');
            window.location.reload();
        }
    }

    tagOk() {
        if (this.state.reloadOnOk) {
            let site = this.state?.selectedSite;
            let selectedSite = localStorage.getItem("SelectedSite");

            if(selectedSite !== undefined && selectedSite != null && selectedSite !== ""){
                selectedSite = JSON.parse(selectedSite);
            }

            if(site?.name === selectedSite?.name){
                this.logOffSite();
            } else {
                window.location.reload();
            }
        } else {
            this.setState({ ...this.state, modal: false }, ()=>{
                this.getAvailableSites(this.state.activePage, this.state.pageSize, this.state.searchStr);
            });
        }
    }

    async logOffSite() {
        let url = baseUrl.URLPath + servicesUrl.logOffSite;
        let type = ApiMethodTypes.POST;
        let headers = getSecurityHeaders();
    
        //payload
        let payload = {};
    
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
    
        if (response?.status === 'SUCCESS') {
          localStorage.removeItem("SelectedSite");
          localStorage.setItem("Authorization", `${response?.tokenType} ${response?.token}`);
          history.push('/dashboard');
          window.location.reload();
        }
      }


    async toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleAddPopup() {
        let fields = this.state.fields;
        fields.selectedItems = [];
        this.setState({ ...this.state, modalAddSite: !this.state.modalAddSite, fields });
    }

    toggleDeleteSite() {
        this.setState({ ...this.state, modalDeleteSite: !this.state.modalDeleteSite })
    }

    toggleMaximumSites() {
        this.setState({ ...this.state, modalShowMaximumSites: !this.state.modalShowMaximumSites });
    }

    updateSiteSettings(event) {
        let fields = this.state.fields;
        let { name, value } = event.target;
        let ReservationFields = ['guarantedSlotDuration', 'cooldown', 'minimumChargingDuration'];
        if (name) {
            if (ReservationFields.includes(name)) {
                if (event.target.validity.valid) {
                    fields[name] = value;
                    this.setState({ ...this.state, fields });
                }
            } else {
                fields[name] = value;
                this.setState({ ...this.state, fields });
            }
        }
    }

    countryChange(event) {
        var x = event.target.value;
        let fields = this.state.fields;
        fields["country"] = x;
        this.setState({ fields });
    }
    
    async saveNewSite() {
    
        let { address, city, zipcode, name, country } = this.state.fields;
        let userGroupName = this.state.usergroupname;
        if (address.trim() === "" || zipcode.trim() === "" || name.trim() === "" || userGroupName === "" || country.trim() === "Country" || country.trim() === "" || (!this.state.isCostPerSessionActive && this.state.CostPerSessionValue === "") || (!this.state.isCostBasedonUsageActive && this.state.CostPerUsageValue === "")) {
            this.setState({
                siteValidationError: this.props.t('group_setting_field_must_valid')
            }, () => {
                this.toggleFieldValidations();
            });

        } else {
            let url = baseUrl.URLPath + servicesUrl.getSitemanagement;
            let payload = {};
            payload["address"] = address.toLowerCase();
            payload["city"] = city.toLowerCase();
            payload["name"] = name.toLowerCase();
            payload["zipcode"] = zipcode;
            payload["country"] = country;
            payload["tenant"] = localStorage.getItem('tenant');
            payload["newWallboxs"] = this.state.fields.selectedItems;
            payload['chargingSessionCurrency'] = this.state.currency;
            payload['currencyCode'] = this.state.currencyCode;
            payload['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
            payload['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;
            // payload['qrCodeAccessStatus'] = false;	
            // payload['reservationStatus'] = false;
            payload['userGroupName'] = userGroupName;
            
            if (this.state.isCostPerSessionActive) {
                payload['costsPerChargingSession'] = 0;
            } else {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    payload['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',', '.');
                } else {
                    payload['costsPerChargingSession'] = this.state.CostPerSessionValue;
                }
            }

            if (this.state.isCostBasedonUsageActive) {
                payload['costsPerKwh'] = 0;
            } else {
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    payload['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',', '.');
                } else {
                    payload['costsPerKwh'] = this.state.CostPerUsageValue;
                }
            }
            let type = ApiMethodTypes.POST;
            let headers = getSecurityHeaders();
            let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
            if (response.message === "site_create_success") {
                this.setState({
                    siteSucess: this.props.t('new_group_added_successfully')
                }, () => {
                    this.toggleSiteSuccess();
                });
            } else if (response.status === 409) {
                if (response.message === 'group_name_already_exists') {
                    this.setState({
                        siteValidationError: this.props.t('group_name_already_exists')
                    }, () => {
                        this.toggleFieldValidations();
                    });
                }
            }
        }
    }

    removeSelectedItem(item, index) {
        const allItems = [...this.state.fields.selectedItems];
        allItems.splice(index, 1);
        const deletedWallboxIndex = this.state.fields.siteWallboxes.findIndex(el => el === item);
        const deletedWallboxes = [...this.state.fields.deletedWallboxes];
        if (deletedWallboxIndex > -1) {
            deletedWallboxes.push(item);
        }
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                selectedItems: allItems,
                deletedWallboxes
            }
        })
    }

    isAccessChecked(user) {
        const index = this.state.fields.users.findIndex(el => el === user && el.hasAccess);
        return index > -1;
    }

    isAdminChecked(user) {
        const index = this.state.fields.users.findIndex(el => el === user && el.isSiteAdmin);
        return index > -1;
    }

    onChangeAccessCheckbox(event, user) {
        const isChecked = event.target.checked;
        const users = [...this.state.fields.users];
        const selectedUser = users.find(el => el === user);
        if (isChecked) {
            if (selectedUser) {
                selectedUser.hasAccess = true;
            }
        } else {
            selectedUser.hasAccess = false;
        }
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                users
            }
        });
    }

    
    onChangeUserGroup(event, user) {
        const userGroupId = event.target.value;
        const users = [...this.state.fields.users];
        const selectedUser = users.find(el => el === user);
        // if (userGroupId) {
        //     if (selectedUser) {
        //         selectedUser.userGroupId = userGroupId;
        //     }selectedUser.userGroupId = userGroupId;
        // } else {
        //     selectedUser.userGroupId = false;
        // }
        selectedUser.userGroupId = userGroupId;
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                users
            }
        });
    }

    onChangeAdminCheckbox(event, user) {

        const isChecked = event.target.checked;
        const users = [...this.state.fields.users];

        let usersFromApi = localStorage.getItem('usersFromApi');
        let defaultUsers = [];
        if (usersFromApi !== undefined) {
            defaultUsers = JSON.parse(usersFromApi)
        }

        const selectedUser = users.find(el => el === user);
        const defaultUser = defaultUsers.find(el => el.id === user.id);

        let promotedList = this.state.promotedList;
        let demotedList = this.state.demotedList;

        if (isChecked) {
            if (selectedUser) {
                selectedUser.isSiteAdmin = true;
            }
            if (defaultUser.isSiteAdmin === true) {
                if (promotedList.includes(selectedUser.id)) {
                    let index = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(index, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if (demotedList.includes(selectedUser.id)) {
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            } else {
                if (demotedList.includes(selectedUser.id)) {
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if (!promotedList.includes(selectedUser.id)) {
                    promotedList.push(selectedUser.id);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            }
        } else {
            selectedUser.isSiteAdmin = false;
            if (defaultUser.isSiteAdmin === true) {
                if (promotedList.includes(selectedUser.id)) {
                    let position = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if (!demotedList.includes(selectedUser.id)) {
                    demotedList.push(selectedUser.id);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            } else {
                if (promotedList.includes(selectedUser.id)) {
                    let index = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(index, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if (demotedList.includes(selectedUser.id)) {
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    }, () => {
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            }
        }
    }
    async updateExistingSite(siteId){
        const{ customizationSettings}=this.props;
        let isReservationActive = this.state.fields.ownerQrStatus && this.state.fields.reservationStatus;
        let url = baseUrl.URLPath + servicesUrl.getSitemanagement + "?siteid=" + siteId;
        let data = {};
        let {address,city,zipcode,name,country} = this.state.fields
        let isValid = true;

        data["address"] = address.toLowerCase();
        data["city"] = city.toLowerCase();
        data["name"] = name.toLowerCase();
        data["zipcode"] = zipcode.toLowerCase();
        data["tenant"] = localStorage.getItem('tenant');
        data["id"] = this.state.fields.id;
        data["newWallboxs"] = this.state.fields.selectedItems;
        data["deletedWallboxs"] = this.state.fields.deletedWallboxes;;
        data["users"] = this.state.fields.users;
        data["country"] = this.state.fields.country;
        // data['chargingSessionCurrency'] = this.state.currency;
        // data['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
        // data['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;
        data['demotedList'] = this.state.demotedList;
        data['promotedList'] = this.state.promotedList;
        // data['currencyCode'] = this.state.currencyCode;
        data['qrCodeStatus'] = this.state.fields.qrCodeAccessStatus;	
        data['reservationStatus'] = this.state.fields.reservationStatus;
        data['energyLimit'] = this.state.fields.energylimitationStatus;

        if (!address.trim() || !city.trim() || !zipcode.trim() || !name.trim() || country === null || country.trim() === "Country" || country.trim() === ""  ) {
            isValid = false;
            this.setState({
                siteValidationError : this.props.t('group_setting_field_must_valid')
            }, ()=>{
                this.toggleFieldValidations();
            })
        }

        // if (!this.state.isCostPerSessionActive && !this.state.CostPerSessionValue.toString()) {
        //     isValid = false;
        //     this.setState({
        //         siteValidationError : this.props.t('enter_cost_per_session_value')
        //     }, ()=>{
        //         this.toggleFieldValidations();
        //     })
        // } else {
        //     if (this.state.CostPerSessionValue.toString() && !this.state.isCostPerSessionActive) {
        //         if (this.state.europeStates.includes(this.state.currencyCode)) {
        //             data['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',', '.');
        //         } else {
        //             data['costsPerChargingSession'] = this.state.CostPerSessionValue;
        //         }
        //     }
        // }
        
        // if (!this.state.isCostBasedonUsageActive && !this.state.CostPerUsageValue.toString()) {
        //     isValid = false;
        //     this.setState({
        //         siteValidationError : this.props.t('please_enter_cost_per_usage_value')
        //     }, ()=>{
        //         this.toggleFieldValidations();
        //     })
        // } else {
        //     if (!this.state.isCostBasedonUsageActive && this.state.CostPerUsageValue.toString()) {
        //         if (this.state.europeStates.includes(this.state.currencyCode)) {
        //             data['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',', '.');
        //         } else {
        //             data['costsPerKwh'] = this.state.CostPerUsageValue;
        //         }
        //     }
        // }

        if(isReservationActive){
            if (this.state.fields.cooldown.toString() !== "" && this.state.fields.guarantedSlotDuration.toString() !== "" && this.state.fields.minimumChargingDuration.toString() !== "") {
                if(parseInt(this.state.fields.guarantedSlotDuration) > 10080){
                    isValid = false;
                    this.setState({
                        siteValidationError : this.props.t('guaranted_max_limit')
                    }, ()=>{
                        this.toggleFieldValidations();
                    });
                } else if(parseInt(this.state.fields.cooldown) > 1440){
                    isValid = false;
                    this.setState({
                        siteValidationError : this.props.t('cooldown_max_limit')
                    }, ()=>{
                        this.toggleFieldValidations();
                    });
                } else if(parseInt(this.state.fields.minimumChargingDuration) > parseInt(this.state.fields.guarantedSlotDuration)){
                    isValid = false;
                    this.setState({
                        siteValidationError : this.props.t('min_charging_duration_max_limit_condition')
                    }, ()=>{
                        this.toggleFieldValidations();
                    });
                } else if(parseInt(this.state.fields.cooldown) > parseInt(this.state.fields.guarantedSlotDuration)){
                    isValid = false;
                    this.setState({
                        siteValidationError : this.props.t('cooldown_max_limit_condition')
                    }, ()=>{
                        this.toggleFieldValidations();
                    });
                } else {
                    //payload for reservation rules
                    data['cooldown'] = parseInt(this.state.fields.cooldown);
                    data['guarantedslotDuration'] = parseInt(this.state.fields.guarantedSlotDuration);
                    data['minimumCharginduration'] = parseInt(this.state.fields.minimumChargingDuration);
                    data['reporting'] = this.state.isReportingChecked;
                }
            } else {
                isValid = false;
                this.setState({
                    siteValidationError : this.props.t('mandatory_validation_error')
                }, ()=>{
                    this.toggleFieldValidations();
                });
            }
        }

        if (isValid) {
            let type = ApiMethodTypes.PUT;
            let headers = getSecurityHeaders();
            let response = await APICallUtility.cpmsAPIFetch(url, type, data, headers, this.props.t);
            if (response) {
                if (response.status === 409 || response.status === 400 || response.status === 403 || response.status === 404) {
                    if (response.message === 'group_name_already_exists' || response.status === 400) {
                        this.setState({
                            siteValidationError: this.props.t('group_name_already_exists')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if (response.message === 'package_charger_limit_exceeded'){
                        this.setState({
                            siteValidationError: this.props.t('package_charger_limit_exceeded')
                        }, () => {
                            this.toggleFieldValidations();
                        });
                    } else if(response.message !== ''){
                        this.setState({
                            siteValidationError: this.props.t(response.message)
                        }, () => {
                            this.toggleFieldValidations();
                        }); 
                    }
                } else {
                    this.setState({
                        siteSucess: this.props.t('group_updated_sucesfuly')
                    }, () => {
                        this.toggleSiteSuccess();
                    });
                }
            }
        }
    }

    async wallboxSearch(siteId, chargePointId){
        let url  = `${baseUrl.URLPath}${servicesUrl.updateSitemanagementWallbox}?siteid=${siteId}&chargePoint=${chargePointId}`
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders(); 
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        if(response.length > 0){
            this.setState({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    availableWallBoxes: response
                }
            }) 
        }else {
            this.setState({
                ...this.state,
                noWallboxExists: this.props.t('wallbox_not_found')
            });
        }
       
    }

    handleEmailChange(e) {
        $("#empty_users").text("")
        this.setState({
            email: e.target.value
        }, () => {
            if (this.state.email.length === 0) {
                this.emailSearch(this.state.fields.id, this.state.email);
            }
        });
    }

    onEmailkeyPress(e) {
        var key = e.key;

        if (e.keyCode === 13) {
            this.setState({ email: e.target.value, inviteEmail: e.target.value }, ()=>{
                this.emailSearch(this.state.fields.id, this.state.email);
            });
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9!#$%&'*+-/=^_`{|}~.@]");
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    updatedata(selected) {
        let fields = this.state.fields;
        fields.selectedItems = selected.selectedItems;
        fields.deletedWallboxes = selected.deletedWallboxes;

        this.setState({ fields });
    }

    onChangeCurrency() {
        let currencySymbol = document.getElementById('currency').value;
        let currencyArray = currencySymbol?.split('_');

        this.setState({
            currency: currencyArray[0],
            CostPerUsageValue: '',
            CostPerSessionValue: '',
            currencyCode: currencyArray[1]
        })
    }

    onChangeCostPerSessionActive() {
        this.setState({
            isCostPerSessionActive: !this.state.isCostPerSessionActive
        }, () => {
            if (this.state.isCostPerSessionActive) {
                this.setState({ CostPerSessionValue: 0 });
            }
        })
    }

    onChangeCostBasedonUsageActive() {
        this.setState({
            isCostBasedonUsageActive: !this.state.isCostBasedonUsageActive
        }, () => {
            if (this.state.isCostBasedonUsageActive) {
                this.setState({ CostPerUsageValue: 0 })
            }
        })
    }

    onChangeCostPerUsageValue(e) {
        if (this.state.europeStates.includes(this.state.currencyCode)) {
            if (/^[0-9]*(\,[0-9]{0,3})?$/.test(e.target.value)) {
                let error = this.state.errors;
                error['costPerUsageError'] = '';

                this.setState({
                    CostPerUsageValue: e.target.value,
                    errors: error
                });
            }
        } else {
            if (/^[0-9]*(\.[0-9]{0,3})?$/.test(e.target.value) && e.target.validity.valid) {
                let error = this.state.errors;
                error['costPerUsageError'] = '';

                this.setState({
                    CostPerUsageValue: e.target.value,
                    errors: error
                });
            }
        }
    }

    onChangeCostPerSessionValue(e) {
        if (this.state.europeStates.includes(this.state.currencyCode)) {
            if (/^[0-9]*(\,[0-9]{0,2})?$/.test(e.target.value)) {
                let error = this.state.errors;
                error['costPerSessionError'] = '';

                this.setState({
                    CostPerSessionValue: e.target.value,
                    errors: error
                });
            }
        } else {
            if (/^[0-9]*(\.[0-9]{0,2})?$/.test(e.target.value) && e.target.validity.valid) {
                let error = this.state.errors;
                error['costPerSessionError'] = '';

                this.setState({
                    CostPerSessionValue: e.target.value,
                    errors: error
                });
            }
        }
    }

    updateDemotedAndPromotedUsersList(demotedList, promotedList) {
        this.setState({
            ...this.state,
            demotedList: demotedList,
            promotedList: promotedList
        });
    }

    closeCostdetails() {
        this.setState({
            costdetails: !this.state.costdetails
        })
    }

    openCostdetails(costPerSession,costsBasedOnUsage,costCurrencyCode,chargingSessionCurrency) {
        this.setState({
            costdetails: !this.state.costdetails,
            costPerSession:costPerSession,
            costsBasedOnUsage:costsBasedOnUsage,
            costCurrencyCode:costCurrencyCode,
            chargingSessionCurrency:chargingSessionCurrency
        })
    }

    createQRCode(id) {
        let errors = {}
        errors['energyLimiterr'] = '';
        errors["daysLimiterr"] = ''	;
        this.setState({ ...this.state, addQRCodePopup: true, QRSiteId:id ,  duplicateqrcodevalue:"", energylimit:"", dayslimit:"", isLimitEnergyusageActive:false, isLimitPeriodOfUseActive:false , errors:errors })
    }

    closeaddQRCodePopup() {
        this.setState({ addQRCodePopup: !this.state.addQRCodePopup })
    }

    openSetUpQRCode1() {
        this.setState({ setUpQRCode1: !this.state.setUpQRCode1 });
        this.setState({ addQRCodePopup: !this.state.addQRCodePopup });
    }

    closeSetUpQRCode1() {
        this.setState({ setUpQRCode1: !this.state.setUpQRCode1 })
    }

    async openSetUpQRCode2(event) {	
        event.preventDefault();	
        let errors = {};
        var energyLimit = this.state.energylimit;	
        var daysLimit = this.state.dayslimit;	
        var energyLimitUsage = this.state.isLimitEnergyusageActive;
        var periodOfUse = this.state.isLimitPeriodOfUseActive;
        if(energyLimitUsage || periodOfUse) {	
            errors['energyLimiterr'] = ''
            errors["daysLimiterr"] = ''	
            if(energyLimitUsage) {
                if(energyLimit === "" && energyLimitUsage) {
                    errors["energyLimiterr"] = this.props.t('please_enter_energy_limit');
                } else {
                    this.setState({
                        energylimit: energyLimit,
                        errors: errors	
                    });                
                    this.setState({ setUpQRCode2: !this.state.setUpQRCode2 });	
                    this.setState({ setUpQRCode1: !this.state.setUpQRCode1 });
                }
            }
            if(periodOfUse) {
                if(daysLimit === "" && periodOfUse) {	
                    errors["daysLimiterr"] = this.props.t('please_enter_noof_days');
                } else {
                    this.setState({
                        dayslimit: daysLimit,
                        errors: errors	
                    });                
                    this.setState({ setUpQRCode2: !this.state.setUpQRCode2 });	
                    this.setState({ setUpQRCode1: !this.state.setUpQRCode1 });
                }
            }
        } else {
            this.setState({ setUpQRCode2: !this.state.setUpQRCode2 });	
            this.setState({ setUpQRCode1: !this.state.setUpQRCode1 });
        }
        this.setState({ errors: errors });	
    }

    closeSetUpQRCode2() {
        this.setState({ setUpQRCode2: !this.state.setUpQRCode2 })
    }

    openSetUpQRCode3() {
        let duplicateValue = this.state.duplicateqrcodevalue;	
        let errors = {}	
        if (duplicateValue === '') {	
            errors["duplicateQRCodeerr"] = this.props.t('please_enter_noof_duplicates');	
            this.setState({ errors: errors })	
        }	
        else {	
            if (duplicateValue <= 100) {
                this.setState({	
                    duplicateqrcodevalue: duplicateValue	
                });	
                this.setState({ setUpQRCode3: !this.state.setUpQRCode3 });	
                this.setState({ setUpQRCode2: !this.state.setUpQRCode2 });	
            }	
            else {
                errors["duplicateQRCodeerr"] = this.props.t('please_enter_0_to_100');	
                this.setState({ errors: errors })	
            }	
        }	
    }

    closeSetUpQRCode3() {
        this.setState({setUpQRCode3:!this.state.setUpQRCode3})	
        this.exportQRCode();	
    }

    exportQRCode(){
        document.getElementById("loader_image_div").style.display = "block";
            let duplicate_value = this.state.duplicateqrcodevalue;
            let energyLimit = this.state.energylimit;
            let daysLimit = this.state.dayslimit;
            let id = this.state.QRSiteId;
            let isLimitEnergyusageActive = this.state.isLimitEnergyusageActive;
            let enableExpiryInDays = this.state.isLimitPeriodOfUseActive;

            var url = baseUrl.URLPath + servicesUrl.exportQRcode;
            var bodydata = {
                    "energyLimit":energyLimit ? parseFloat(energyLimit) : 0 ,
                    "expiryInDays": daysLimit ? parseFloat(daysLimit) : 0 , 
                    "noOfCopies": duplicate_value ? parseFloat(duplicate_value) : 0 ,
                    "siteId": id,
                    "enableEnergyLimitUsage":isLimitEnergyusageActive,
                    "enableExpiryInDays":enableExpiryInDays
            }
            fetch(url, {
                method: "POST",
                body: JSON.stringify(bodydata),
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization"),
                }
            }).then((resp) => {
            if (resp.status === 401) {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    window.location.reload()
                }, 100);
            }else if(resp.status === 400){
                document.getElementById("loader_image_div").style.display = "none";
                 return resp.json();
            }else if(resp.status === 403){
                document.getElementById("loader_image_div").style.display = "none";
                return resp.json();
            }else if (resp.status === 200) {
                return resp.blob();
            }
            }).then((response) => {
                if (response !== undefined) {
                    if(response.status===400 || response.status===403 ){
                        this.toggQrCodeModel(); 
                        let qr_warning_msg = "";
                       if(response.message==="energy_limit_must_be_positive_and_max_value"){
                         qr_warning_msg = this.props.t('energy_limit_must_be_positive_and_max_value')
                       }else if(response.message==="expire_in_days_must_be_positive_and_max_value"){
                        qr_warning_msg = this.props.t('expire_in_days_must_be_positive_and_max_value')
                       }else if(response.message==="number_of_copies_limit"){
                        qr_warning_msg = this.props.t('number_of_copies_limit')
                       }else if(response.message==="site_no_chargers"){
                        qr_warning_msg = this.props.t('site_no_chargers')
                       }else if(response.message === "group_owner_detail_not_exist"){
                        qr_warning_msg = this.props.t('group_owner_detail_not_exist')
                       }
                       this.setState({
                        qr_warning_msg:qr_warning_msg
                       })
                    }else{
                        let d = new Date();
                        let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                        let url = window.URL.createObjectURL(response);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'QRCode_' + dformat + '.pdf';
                        a.click();
                        this.setState({
                            QRSiteId:""
                        })
                    }
                    document.getElementById("loader_image_div").style.display = "none";
                }
            }).catch((error) => {
                document.getElementById("loader_image_div").style.display = "none";
                console.log(error, "catch the loop")
            })
        }

    onChangeLimitEnergyusageActive() {	
        this.setState({
            isLimitEnergyusageActive: !this.state.isLimitEnergyusageActive
        }, () => {	
            if (!this.state.isLimitEnergyusageActive) {	
                let error = this.state.errors;	
                error['energyLimiterr'] = ''	
                this.setState({	
                    energylimit: '',	
                    errors: error	
                });
            }	
        })	
    }	

    onChangeLimitEnergyusageValue(e) {	
        let energyLimit = e.target.value;	
        let error = this.state.errors;
        if(e.target.validity.valid){
        if (energyLimit === '' || (/^\d*$/.test(energyLimit) && energyLimit >= 1 && energyLimit <= 1000)) {	
            error['energyLimiterr'] = ''	
                this.setState({	
                    energylimit: energyLimit,	
                    errors: error	
                });	            	
        }	else {	
            error["energyLimiterr"] = this.props.t('please_enter_1_to_1000');	
            this.setState({ errors: error })	
        }
    }
    }

    onChangeLimitPeriodOfUseActive() {
        this.setState({
            isLimitPeriodOfUseActive: !this.state.isLimitPeriodOfUseActive
        }, () => {
            if (!this.state.isLimitPeriodOfUseActive) {
                let error = this.state.errors;
                error['daysLimiterr'] = ''
                this.setState({	
                    dayslimit: '',	
                    errors: error	
                });
            }
        })
    }

    onChangeLimitPeriodOfUseValue(e) {
        let daysLimit = e.target.value;	
        let error = this.state.errors;
        if(e.target.validity.valid){
        if (daysLimit === '' || (/^\d*$/.test(daysLimit) && daysLimit >= 1 && daysLimit <= 1000)) {
            error['daysLimiterr'] = ''
                this.setState({	
                    dayslimit: daysLimit,	
                    errors: error	
                });		
        } else {	
            error["daysLimiterr"] = this.props.t('please_enter_1_to_1000');	
            this.setState({ errors: error })	
        }
    }
    }

    handleEnergyLimit (e) {
        let energyLimit = e.target.value;
        if (e.key === "Backspace" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
          this.setState({
            energylimit: energyLimit.slice(0, 1)
          });
        }
        if (e.key === "Delete" && (energyLimit.length === 2 || energyLimit.length === 3) && energyLimit.startsWith("0")) {
          this.setState({
            energylimit: energyLimit.slice(1, 2)
          });
        }
      };
      handlePeriodOfUse (e) {
        let daysLimit = e.target.value;	
        if (e.key === "Backspace" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
          this.setState({
            dayslimit: daysLimit.slice(0, 1)
          });
        }if (e.key === "Delete" && (daysLimit.length === 2 || daysLimit.length === 3) && daysLimit.startsWith("0")) {
          this.setState({
            dayslimit: daysLimit.slice(1, 2)
          });
        }
      };

    // onChangeLimitChargingSessionsActive() {
    //     this.setState({
    //         isLimitChargingSessionsActive: !this.state.isLimitChargingSessionsActive
    //     }, () => {
    //         if (this.state.isLimitChargingSessionsActive) {
    //             this.setState({
    //                 sessionlimit: '0'
    //             })
    //         }
    //     })
    // }

    // onChangeLimitChargeSessionValue(e) {
    //     if (/^[0-9]*(\,[0-9]{0,2})?$/.test(e.target.value)) {
    //         let error = this.state.errors;
    //         error['costPerSessionError'] = ''
    //         this.setState({
    //             sessionlimit: e.target.value,
    //             errors: error
    //         });
    //     }
    //     else {
    //         if (/^[0-9]*(\.[0-9]{0,2})?$/.test(e.target.value) && e.target.validity.valid) {
    //             let error = this.state.errors;
    //             error['costPerSessionError'] = ''
    //             this.setState({
    //                 sessionlimit: e.target.value,
    //                 errors: error
    //             });
    //         }
    //     }
    // }

    onChangeDuplicateQRCodesValue(e) {
        let duplicateValue = e.target.value;
        let errors = {}

        if(e.target.validity.valid) {
            if (duplicateValue === '' || (/^\d*$/.test(duplicateValue) && duplicateValue >= 1 && duplicateValue <= 100)) {
                let error = this.state.errors;
                error['duplicateQRCodeerr'] = ''
                    this.setState({
                        duplicateqrcodevalue: duplicateValue,
                        errors: error
                    });
            } else {
                errors["duplicateQRCodeerr"] = this.props.t('please_enter_1_to_100');
                this.setState({ errors: errors })
            }
        }
    }
    	

    changeReservationStatus(){	
        let fields = this.state.fields;	
        fields['reservationStatus'] = true;	
        fields['qrCodeAccessStatus'] = false;	
        this.setState({	
            fields	
        });	
    }	
    changeQRCodeStatus(){	
        let fields = this.state.fields;	
        fields['reservationStatus'] = false;	
        fields['qrCodeAccessStatus'] = true;	
        this.setState({	
            fields	
        });	
    }

    changeenergylimitationStatus(e){	
        let fields = this.state.fields;	
        fields['energylimitationStatus'] = e.target.checked;	
        this.setState({	
            fields	
        });	
    }
    toggQrCodeModel(){
        this.setState({QrCodeModel:!this.state.QrCodeModel})	
    }

    onDeleteUserGroup(id) {
        this.setState({ ...this.state, selectedUserGroup: id, modalDeleteUserGroup: true });
    }

    toggleDeleteUserGroup() {
        this.setState({ ...this.state, modalDeleteUserGroup: !this.state.modalDeleteUserGroup })
    }

     async onConfirmDeleteUserGroup(){
        this.toggleDeleteUserGroup();
        let url =`${baseUrl.URLPath}${servicesUrl.deletesiteUserGroupList}?siteId=${this.state.siteId.id}&userGroupId=${this.state.selectedUserGroup}`;
        let type = ApiMethodTypes.DELETE;
        let headers = getSecurityHeaders(); 
        let payload={}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);

        if(response.status === 403){
            APICallUtility.renderAccessDenied(this.props.t);
            this.setState({ modalDeleteUserGroup: false })
        } else if (response.message === "user_group_deleted_success"){
            this.setState({
                isUserGroupDeleteSuccess:!this.state.isUserGroupDeleteSuccess
            })
            this.setState({ ...this.state, userGroupDeleteSucess: this.props.t('user_group_deleted_success') });
        }
     }

     toggleUserGroupDeleteSuccess() {
        this.getUserGroupListData(this.state.siteId);
        this.setState({ ...this.state, isUserGroupDeleteSuccess: !this.state.isUserGroupDeleteSuccess })
    }
     async getGroupUserList(){
        let url =`${baseUrl.URLPath}${servicesUrl.getGroupsUserList}?pageSize=${this.state.pageSizeGroup}&pageNo=${this.state.activePageGroup}&siteId=${this.state.fields.id}`;
        let type = ApiMethodTypes.GET;
        let headers = getSecurityHeaders();
        let payload = {}
        let response = await APICallUtility.cpmsAPIFetch(url, type, payload, headers, this.props.t);
        let fields = this.state.fields;
        fields.users = response.users;
        let noOfRecordsGroup = response.count.count;
        localStorage.setItem('usersFromApi', JSON.stringify(response.users));
        if (response) {
            this.setState({
                fields: fields,
                noOfRecordsGroup:noOfRecordsGroup
            })
        }
     }

    render() {
        
        const{t, customizationSettings}=this.props;

        let currecyList = this.state.currencyList?.map((country,index)=>{
            return <option value={country.currencysymbol+'_'+country.currencyCode} key={`currency_${index}`} style={{fontWeight:'bold'}}>{country.currencyCode}</option>
        });

        let userGroupsList = this.state.userGroupsData.map((user, index) => {
            return <option value={user.id} key={user.id} style={{fontWeight:'bold'}}>{user.name}</option>
    })

        let isReservationActive = this.state.fields.ownerQrStatus && this.state.fields.reservationStatus;       
        const userGroupsData = this.state.userGroupsData.map((user, index) => {
      
            return (
              <tr key={user.id}>
                <td className="align-middle">{user.name}</td>
                <td className="align-middle text-center">{user.count}</td>                
                <td className="align-middle text-center">
                    <button className="btn_primary mr-2" type="button" onClick={() => this.setState({isNewUserGroup: false}, () => this.toggleAddUserGroups(user))}> {t('edit')}</button>
                    { user.default ? "" : 
                    ((localStorage.getItem('tenant') === 'webasto') ? <button className="btn_secondary mr-2" type="button" onClick={() => this.onDeleteUserGroup(user.id)}> {t('delete')}</button> : <></>) }
                </td>
              </tr>
            )
      
          })
        return (
            <main className="content-div">
                <p>{t('Group Management')}</p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12">
                            {this.props.Sitemanagement_W &&
                                <button type="button" data-test="button" onClick={this.addNewSite.bind(this)} data-toggle="modal" data-target="#operationsModal">
                                    <i className="fa fa-plus"></i>
                                    <span>{t('add')}</span>
                                </button>
                            }
                            <div className="pull-right">
                                <label className="sub-text pull-left" >{t('show_entries')}</label>
                                <Form.Group className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" onChange={this.showEntriesChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pull-right">
                            <div className="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    options={this.state.searchSuggestions}
                                    id="rbt_id"
                                    minLength={3}
                                    placeholder={t('search_group')}
                                    onKeyDown={this.handleKeyDown}
                                    ref={this.typeAHeadRef}
                                    onChange={this.onChangeTypeahead}
                                    onBlur={this.onBlurEvent}
                                />

                                <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.clearSearchSuggestions}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="input-group-btn">
                                    <button className="input-searchBtn find-btn" type="submit" onClick={() => {
                                        if (this.state.searchStr === "" || this.state.searchStr === null) {
                                            $('.empty_site').text(t('please_enter_group_name'))
                                        } else {
                                            $('.empty_site').text("")
                                            this.searchSite();
                                        }
                                    }}>{t('find')}</button>
                                </div>
                                <span className="pull-left error_msg w3-animate-top mt-1 empty_site" style={{ color: "red" }}></span>
                                {
                                    this.state.isSearchNotFound && <span className="pull-left error_msg w3-animate-top mt-1">
                                        <span style={{ color: "red" }}>{t('group_not_exists')}</span>
                                    </span>
                                }
                            </div>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Table striped hover size="sm" className="page-table">
                                <tbody>
                                    {
                                        (this.state.availableSites.sites !=="" && this.state.availableSites.sites !==undefined && this.state.availableSites.sites !==null ) ? 
                                        this.state.availableSites.sites?.map((site, index) => {
                                            const isEmpty = !site.onlineCPCount && !site.oflineCPCount;
                                            const cpCount = site.onlineCPCount + site.oflineCPCount;
                                            let total = 0;
                                            let perOnline = 0;
                                            let perOffline = 0;
                                            if (!isEmpty) {
                                                total = site.oflineCPCount + site.onlineCPCount;
                                                perOnline = (site.onlineCPCount / total) * 100;
                                                perOffline = (site.oflineCPCount / total) * 100;
                                                perOffline = perOffline.toFixed();
                                                perOnline = perOnline.toFixed();
                                            }
                                            return ( 
                                                (site.status === false) ? 
                                                <>
                                                <tr key={index} className="limited-group">
                                                    <td className="align-middle text-center">
                                                        <h6>#{(this.state.activePage - 1) * this.state.pageSize + index + 1}</h6>
                                                    </td>
                                                    <td className="align-middle group-name">
                                                        <div className="d-flex flex-column">
                                                            <h4 style={{ cursor: 'pointer' }} className="site-link" onClick={() => this.switchSite(site)}>{(site?.name?.length >= 28) ? site?.name?.substring(0, 25) + "..." : site?.name}</h4>
                                                            <span>{site.address}</span>
                                                            <span>{site.zipcode} {site.city}</span>
                                                        </div>
                                                    </td>
                                                    <td>{site.level}</td>
                                                    <td className="align-middle">
                                                        <div className="d-flex align-items-center">
                                                            <div style={{ height: '60px', width: '60px', position: 'relative', top: '-5px' }}>
                                                                <Doughnut data={this.getDoughnutData(site)} options={{
                                                                    responsive: true,
                                                                    tooltips: {
                                                                        enabled: false
                                                                    }
                                                                }} height={60} width={60} />
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <span className="text-success">{t('online')} {perOnline} %</span>
                                                                <span>{t('offline')} {perOffline} %</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/Wallbox.png"} alt="Wallbox" style={{ maxHeight: '50px', marginTop: "14px" }} />
                                                        { localStorage.getItem("tenant") === "webasto" ?
                                                          <h4>{site.ownerChargersLimit === -1 ? <span> {cpCount} / &infin; </span> : cpCount + "/" + site.ownerChargersLimit}
                                                         </h4>
                                                         : <h4>{cpCount}</h4>
                                                         }
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex flex-column">
                                                            <ReactSpeedometer
                                                                minValue={0}
                                                                maxValue={5000}
                                                                value={site.totalPower || 0}
                                                                needleColor="#00529e"
                                                                startColor="#00529e"
                                                                segments={1}
                                                                maxSegmentLabels={0}
                                                                endColor="#00529e"
                                                                width={100}
                                                                height={100}
                                                                ringWidth={5}
                                                                dimensionUnit="px"
                                                                currentValueText={`${site.totalPower || 0} kW`}
                                                                paddingHorizontal={5}
                                                                needleHeightRatio={0.45}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/users.png"} alt="users" className='usersIcon' />
                                                        <h3>{site.usersCount ? site.usersCount : 0}</h3>
                                                    </td>
                                                    {/* <td className="align-middle text-center">
                                                        {t('cost_details')} <i className="fa fa-info-circle iIcon" aria-hidden="true" ></i>
                                                    </td> */}
                                                    <td className="align-middle text-center">
                                                        {t('user_groups')}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <button className="btn_primary mr-2" type="button" onClick={() => this.editSite(site)} style={{zIndex: "9", position: "relative"}}> {t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                                                        <button className="btn_secondary mr-2" type="button" onClick={() => this.onDeleteSite(site)} style={{zIndex: "9", position: "relative"}}> {t('delete')} <i class="fas fa-trash-alt ml-2"></i></button>
                                                    </td>
                                                </tr> 
                                                <tr className="masked-row">
                                                    <td colSpan="9" className="align-middle" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <div className="limit_message">
                                                        <div className="limit_icon"><img src={process.env.PUBLIC_URL + "/assets/img/svg/limit_group_icon.svg"} className="limit_group_icon" alt="" /></div>
                                                        <div className="limit_content">
                                                            <h5>{t('this_group_is_deactivated')}</h5>
                                                            <span>{t('maximum_number_of_groups_has_been_reached')}<br />
                                                            {t('premium_package_for_your_account_to_reactivate_the_group')}</span>
                                                        </div>                                                  
                                                    </div>
                                                    </td>
                                                </tr>
                                                </>
                                                : 
                                                <tr key={index}>
                                                    <td className="align-middle text-center">
                                                        <h6>#{(this.state.activePage - 1) * this.state.pageSize + index + 1}</h6>
                                                    </td>
                                                    <td className="align-middle group-name">
                                                        <div className="d-flex flex-column">
                                                            <h4 style={{ cursor: 'pointer' }} className="site-link" onClick={() => this.switchSite(site)}>{(site?.name?.length >= 28) ? site?.name?.substring(0, 25) + "..." : site?.name}</h4>
                                                            <span>{site.address}</span>
                                                            <span>{site.zipcode} {site.city}</span>
                                                        </div>
                                                    </td>
                                                    <td>{site.level}</td>
                                                    <td className="align-middle">
                                                        <div className="d-flex align-items-center">
                                                            <div style={{ height: '60px', width: '60px', position: 'relative', top: '-5px' }}>
                                                                <Doughnut data={this.getDoughnutData(site)} options={{
                                                                    responsive: true,
                                                                    tooltips: {
                                                                        enabled: false
                                                                    }
                                                                }} height={60} width={60} />
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <span className="text-success">{t('online')} {perOnline} %</span>
                                                                <span>{t('offline')} {perOffline} %</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/Wallbox.png"} alt="Wallbox" style={{ maxHeight: '50px', marginTop: "14px" }} />
                                                        { localStorage.getItem("tenant") === "webasto" ?
                                                          <h4>{site.ownerChargersLimit === -1 ? <span>{cpCount} / &infin;</span> : cpCount+ "/" + site.ownerChargersLimit}
                                                         </h4>
                                                         : <h4>{cpCount}</h4>
                                                         }
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex flex-column">
                                                            <ReactSpeedometer
                                                                minValue={0}
                                                                maxValue={5000}
                                                                value={site.totalPower || 0}
                                                                needleColor="#00529e"
                                                                startColor="#00529e"
                                                                segments={1}
                                                                maxSegmentLabels={0}
                                                                endColor="#00529e"
                                                                width={100}
                                                                height={100}
                                                                ringWidth={5}
                                                                dimensionUnit="px"
                                                                currentValueText={`${site.totalPower || 0} kW`}
                                                                paddingHorizontal={5}
                                                                needleHeightRatio={0.45}
                                                            />
                                                        </div>
                                                    </td> 
                                                    <td className="align-middle text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/users.png"} alt="users" className='usersIcon' />
                                                        <h3>{site.usersCount ? site.usersCount : 0}</h3>
                                                    </td>
                                                    {/* <td className="align-middle text-center">
                                                        {t('cost_details')} <i className="fa fa-info-circle iIcon" onClick={() => this.openCostdetails(site.costsPerChargingSession, site.costsPerKwh, site.currencyCode, site.chargingSessionCurrency)} aria-hidden="true" ></i>
                                                    </td> */}                                                    
                                                    <td className="align-middle text-center">
                                                        <button onClick={() => this.openUserGroups(site)} className="newui-blue-btn">{t('user_groups')}</button>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <button className="btn_primary mr-2" type="button" onClick={() => this.editSite(site)}> {t('edit')} <i className="fas fa-pencil-alt ml-2"></i></button>
                                                        <button className="btn_secondary mr-2" type="button" onClick={() => this.onDeleteSite(site)}> {t('delete')} <i className="fas fa-trash-alt ml-2"></i></button>
                                                        { /* { site.qrCodeStatus ? <button className="btn_primary mr-2" type="button" onClick={this.createQRCode.bind(this,site.id)} data-toggle="modal" data-target="#operationsModal"> {t('create_qr_code')} <i class="fa fa-qrcode ml-2"></i></button> :"" } */}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        ) : ""
                                         
                                    }
                                    {
                                        (this.state.availableSites.sites.length === 0) ?
                                            <tr>
                                                <td colSpan={7} className="align-middle text-center">
                                                    {
                                                        (localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS || localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || localStorage.getItem('role') === roles.WEBASTO_CUSTOMER_ADMIN) ?
                                                            <>{t('no_groups_available')}</> : <>{t('You_see_if_you_are_admin_of_a_group')}</>
                                                    }
                                                </td>
                                            </tr> : null
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <MDBModal isOpen={this.state.modalAddSite} toggle={this.toggleAddPopup} size="lg">
                        <MDBModalHeader toggle={this.toggleAddPopup} className="pl-4 mt-3">
                            <h4>
                                {this.state.isNewSite ? t('add_new_group') : t('edit_group')}
                            </h4>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBNav className=" mt-1 p-rel border-0">
                                <MDBNavItem className="nav-first">
                                    <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 0 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 0)}>{t('Settings')}</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 1 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 1, this.state.fields.id)}>{t('wallboxes')}</MDBNavLink>
                                </MDBNavItem>
                                {
                                    this.state.isNewSite ? <></> : 
                                        <MDBNavItem className={(this.state.isNewSite || isReservationActive) ? '': 'nav-last'}>
                                            <MDBNavLink to="#" className={`b-75 pl-4 pr-4 ${this.state.selectedTab === 2 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 2)}>{t('Users')}</MDBNavLink>
                                        </MDBNavItem>
                                }
                               {this.state.isNewSite ? 
                                <MDBNavItem className={(this.state.isNewSite) ? 'nav-last': ''}>
                                    <MDBNavLink to="#" className={`b-75 pl-4 pr-4 ${this.state.selectedTab === 3 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 3)}>{t('user_group')}</MDBNavLink>
                                </MDBNavItem> : <></>  }
                                {
                                    this.state.isNewSite ? <></> :
                                    isReservationActive && <MDBNavItem className="nav-last">
                                            <MDBNavLink to="#" className={`b-75 pl-4 pr-4 ${this.state.selectedTab === 4 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 4)}>{t('reservation_rules')}</MDBNavLink>
                                        </MDBNavItem>
                                }
                            </MDBNav>
                            {
                                this.state.selectedTab === 0 &&
                                <NewSiteSettings
                                    cancel={this.toggleAddPopup}
                                    country = {this.state.fields.country}
                                    name={this.state.fields.name}
                                    city={this.state.fields.city}
                                    zipcode={this.state.fields.zipcode}
                                    address={this.state.fields.address}
                                    updateSiteSettings={this.updateSiteSettings}
                                    isNewSite={this.state.isNewSite}
                                    id={this.state.fields.id}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    reservationStatus={this.state.fields.reservationStatus}	
                                    qrCodeAccessStatus={this.state.fields.qrCodeAccessStatus}
                                    energylimitationStatus = {this.state.fields.energylimitationStatus}
                                    ownerQrStatus ={this.state.fields.ownerQrStatus}
                                    changeReservationStatus = {this.changeReservationStatus}	
                                    changeQRCodeStatus = {this.changeQRCodeStatus}
                                    countryChange = {this.countryChange}
                                    changeenergylimitationStatus = {this.changeenergylimitationStatus}
                                />
                            }
                            {
                                this.state.selectedTab === 1 &&
                                <NewSiteWallboxes
                                    cancel={this.toggleAddPopup}
                                    isNewSite={this.state.isNewSite}
                                    selectedItems={this.state.fields.selectedItems}
                                    btnAction={this.state.btnAction}
                                    id={this.state.fields.id}
                                    removeSelectedItem={this.removeSelectedItem}
                                    availableWallBoxes={this.state.fields.availableWallBoxes}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    wallboxSearch={this.wallboxSearch}
                                    noWallboxExists={this.state.noWallboxExists}
                                    clearWallboxerror={this.clearWallboxerror}
                                    wallbox={this.state.wallbox}
                                    handleWallboxChange={this.handleWallboxChange}
                                    handleWallboxPress={this.handleWallboxPress}
                                    updatedata={this.updatedata}
                                />
                            }
                            {
                                this.state.selectedTab === 2 && !this.state.isNewSite &&
                                <NewSiteUsers
                                    activePage = {this.state.activePageGroup}
                                    pageSize = {this.state.pageSizeGroup}
                                    noOfRecords = {this.state.noOfRecordsGroup}
                                    cancel={this.toggleAddPopup}
                                    users={this.state.fields.users}
                                    handleUserPageChange={this.handleUserPageChange}
                                    isAccessChecked={this.isAccessChecked}
                                    isCreatedBy={this.state.isCreatedBy}
                                    isAdminChecked={this.isAdminChecked}
                                    onChangeAccessCheckbox={this.onChangeAccessCheckbox}
                                    onChangeAdminCheckbox={this.onChangeAdminCheckbox}
                                    updateExistingSite={this.updateExistingSite}
                                    id={this.state.fields.id}
                                    emailSearch={this.emailSearch}
                                    emailError={this.state.emailError}
                                    displayInvite={this.state.displayInvite}
                                    inviteEmail={this.inviteEmail}
                                    hideinvite={this.toggleDisplayInvite}
                                    getUsers={this.getUsers}
                                    handleEmailChange={this.handleEmailChange}
                                    email={this.state.email}
                                    onpress={this.onEmailkeyPress}
                                    invite_email={this.state.inviteEmail}
                                    onChangeInvitemail={this.onchangeInviteMail}
                                    demotedList={this.state.demotedList}
                                    promotedList={this.state.promotedList}
                                    updateDemotedAndPromotedUsersList={this.updateDemotedAndPromotedUsersList}
                                    toggleInviteUser={this.toggleInviteUser}
                                    userGroupsData = {this.state.userGroupsData}
                                    onChangeUserGroup = {this.onChangeUserGroup}
                                />
                            }
                            {
                                this.state.selectedTab === 3 &&
                                <NewSiteCosts
                                    cancel={this.toggleAddPopup}
                                    isNewSite={this.state.isNewSite}
                                    currency={this.state.currency}
                                    currencyCode={this.state.currencyCode}
                                    isCostPerSessionActive={this.state.isCostPerSessionActive}
                                    isCostBasedonUsageActive={this.state.isCostBasedonUsageActive}
                                    CostPerSessionValue={this.state.CostPerSessionValue}
                                    CostPerUsageValue={this.state.CostPerUsageValue}
                                    onChangeCurrency={this.onChangeCurrency}
                                    onChangeCostPerSessionActive={this.onChangeCostPerSessionActive}
                                    onChangeCostBasedonUsageActive={this.onChangeCostBasedonUsageActive}
                                    onChangeCostPerSessionValue={this.onChangeCostPerSessionValue}
                                    onChangeCostPerUsageValue={this.onChangeCostPerUsageValue}
                                    currencyList={this.state.currencyList}
                                    currecyCodeList={this.state.currecyCodeList}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    id={this.state.fields.id}
                                    errors={this.state.errors}
                                    usergroupname={this.state.usergroupname}
                                    onChangeUserGroupName={this.onChangeUserGroupName}

                                />
                            }
                            {
                                this.state.selectedTab === 4 && !this.state.isNewSite && isReservationActive  &&
                                <NewSiteReservationRules
                                    errors = {this.state.errors}
                                    cancel={this.toggleAddPopup}
                                    isNewSite={this.state.isNewSite}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    id={this.state.fields.id}
                                    updateSiteSettings={this.updateSiteSettings}
                                    changeReportingChecked = {this.changeReportingChecked}
                                    guarantedSlotDuration = {this.state.fields.guarantedSlotDuration}
                                    cooldown = {this.state.fields.cooldown}
                                    isReportingChecked = {this.state.isReportingChecked}
                                    minimumChargingDuration = {this.state.fields.minimumChargingDuration}
                                />
                            }
                        </MDBModalBody>
                    </MDBModal>
                </div>

                <MDBModal isOpen={this.state.costdetails} size="sm" className="model_top costsModelWidth ">
                    <MDBModalHeader><label className="fs-16"><b>{t('cost_details')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="w-100 mt-3 row">
                            <label className="pl-3 col-md-7"> {t('costsPerChargingSession')}: </label> <label> {currencyFormatter.format(this.state.costPerSession, { code: this.state.currencyCode, symbol:'' })} {this.state.chargingSessionCurrency  } </label>
                        </div>
                        <div className="w-100 row">
                            <label className="pl-3 col-md-7"> {t('costs_based_on_usage')}: </label> <label> {currencyFormatter.format(this.state.costsBasedOnUsage, { code: this.state.currencyCode, symbol:'' })} {this.state.chargingSessionCurrency}/{t('kwh')} </label>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.closeCostdetails}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

               {/* User Groups Model */}
               <MDBModal isOpen={this.state.usergroupsmodal} size="md" className="model_top mw-620" toggle={this.closeUserGroupsPopup}>
                <MDBModalBody className='usergroups_popup'>
                    <div className='heading'>
                    <h5 className='modal_title'>{t('user_groups')}</h5>
                    {
                        (localStorage.getItem('tenant') === 'webasto') ?
                        <button className="newui-blue-btn" onClick={() => this.setState({isNewUserGroup: true}, () => this.toggleAddUserGroups())}><span className='plusicon'>+</span> {t('add_user_group')}</button> : 
                        <></>
                    }
                    </div>
                    <div className='flex-col-justify-n-align-center'>
                    <Table striped hover size="sm" className="usergroups-table">
                    <thead>
                        <tr>
                        <th className="align-middle">{t('user_groups_list')}</th>
                        <th className='align-middle text-center'>{t('users')}</th>
                        <th className='align-middle text-center'>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody className='tableScroller'>
                        {userGroupsData}
                    </tbody>
                    </Table>
                        <button type="button" className='text-center newui-close-btn' onClick={this.closeUserGroupsPopup}>{t('close')}</button>
                    </div>
                </MDBModalBody> 
                </MDBModal>

                  {/* User Groups Add/Edit Model */}
                  <MDBModal isOpen={this.state.addUserGroupsPopup} toggle={this.toggleAddUserGroups} size="lg" className="model__top mw-720">
                    <MDBModalBody>
                    <div className='p-3 '>
                        <div className="newPopupHeader">
                            <h5 className='pull-left pb-0'>{this.state.isNewUserGroup ? t('add_user_group') : t('edit_user_group')}</h5>
                        </div>
                        <div className="mb-4 mt-2 w-60">
                        <MDBInput
                          value={this.state.usergroupname}
                          name="userGroupName"
                          onChange={this.onChangeUserGroupName}
                          type="text"
                          id="userGroupName"
                          label={t('user_group_name') + " *"}
                          maxLength={30}
                        >
                        </MDBInput>
                        </div>
                        <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{t('chargingsession_costs')}</div>
                        <div className='mt-1'>{t('chargingsession_costs_description')}</div>
                        <div className='row'>
                            <div style={{fontWeight: 'bold',flex: '0 0 25%', maxWidth: '25%', padding: '15px', fontSize: '15px'}}>{t('select_currency')}</div>
                            <div className="col-md-3 mt-1">
                                <select className="browser-default custom-select select_height" id="currency" value={this.state.currency+"_"+this.state.currencyCode} onChange={this.onChangeCurrency}>
                                    {currecyList}
                                </select>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('costsPerChargingSession')}</label>
                                <label className="switch">
                                    <input type="checkbox" checked={!this.state.isCostPerSessionActive} onChange={this.onChangeCostPerSessionActive} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('costs_based_on_usage')}</label>
                                <label className="switch">
                                    <input type="checkbox" checked={!this.state.isCostBasedonUsageActive} onChange={this.onChangeCostBasedonUsageActive} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                {t('costs_per_session_description')}
                            </div>
                            <div className='col-md-6'>
                            {t('costs_on_usage_description')}
                            </div>
                        </div>
                        <div className='row mt-2 mb-4'>
                            <div className='col-md-6'>
                                <div className="md-form">
                                    <input
                                        id="sessionCost"
                                        type="text"
                                        name="sessionCost"
                                        pattern={noDecimalCurrencies.includes(this.state.currencyCode) ? "[0-9]*" : "[0-9.]*"}
                                        className="form-control pl-0"
                                        value={this.state.CostPerSessionValue}
                                        disabled={this.state.isCostPerSessionActive}
                                        onChange={this.onChangeCostPerSessionValue}
                                        placeholder={t('costsPerChargingSession')}
                                        maxLength={5}
                                    />
                                    <span style={{ color: '#00529e', position: 'relative', fontWeight: 'bold', left: '285px', top: '-42px' }}>{this.state.currencyCode}</span>
                                    <div className="error_msg1 w3-animate-top ml-0" style={{ color: "red", marginTop: '-30px' }}>{this.state.errors['costPerSessionError']}</div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="md-form">
                                    <input
                                        id="usageCost"
                                        type="text"
                                        name="usageCost"
                                        pattern={noDecimalCurrencies.includes(this.state.currencyCode) ? "[0-9]*" : "[0-9.]*"}
                                        className="form-control pl-0"
                                        value={this.state.CostPerUsageValue}
                                        disabled={this.state.isCostBasedonUsageActive}
                                        onChange={this.onChangeCostPerUsageValue}
                                        placeholder={t('costs_based_on_usage')}
                                        maxLength={5}
                                    />
                                    <span style={{ color: '#00529e', position: 'relative', fontWeight: 'bold', left: '252px', top: '-42px' }}>{this.state.currencyCode}/{t('kwh')}</span>
                                    <div className="error_msg1 w3-animate-top ml-0" style={{ color: "red", marginTop: '-30px' }}>{this.state.errors['costPerUsageError']}</div>
                                </div>
                            </div>
                        </div>
                        <div className="newPopupFooter">                       
                            {this.state.isNewUserGroup ?
                                <button className="blueBtnNew createReportBtn" onClick={() => {this.addUserGroup(this.state.siteId)}}>{t('save')}</button>
                                :
                                <button className="blueBtnNew createReportBtn" onClick={this.updateUserGroup}>{t('update')}</button>
                                }
                                <button className="ltblueBtn createReportBtn pull-right" onClick={() => this.setState({isNewUserGroup: true}, () => this.toggleAddUserGroups())}>{t('close')}</button>
                        </div>
                    </div>
                    <div className="error_msg w3-animate-top" style={{ color: "red", textAlign: "center", marginLeft: "130px" }}>{this.state.errors["duplicateQRCodeerr"]}</div>
                    </MDBModalBody>
                </MDBModal>
                

                <MDBModal isOpen={this.state.modal} size="md" className="model_top mw-580">
                <MDBModalBody className='groupdel_popup'>
                    <div className='flex-col-justify-n-align-center'>
                    <img src={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"} width="60" height="60" alt="" />
                    <p className='groupdel-success'>{t('the_group_has_been_deleted_successfully')}</p>
                    <div className='smpopup-btns deativate-btndiv' >
                        <button type="button" className='smpopup-savebtn' style={{width:"35%"}} onClick={this.tagOk}>{t('close')}</button>
                    </div>
                    </div>
                </MDBModalBody> 
                </MDBModal>


                <MDBModal isOpen={this.state.modalDeleteSite} size="md" className="model_top mw-580">
                    <MDBModalBody className='groupdel_popup'>  
                    <div className="delGroupPopupHeader">
                        <img src={process.env.PUBLIC_URL + "/assets/img/svg/close.svg"} className='pull-right closeIcon' onClick={this.toggleDeleteSite} />
                    </div>      
                    <div className='flex-col-justify-n-align-center'>
                    <h3 className='groupdel-popup-h3'>{t('delete_group')}</h3>
                    <p className='groupdel-confirm'>{t('are_you_sure_you_want_to_delete_your_group')} <br/>{t('action_cannot_be_undone')}</p>
                    <div className='smpopup-btns deativate-btndiv'>
                        <button type="button" className='smpopup-yesbtn' onClick={this.onConfirmDeleteSite}><i className="fas fa-trash" style={{paddingRight: "7px"}}></i> {t('yes_delete')}</button>
                        {/* <button type="button" className='smpopup-nobtn ml-3' onClick={this.toggleDeleteSite}>{t('no')}</button> */}
                    </div>
                    </div>
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.modalShowMaximumSites} size="md" className="model_top">
                    <MDBModalHeader>{t('Warning')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('limit_is_reached')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleMaximumSites}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.isfieldvalidate} size="md" className="model_top">
                    <MDBModalHeader>{t('Warning')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.siteValidationError}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleFieldValidations}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.isSiteSuccess} size="md" className="model_top">
                    <MDBModalHeader>{t('success')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.siteSucess}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={() => { window.location.reload(); }}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                {/* QR CODE POPUP */}
                <MDBModal isOpen={this.state.addQRCodePopup} className="model_top">
                    <MDBModalHeader><label class="fs-16"><b>{t('setup_qr_code')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="mt-2 row">
                            <label className="pl-3 pr-3">{t('setup_qr_code_text1')}</label>
                        </div>
                        <div className="mb-2 row">
                            <img src={process.env.PUBLIC_URL + "/assets/img/scan-step.png"} className="qrcode-steps-img" alt='' />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_cancel mr-2 ml-2" onClick={this.closeaddQRCodePopup} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                        <button className="btn_primary mr-4 ml-2" onClick={this.openSetUpQRCode1} type="button" data-test="button">{t('continue')}</button>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.setUpQRCode1} className="model_top">
                    <MDBModalHeader><label class="fs-16"><b>{t('setup_qr_code')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="mt-2 row">
                            <label className="pl-3 pr-3">{t('setup_qr_code_text2')}</label>
                        </div>
                        <div className="mt-2 row energyusage">
                            <div className="col-md-6">
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('limit_energyusage')}</label>
                            </div>

                            <div className="col-md-6">
                                <label class="switch" style={{ float: 'right' }}>
                                    <input type="checkbox" checked={this.state.isLimitEnergyusageActive} onChange={this.onChangeLimitEnergyusageActive} />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div className="mb-2 row pl-3 pr-3">
                                <label className="pl-3 pr-3">{t('energy_is_limited_to_defined_amount')}</label><br />
                                <MDBCol md="5" >
                                    <label className="pull-left sel_lbl">{t('energylimitation')} :</label>
                                </MDBCol>
                                <MDBCol md="5" >
                                    <MDBInput
                                        type="text"
                                        name="energylimit"
                                        id="energyLimit"
                                        value={this.state.energylimit}
                                        disabled={!this.state.isLimitEnergyusageActive}
                                        onChange={this.onChangeLimitEnergyusageValue}
                                        onKeyDown={this.handleEnergyLimit}
                                        placeholder={t('please_enter_energy_limit')}
                                        pattern="[0-9]*"
                                    >
                                        <p style={{ color: '#00529e', fontWeight: 'bold', position: "absolute", top: "10px", right: "-35px" }}>{t('kwh')}</p>
                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["energyLimiterr"]}</span></span>
                                    </MDBInput>
                                </MDBCol>
                            </div>
                        </div>

                        <div className="mt-2 row energyusage">
                            <div className="col-md-6">
                                <label style={{ fontWeight: 'bold', fontSize: '15px', marginRight: '20px' }}>{t('limit_periodofuse')}</label>
                            </div>

                            <div className="col-md-6">
                                <label class="switch" style={{ float: 'right' }}>
                                    <input type="checkbox" checked={this.state.isLimitPeriodOfUseActive} onChange={this.onChangeLimitPeriodOfUseActive} />
                                    <span class="slider round"></span>
                                </label>
                            </div>

                            <div className="mb-2 row pl-3 pr-3">
                                <label className="pl-3 pr-3">{t('qrcode_is_valid_for_period_of_days')}</label><br />
                                <MDBCol md="5" >
                                    <label className="pull-left sel_lbl">{t('periodofuse')} :</label>
                                </MDBCol>
                                <MDBCol md="5" >
                                    <MDBInput
                                        type="text"
                                        name="dayslimit"
                                        id="daysLimit"
                                        value={this.state.dayslimit}
                                        disabled={!this.state.isLimitPeriodOfUseActive}
                                        onChange={this.onChangeLimitPeriodOfUseValue}                                       
                                        onKeyDown={this.handlePeriodOfUse}
                                        placeholder={t('please_enter_noof_days')}
                                        pattern="[0-9]*"
                                    >
                                        <p style={{ color: '#00529e', fontWeight: 'bold', position: "absolute", top: "10px", right: "-45px" }}>{t('days')}</p>
                                        <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["daysLimiterr"]}</span></span>
                                    </MDBInput>
                                </MDBCol>
                            </div>

                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_cancel mr-2 ml-2" onClick={this.closeSetUpQRCode1} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                        <button className="btn_primary mr-4 ml-2" onClick={this.openSetUpQRCode2} type="button" data-test="button">{t('continue')}</button>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.setUpQRCode2} className="model_top">
                    <MDBModalHeader><label class="fs-16"><b>{t('setup_qr_code')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="mt-2 row">
                            <label className="pl-3 pr-3">{t('do_you_want_to_duplicate_qr_codes')}</label>
                        </div>
                        <div className="mb-2 row pl-3 pr-3">
                            <MDBCol md="5" >
                                <label className="pull-left sel_lbl">{t('duplicate_qr_code')} :</label>
                            </MDBCol>
                            <MDBCol md="5" >
                                <MDBInput
                                    type="text"
                                    name="duplicateqrcode"
                                    id="duplicateqrcode"
                                    value={this.state.duplicateqrcodevalue}
                                    onChange={this.onChangeDuplicateQRCodesValue}
                                    placeholder={t('please_enter_noof_duplicates')}
                                    pattern="[0-9]*"
                                >
                                    <p style={{ color: '#00529e', fontWeight: 'bold', position: "absolute", top: "10px", right: "-40px" }}>{t('times')}</p>
                                </MDBInput>
                            </MDBCol>
                        </div>
                        <div className="error_msg w3-animate-top" style={{ color: "red", textAlign: "center", marginLeft: "130px" }}>{this.state.errors["duplicateQRCodeerr"]}</div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="text-right">
                            <button className="btn_cancel mr-2 ml-2" onClick={this.closeSetUpQRCode2} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                            <button className="btn_primary mr-4 ml-2" onClick={this.openSetUpQRCode3} type="button" data-test="button">{t('continue')}</button></div>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.setUpQRCode3} className="model_top">
                    <MDBModalHeader><label class="fs-16"><b>{t('setup_qr_code')}</b></label></MDBModalHeader>
                    <MDBModalBody>
                        <div className="mt-2 row">
                            <label className="pl-3 pr-3">{t('setup_qr_code_text3')}</label><br />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter style={{ justifyContent: "center" }}>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.closeSetUpQRCode3}><b>{t('export_and_close')}</b></button>
                    </MDBModalFooter>
                </MDBModal>

                {/* warning qr code popup */}
                
                <CustomModal
                    open={this.state.QrCodeModel}
                    title={t('Warning')}
                    bodyMessage={t(this.state.qr_warning_msg)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.toggQrCodeModel}
                />

               
                            
              

                {/* Invite User Model */}
                <MDBModal isOpen={this.state.inviteUserModal} toggle={this.toggleInviteUser} size="md" className="model__top mw-550">
                    <MDBModalBody>
                    <div className='p-3 '>
                        <div className="newPopupHeader">
                            <h5 className='pull-left pb-0'>{t('inviteUser')}</h5>
                        </div>
                        <div className="mb-4 mt-2">
                        <MDBInput
                          valueDefault=""
                          name="inviteemailid"
                          onChange={(e) => this.onchangeInviteMail(e)}
                          type="text"
                          id="inviteEmailId"
                          label={t('email') + " *"}
                          className='inviteemailid'
                        >
                          <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></span>                          
                          <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                        </MDBInput>
                        <select className="browser-default custom-select select_height user-groups" onChange={this.onChangeUserGroupId}>
                            <option value="">{this.props.t('select_user_group')}</option>
                            {userGroupsList}
                        </select>
                        <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["userGroupEmpty"]}</span></span>
                        </div>
                        <div className="newPopupFooter">                            
                            <button className="blueBtnNew createReportBtn" onClick={() => { this.inviteEmail(this.state.fields.id, this.state.inviteEmail)}}>
                                {t('invite')}</button>                                
                            <button className="ltblueBtn createReportBtn pull-right" onClick={this.toggleInviteUser}> {t('close')}</button>
                        </div>
                    </div>
                    </MDBModalBody>
                </MDBModal>

                {/* delete warning popup */}
                <MDBModal isOpen={this.state.modalDeleteUserGroup} size="md" className="model_top mw-580">
                    <MDBModalBody className='groupdel_popup'>  
                    <div className="delGroupPopupHeader">
                        <img src={process.env.PUBLIC_URL + "/assets/img/svg/close.svg"} className='pull-right closeIcon' onClick={this.toggleDeleteUserGroup} />
                    </div>      
                    <div className='flex-col-justify-n-align-center'>
                    <h3 className='groupdel-popup-h3'>{t('delete_usergroup')}</h3>
                    <p className='groupdel-confirm'>{t('are_you_sure_you_want_to_delete_your_usergroup')}</p>
                    <div className='smpopup-btns deativate-btndiv'>
                        <button type="button" className='smpopup-yesbtn' onClick={this.onConfirmDeleteUserGroup}><i className="fas fa-trash" style={{paddingRight: "7px"}}></i> {t('yes_delete')}</button>
                        {/* <button type="button" className='smpopup-nobtn ml-3' onClick={this.toggleDeleteSite}>{t('no')}</button> */}
                    </div>
                    </div>
                    </MDBModalBody>
                </MDBModal>
                
                <MDBModal isOpen={this.state.invitePopup} size="md" className="model_top">
                    <MDBModalHeader>{(this.state.errorCode===200)? t('success'):t('alert')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.inviteMessage}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleInvitePopup}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                <SuccessPopup
                    open={this.state.isUserGroupSuccess}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={this.state.userGroupSucess}
                    btnText={t("close")}
                    onClickOperation={this.toggleUserGroupSuccess}
                />

                <SuccessPopup
                    open={this.state.isUserGroupDeleteSuccess}
                    image={process.env.PUBLIC_URL + "/assets/img/svg/success.svg"}
                    content={this.state.userGroupDeleteSucess}
                    btnText={t("close")}
                    onClickOperation={this.toggleUserGroupDeleteSuccess}
                />

                <CustomModal
                    open={this.state.userGroupsListWarningModal}
                    title={t('Warning')}
                    bodyMessage={t(this.state.userGroupsListErrMsg)}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.toggleErrMsgModel}
                />

                 {/* Loader */}
                 <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>

                {this.state.loading ? (
                    <Loader loader={this.state.loading} />
                ) : ("")}
            </main>
        )
    }
}

export default withTranslation()(SiteManagement);