const servicesUrl = {
    "login": "/login/v2.0/",
    "forgotpassword":"/system/authentication/forgotpassword",
    "verifyToken": "/system/authentication/verifytoken/",
    "resetPassword": "/system/authentication/resetpassword",
    "updatePassword": "v1.0/user/updatepassword",
    "getBrokerStatus" : "/login/v1.0/enableInfo",
    "getUserDetails": "v1.0/user/me",
    "refreshPermissions": "v1.0/user/permissions/refresh",
    "getDateRange": "settings",
    "getCombinedChargingPowerFrequency": "dashboard/combinedpower/frequency",
    "getChargepointsCount": "dashboard/chargepoints/status/counts",
    "getConnectorStatusWithCPFilter": "dashboard/chargepoints/connectors/status?cpid=",
    "getConnectorStatus": "dashboard/chargepoints/connectors/status",
    "getChargingPowerWithCPFilter":"dashboard/chargingpower?chargePointId=",
    "getDashboardSessions": "dashboard/chargepoints/chargingsessions?startDate=",
    "getComChargingPower": "dashboard/combinedpower?fromTime=",
    "getDashboardReservations": "dashboard/reservations?startDate=",
    "getDeeplinkCredentials": "preferences/deeplinkcredential",
    "getChargingProfile": "chargingprofiles/search",
    "getPreferences":"preferences/diagnostics",
    "getPreferencesProfiles": "preferences/chargingprofile",
    "saveChargingPower": "preferences/wallBox",
    "saveSiteLimit": "preferences/sitelimit",
    "getSiteLimit": "preferences/getSiteLimit",
    "getDashboardInventory": "cpinventory/dashboard?fromDate=",
    "EmailNotifications": "preferences/mailnotification",
    "accountActivate": "v1.0/user/account/activate",
    "getCustomizationWithTenant" : "admin/v1.0/account/tenant/customization?orgName=",
    "updateCustomizationTenant": "admin/v1.0/account/tenant/customization/save?orgName=",
    "getPreferredLanguages": "admin/v1.0/account/languages/preferred",
    "getAvailableGroups": "sitemanagement/all",
    "getNotificationsCount": "pushnotifications/search/unseenNotificationCount",
    "resetNotifications": "pushnotifications/reset/unseenNotification",
    "getNotificationTypes": "pushnotifications/settings/get",
    "getNotifications": "pushnotifications/search/pushNotification?pageSize=",
    "saveNotificationTypes": "pushnotifications/settings/save",
    "changeTenant": "switchaccount/",
    "logOffTenant": "loggedoff/",
    "changeSite": "switchsite/",
    "logOffSite": "loggedoff/site/",
    "getOrganizationsList": "admin/v1.0/account/all",
    "getChargePointOperations" : "chargepoints/chargeroperations?id=",
    "getAllCplistForNewRfid" : "ocpptags/all/cplist",
    "getSecurityPreferences" : "preferences/security",
    "getChargePointConfiguration" : "chargepoints/configuration",
    "getChargePointCplistByModelOrCPId":"chargepoints/cplistByModelOrCPId",
    "getChargePointCplistWithModels":"chargepoints/cplistWithModels",
    "getChargePointbycp":"chargepoints/getbycp",
    "getChargePointlocalListversion":"chargepoints/localListversion",
    "getChargePointConnectorSearch":"chargepoints/search/connector/search",
    "getChargePointTransactionSearch":"chargepoints/search/transaction",
    "getChargePointDetails":"chargepoints/details",
    "getChargePointsLatestfirmware":"otamanagement/latestfirmware",
    "getChargepointsPartilaSearch":"chargepoints/search/partial",
    "getCPattachmentBySerialnumber":"chargepoints/attachment/byserialnumber",
    "getCPchargepointids":"chargepoints/chargepointids",
    "getCPchargepointidsPartial":"chargepoints/chargepointids/partial",
    "getCPConfigurationKeys":"chargepoints/configurationKeys",
    "getPingpong":"pingpong/id",
    "getChargingprofilesByprofileId":"chargingprofiles/profileId",
    "getChargingprofiles":"chargingprofiles/profileId",
    "getChargingProfilesWId":"chargingprofiles",
    "getchargingschedules":"chargingschedules",
    "getchargingschedulesByscheduleid":"chargingschedules/scheduleid",
    "getCpInventoryOrgByModel":"cpinventory/orgbymodel",
    "updateCpInventory":"cpinventory/update",
    "getCpInventoryEvmodelOrg":"cpinventory/evmodel/organizations",
    "getCpInventory":"cpinventory/bycpId",
    "getCpOperationsLogs":"cp/operations/logs/byDate",
    "getCsOperationsLogs":"cs/operations/logs/byDate",
    "getOwtinventoriesSearchByCp":"owtinventories/search/bychargepointId",
    "getOwtinventoriesBychargepointId":"owtinventories/bychargepointId",
    "getCustomerServiceCP":"customerservice/chargepoints",
    "getCustomerserviceCPErrorGroup":"customerservice/chargepoints/errorgroup",
    "getCustomerserviceCPfirmwareStatus":"customerservice/chargepoints/firmwarestatus",
    "getCustomerserviceCPStatus":"customerservice/chargepoints/status",
    "getCustomerserviceCPLogs":"customerservice/chargepoints/logs",
    "getCustomerserviceCPHeartbeats":"customerservice/chargepoints/heartbeats",
    "getDiagnosticsSN":"diagnostics/SN",
    "getDiagnosticsCP":"diagnostics/chargepoints",
    "getDiagnosticsCPDetails":"diagnostics/chargepoints/details",
    "getDiagnosticsArchive":"diagnostics/archive",
    "updateDashboardBuildArchive":"dashboard/build/archive",
    "updateDashboardBuildRollout":"dashboard/build/rollout",
    "addDashboardBuild": "dashboard/ota/upload",
    "getDashboardBuild":"dashboard/build",
    "getDashboardBuildRolloutHistory":"dashboard/build/rollout/history",
    "getHomeCPChargingsession":"home/chargepoint/chargingsession",
    "getOcpptagsByIdtag":"ocpptags/getbyidtag",
    "getOcpptagById":"ocpptags/id",
    "getOcpptagList":"ocpptags/list",
    "getOcpptagUpdateCPAssociation":"ocpptags/updatecpassociation",
    "getOcpptagsAssociatedPartialSearch":"ocpptags/associated/search/partial",
    "getOcpptagsUnassociatedPartialSearch":"ocpptags/unassociated/search/partial",
    "getOcpptagsCPAssociated":"ocpptags/charger/associated",
    "getOcpptagsCPUnassociated":"ocpptags/charger/unassociated",
    "getOcpptag":"ocpptags",
    "getOcppTagsPartialSearch":"ocpptags/search/partial",
    "getOcppTagsSearch":"ocpptags/search",
    "activateAdminAccount":"admin/v1.0/account/activate",
    "deactivateAdminAccount":"admin/v1.0/account/deactivate",
    "getAdminAccountByid":"admin/v1.0/account/id",
    "getPublishnotificationsByOrg":"publishnotifications/getbyorg",
    "getPublishnotificationslogsOrg":"publishnotifications/logs/organization",
    "getPushnotificationsDetails":"pushnotifications/getDetails",
    "updateReassignmentUsers":"reassignment/users",
    "updateSitemanagementWallbox":"sitemanagement/wallbox",
    "getSitemanagementWallbox":"sitemanagement/wallbox/siteid",
    "getSearchInventoryListWallbox":"sitemanagement/wallbox/partial",
    "getSitemanagement":"sitemanagement",
    "getVerifyUser":"/sitemanagement/invitation/",
    "getSitemanagementUser":"sitemanagement/user",
    "getUserGroupDetails": "sitemanagement/usergroup",
    "siteInviteEmail":"sitemanagement/user/invite",
    "sitegetSiteLimit":"preferences/getSiteLimit",
    "sitegetCurrencies":"sitemanagement/currencycodes",
    "getTenantConfigurationServiceType":"tenantconfiguration/serviceType",
    "getTenantConfig": "tenantconfiguration",
    "getTimeouthinderBycp":"timeouthinder/getbycp",
    "downloadTransactions":"transactions/download",
    "getTransactionsByTransId":"transactions/getbytranId",
    "getTranBusinessErrorsByTransId":"transactions/tranid/businesserrors",
    "updateByTranId":"transactions/updateByTranId",
    "getUpdateRemotestartTrans":"transactions/updateRemotestart",
    "getTransMeterValue":"transactions/metervalues",
    "getVehiclesByname":"v1.0/vehicles/all/getbyname",
    "getVehiclesBynameById":"v1.0/vehicles",
    "getVehicles":"v1.0/vehicles",
    "getBrokerVendorMapperPartialSearch":"broker/vendor/mapper/search/partial",
    "getBrokerVendorMapperMapCharger":"broker/vendor/mapper/mapCharger/",
    "getBrokerVendorName":"broker/vendor/get/name",
    "getBrokerVendorSearchmatchedname":"broker/vendor/search/getmatchedname",
    "getBrokerVendorSearch":"broker/vendor/search",
    "getTransactionsMetervalueBytransid":"transactions/metervalue/bytransid",
    "getVendorBroker":"broker/vendor/", 
    "deleteVendor":"broker/vendor/remove/id",
    "getAssignVendor":"broker/vendor/mapper/map",
    "viewAssignedVendors":"broker/vendor/mapper/by/vendor",
    "updateReservationStatus":"reservations/site",
    "getGroupList":"sitemanagement/search",
    "getReservationChargersAndSlots":"reservations/site/slots",
    "getChargersBasedOnEmailAndSiteid": "reservations/site/user",
    "getSlotsBasedOnChargerAndReservationDate":"reservations/charger/slots",
    "getReservationOverview": "reservations/details",
    "cancelReservation":"reservations/delete",
    "addReservation":"reservations/add",
    "uploadCPImages":"chargepoints/images",
    "getCPImages":"chargepoints/images",
    "deleteImages":"chargepoints/images",
    "getGroupsByPartial" : "sitemanagement/search/partial",
    "getCsrListing":"chargepoints/csr",
    "getVehicleList":"v1.0/vehicles/search/all",
    "getVehiclesCount":"v1.0/vehicles/search/all/count",
    "getUniqueVehicleList":"v1.0/vehicles/all/name",
    "addVehicle":"v1.0/vehicles/",
    "getVendorCount":"broker/vendor/search/count",
    "getUnknownCP":"chargepoints/unknown",
    "UnknownGetPartialSearchList":"chargepoints/unknown/partial",
    "getTransactions":"transactions",
    "transctionsSaveTableField":"transactions/saveFileteredColumns",
    "transctionsGetTableField":"transactions/getFilteredColumns",
    "transctionsbeginRemoteStart":"cs/operations/RemoteStartTransaction",
    "transactionsGetBusinessErrors":"transactions/businesserrors",
    "transactionsGetCustomization":"/api/admin/v1.0/account/tenant/customization",
    "getChargingProfiles":"chargingprofiles",
    "getChargingschedules":"chargingschedules/search",
    "getChargingSchedules":"chargingschedules",
    "getOrganizationAdminList":"admin/v1.0/account/search",
    "getConnectors":"chargepoints/connectors",
    "unblockUser":"platform/user/unblock?userId=",
    "downloadSafeEvFiles":"transactions/ocmfdownload",
    "getQRcodeAuthTag":"qrcode/ocpptags",
    "getQRcode":"qrcode/search",
    "getQRauthTagsPartial" : "qrcode/ocpptags/partial/search",
    "createQRCode":"qrcode/generate",
    "exportQRcodeDetails":"qrcode/export/qrcode",
    "downloadQRCodes":"qrcode/export/all",
    "updateQRcodeLimitaions": "qrcode/update/limitations",
    "updateQRcodeDetails": "qrcode/update",
    "saveActivateDeactivateStatus":"qrcode/update/status",
    "getLimitaionsDetails":"qrcode/limitations",
    "getAuthorizationDetails":"login/v2.0/authorization",
    "getModelList":"otamanagement/models/all",
    "addModel":"otamanagement/models",
    "getBuildsBasedonModel": "otamanagement/ota/builds",
    "updateFirmwareUpdate": "otamanagement/models/settings",
    "getOTAStatus": "otamanagement/ota/status",
    "updateFirmware": "otamanagement/operations/updateFirmware",
    "saveBuildSequence": "otamanagement/ota/builds/order",
    "deleteBuild": "dashboard/ota/builds",
    "updatePackage":"user/package/update",
    "cancelPackage":"user/package/cancel",
    "getPackageDetails":"user/package",
    "deleteOTAModel":"otamanagement/ota/builds",
    "getServiceUrl":"tenantconfiguration/schedulers",
    "getReportDetails":"transactions/report/details",
    "updateTransactionsReport":"transactions/report",
    "saveTransactionsReport":"transactions/report",
    "transactionsGetTablecontents":"transactions/getfilteredcontents",
    "downloadTransactionsexport":"transactions/export",
    "deleteReport":"transactions/report",
    "getTraChargerList":"transactions/chargers",
    "getTraTagList":"transactions/tags",
    "getTraUserList":"transactions/users",
    "getReport":"transactions/report",
    "downloadTransactionsexport":"transactions/export",
    "transactionsGetTableTiltecontents":"transactions/getfilteredcontentcolumns",
    "getModelVariantStatus":"otamanagement/models/enable",
    "getModelconfigkeys":"otamanagement/models/configkeys",
    "getModelconfigkeysvalues":"otamanagement/models/configkey/values",
    "getModelMetertypes":"otamanagement/models/metertypes",
    "transactionsGetFilterHistory":"transactions/user/preferences",
    "getLocalUsers": "chargepoints/getLocalusers",
    "deleteLocalUser": "chargepoints/removeLocalUser",
    "addLocalUser": "chargepoints/addLocalUser",
    "updateLocalUserPassword": "chargepoints/updateLocalUserPassword",
    "syncLocalUsers": "chargepoints/syncLocalUsers",
    "syncChargerStatistics": "chargepoints/syncChargerStatistics",
    "syncFreeChargerStatistics": "chargepoints/syncFreeChargerStatistics",
    "configureMQTTByCpId": "chargepoints/mqtt/configure/",
    "getOfflineData": "offlinedata/all",
    "getWallboxesbasedonUser": "web/chargers/",
    "getAccessCode":"service/partner/code",
    "getUserServicePartnerDetails" : "service/partner/user",
    "saveAccessCode":"service/save",
    "editServicePartner":"service/partner",
    "updateSMProfile":"service/partner",
    "saveSMProfile":"service/partner",
    "deactiveSMProfile":"service/partner/status",
    "saveSMProfileAdd":"platform/user/servicepartner",
    "getSMchargers":"service/partner/user/search/chargers",
    "getSMFilterHistory":"service/partner/user/preferences",
    "getSMFilterFirmwareList":"service/partner/user/search/firmware",
    "downloadSMpartner":"service/partner/user/export",
    "shareSMAccessCode":"service/partner/user/share",
    "getSMchargerDetails":"service/partner/user/charger/details",
    "getSMconnectorStatus":"service/partner/user/charger/connectors",
    "getSMinstallation":"service/partner/user/charger/installation",
    "getSMimages":"service/partner/user/charger/images",
    "getSMcscplogs":"service/partner/user/charger/cscplogs",
    "getSMdiagnostics":"service/partner/user/charger/diagnostics",
    "getSMconfiguration":"service/partner/user/charger/configuration",
    "getSMconnectorStatusList":"chargepoints/connector/status",
    "downloadSMTransactions":"service/partner/user/charger/transactions/download",
    "getPartialVoucherIds":"qrcode/search/partial/voucherIds",
    "getPartialsiteName":"qrcode/search/partial/sites",
    "getQrcodeFilterHistory":"qrcode/user/preferences",
    "getsiteUserGroupList":"sitemanagement/usergroup/list",
    "deletesiteUserGroupList":"sitemanagement/usergroup",
    "getGroupsUserList":"sitemanagement/users",
    "getLMChargersList": "loadmanagement/chargers",
    "getLMGroupsList": "loadmanagement/sites",
    "getLoadMangList":"loadmanagement/search",    
    "LoadManagementGetFilterHistory":"loadmanagement/preferences",
    "updateTrialPeriod":"loadmanagement/extend",
    "subsubscribeCP":"loadmanagement/subscribe",
    "unsubsubscribeCP":"loadmanagement/unsubscribe",
    "multisubsubscribeCP":"loadmanagement/subscribe/multi",
    "multiunsubsubscribeCP":"loadmanagement/unsubscribe/multi",     
    "getClientDetails":"oauth/user/enableapiaccess",  
    "getSwaggerApis":"oauth/user/getswaggerapis",
    "getAccessAPI":"oauth/user/packageaccessmenu",
    "getUserEnableAccessValue":"oauth/user/details"
}
export default servicesUrl;
